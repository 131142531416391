import { Grid } from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { AutoComplete, Input } from "../../components/layouts/forms/inputs";
import Navbar from "../../components/layouts/Navbar";
import { PageHeader } from "../../components/layouts/pageHeader";


const priorityOptions = [
  { id:0, name: "Min" },
  { id:1, name: "Normal" },
  { id:2, name: "Max" },
];

const taskValidations = yup.object({
  title: yup
    .string()
    .required("Field is required")
    ,
  description: yup
    .string()
    .required("Field is required")
    ,
  assigned_to: yup.string(),
  assigned_grp: yup.string(),
  due_date: yup.string(),
  priority: yup.string(),
  state: yup.string(),
});

const CreateTask = () => {

  return (
    <>
      <Navbar heading="CREATE NEW TASK" subheading="NEW TASK" />
      <section className="main-container ">
        <main className="d-flex flex-column px-4 pt-2">
          <Formik
            initialValues = {{
                title: "",
                description: "",
                note: "",
                due_date: "",
                service: "",
                assigned_grp: "",
                assigned_to: "",
                priority: "Normal",
                state: "New",
              }}
            validationSchema={taskValidations}
            // enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
              //   const formData = new FormData(this);
              //   formData.append("titel", values?.title);
              //   formData.append("due_date", values?.due_date || "");
              //   formData.append("assigned_grp", values?.assigned_grp);
              //   formData.append("assigned_to", values?.assigned_to || "");
              //   formData.append("priority", values?.priority);
              //   formData.append("state", values?.state);
              console.log("Values => ", values);
            }}
          >
            {({ values, errors, handleChange, setFieldValue }) => (
              <>
                <Form>
                  <PageHeader header={"Create Task"} className="mb-4" />
                  <Grid container columnSpacing={6}>
                    <Grid item xs={6} className="mt-4 justify-between">
                      <Input
                        // getOptionLabel={(opt) => opt.title}
                        label="Title"
                        name="title"
                        value={values?.title}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    {/* <Grid item xs={6} className="mt-4">
                      <Grid container columnSpacing={0}>
                        <Grid item xs={12}>
                          <AutoComplete
                            label="Note"
                            value={values?.note}
                            name="note"
                            onChange={handleChange}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid> */}
                    {/* <Grid item xs={6} className="mt-4">
                      <Input
                        value={values.description}
                        label="Description"
                        name="description"
                        multiline
                        rows={3}
                        required
                      />
                    </Grid> */}
                    <Grid item xs={6} className="mt-4">
                      {/* <DatePicker
                        name="due_date"
                        label="Due Date"
                        onChange={(newValue) => setFieldValue("due_date", newValue)}
                        renderInput={(params) => (
                          <Input
                            // options={values?.category?.sub_categories}
                            // getOptionLabel={(opt) => opt?.name || ""}
                            label="Due Date"
                            name="due_date"
                            // onChange={handleChange}
                          />
                        )}
                      /> */}
                      <Input
                        // options={values?.category?.sub_categories}
                        // getOptionLabel={(opt) => opt?.name || ""}
                        label="Due Date"
                        name="due_date"
                        // onChange={handleChange}
                      />
                      {/* <Input  label="due_date" Date/> */}
                      {/* <Date /> */}
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        name="assigned_group"
                        label="Assign Group"
                        onChange={handleChange}
                        options={values?.sub_category?.users}
                        getOptionLabel={(opt) => opt?.profile?.full_name || ""}
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        label="Priority"
                        name="priority"
                        value={values.priority.name}
                        options={priorityOptions}
                        onChange={(e, newValue) => setFieldValue("priority", newValue.name)}
                        getOptionLabel={(opt) => opt?.name || ""}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <AutoComplete
                        name="assigned_to"
                        label="Assign To"
                        onChange={handleChange}
                        // getOptionLabel={(opt) => opt?.profile?.full_name || ""}
                      />
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        </main>
      </section>
    </>
  );
};

export default CreateTask;
