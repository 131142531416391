import { Grid, Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Input } from "../../components/layouts/forms/inputs";

const NotesForm = ({ data, setNotesInfo, workNotesRef }) => {
  const [loading, setLoading] = useState(true);
  // const initialState = {
  //   additional_comments: "",
  //   work_notes: "",
  // };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);
  if (loading) {
    return (
      <Box
        className="d-flex justify-content-center align-items-center w-full"
        style={{ height: "50vh" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const notesValidation = yup.object({
    work_notes: yup.string().required("Field is mandatory").nullable(),
  })

  return (
    <>
      <Formik initialValues={{ ...data }} validationSchema={notesValidation}>
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <Grid
              container
              spacing={3}
              className="p-4 sm:flex-col justify-content-left sm:grid-cols-1 tab:flex-col tab:grid-cols-1"
            >
              <Grid item xs={11}>
                <Input
                  name="work_notes"
                  label="Work Notes"
                  multiline
                  rows={2}
                  ref={workNotesRef}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("work_notes", e.target.value);
                    setNotesInfo({ ...values, work_notes: e.target.value });
                  }}
                  required
                />
              </Grid>
              <Grid item xs={11}>
                <Input
                  name="additional_comments"
                  label="Additional Comments"
                  multiline
                  rows={2}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("additional_comments", e.target.value);
                    setNotesInfo({
                      ...values,
                      additional_comments: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box className="mt-5 d-flex justify-content-end"></Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NotesForm;
