import React from 'react'
import { IoCheckmark } from "react-icons/io5";

export default function Uitabs({children, title='', disable=false}) {
  return (
    <>{
        !disable ? (
        <div title={title} className="bg-buttonbgc text-black min-w-[130px] flex justify-center min-h-[38px] items-center px-4 py-2 text-base rounded mr-3 sm:mb-3">
          {children} &nbsp;<IoCheckmark size={22} color='#259799' />
        </div>
        ) : (
        <div title={title} className="bg-[#E9EDF1] text-[#757779] min-w-[130px] flex justify-center min-h-[38px] items-center px-4 py-2 text-base rounded mr-3 sm:mb-3">
          {children}
        </div>
        )
    }
    </>
  )
}
