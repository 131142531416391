import { Email } from "@carbon/icons-react";
import {
  Grid,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Input, Label } from "../layouts/forms/inputs";
import { Dialog } from "../layouts/commonLayouts/dialogBox/dialog";
import { Button } from "../layouts/commonLayouts/buttons";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { ActionCard } from "../layouts/commonLayouts/cards/ActionCards";
import { useCreateOrUpdate, useGetAll } from "../../hooks";
import { toast } from "../../utils/toaster";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const userInfo = {
  user: localStorage.getItem("data")
    ? JSON.parse(localStorage.getItem("data"))
    : {},
};
const initialState = {
  email: "",
  password: "",
  port_number: "",
};

const validations = yup.object({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
  port_number: yup.string().required("Port number is required"),
});

export const EmailConfigDialog = ({ history }) => {
  const [show, setShow] = useState(false);
  const { data, refetch } = useGetAll({
    key: `/portal/emailconf`,
  });

  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/portal/emailconf`,
    method: "post",
  });

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      refetch();
      document.title = "Email Configuration";
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      title={"Email Notification"}
      button={
        <ActionCard className="mt-8 w-96 h-32" variant="primary">
          <div className="mx-10 flex items-center justify-center">
            <Email size="32" />
            <Typography
              className="ml-1  xl:text-sm  2xl:text-base"
              sx={{ fontFamily: "'Century Gothic', 'sans-serif'" }}
            >
              Email Configuration
            </Typography>
          </div>
        </ActionCard>
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{ ...initialState, ...data }}
          validationSchema={validations}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => {
            mutate(
              { ...values, updated_by: userInfo?.user?.USER_ID },
              {
                onSuccess: () => {
                  onClose();
                  refetch();
                  toast("Email Notication saved successfully");
                },
              }
            );
          }}
        >
          {({ errors }) => (
            <Form>
              <Grid container columnSpacing={4} className="mt-2">
                <Grid xs={12} item className="mt-4 sm:flex sm:flex-col">
                  <Input
                    name="email"
                    label="Email"
                    required
                    // helperText="All notifications and communications will be sent by this email."
                  />
                </Grid>
                <Grid xs={12} item className="mt-4">
                  <Input
                    className="mb-2"
                    name="password"
                    label="Password"
                    type={show ? "text" : "password"}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShow((show) => !show)}
                            edge="end"
                          >
                            {show ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid xs={12} item className="mt-4">
                  <Input name="port_number" label="Port" required />
                </Grid>
                <Grid xs={12} item className="mt-4 flex justify-end" > 
                  <Label text={`Last Updated At : ${data?.updated_at}`} />
                </Grid>

                <Grid xs={12} item>
                  <Box className="mt-5 d-flex justify-content-end">
                    <Button
                      color="secondary"
                      className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                      variant="contained"
                      onClick={onClose}
                    >
                      Discard
                    </Button>
                    <Button
                      isLoading={isLoading}
                      variant="contained"
                      className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                      type="submit"
                      onClick={() => console.log(errors, "errors")}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
