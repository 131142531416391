import { IdManagement } from "@carbon/icons-react";
import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import { Dialog } from "../layouts/commonLayouts/dialogBox/dialog";
import { Button } from "../layouts/commonLayouts/buttons";
import { useCreateOrUpdate } from "../../hooks/useCreateOrUpdate";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "../../utils/toaster";
import { AutoComplete, Label } from "../layouts/forms/inputs";
import { ActionCard } from "../layouts/commonLayouts/cards/ActionCards";
import { useGetAll } from "../../hooks";
import {pushToDataLayer} from "../DataLayer/FormData"

const initialState = {
  manager: {},
  users: [],
};

const subcategoryValidations = yup.object({
  manager: yup.object().required("Manager is required"),
  users: yup
    .array()
    .required("Users are required")
    .typeError("Plaeseselect valid data"),
});

export const AssesstManageDialog = () => {
  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/accounts/assetsteam`,
    method: "put",
  });

  const { data, refetch } = useGetAll({
    key: "/accounts/assetsteam",
  });

  return (
    <Dialog
      title={"Select Asset Management Team"}
      button={
        <ActionCard className="mt-8 w-96 h-32" variant="primary">
          <div className="mx-10 flex items-center justify-center">
            <IdManagement size="32" />
            <Typography
              className="ml-1  xl:text-sm  2xl:text-base"
              sx={{ fontFamily: "'Century Gothic', 'sans-serif'" }}
            >
              Asset Management
            </Typography>
          </div>
        </ActionCard>
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{
            ...initialState,
            manager: data?.manager,
            users: data?.users,
          }}
          validationSchema={subcategoryValidations}
          onSubmit={(values) => {
            const array = values?.users?.map((item) => item.id);
            mutate(
              {
                manager: values?.manager?.id,
                users: array.toString(),
              },
              {
                onSuccess: () => {
                  try {
                    
                    pushToDataLayer(values);
                  } catch (error) {
                    console.log('failed',error)
                  }
                  refetch();
                  onClose();
                  toast("Asset Management added successfully.");
                },
              }
            );
          }}
        >
          {({ values, errors }) => (
            <Form>
              <Grid container columnSpacing={4} className="mt-2">
                <Grid xs={12} item className="mt-4">
                  <AutoComplete
                    url="/accounts/managers"
                    name="manager"
                    label="Manager"
                    getOptionLabel={(opt) =>
                      opt?.profile?.full_name || "-------"
                    }
                    helperText={
                      "Select manager for asset management department."
                    }
                    required
                  />
                </Grid>
                <Grid xs={12} item className="mt-4">
                  <AutoComplete
                    url="/accounts/usernamewithid"
                    name="users"
                    label="Users"
                    multiple
                    getOptionLabel={(opt) =>
                      opt?.full_name || opt?.profile?.full_name || ""
                    }
                    helperText={"Select users for asset management department."}
                  />
                </Grid>
                <Grid xs={12} item className="mt-4">
                  <Label text={`Last Update At : ${data?.updated_on}`} />
                </Grid>
                <Grid xs={12} item>
                  <Box className="mt-5 d-flex justify-content-end">
                    <Button
                      color="secondary"
                      className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                      variant="contained"
                      onClick={onClose}
                    >
                      Discard
                    </Button>
                    <Button
                      isLoading={isLoading}
                      variant="contained"
                      className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                      type="submit"
                      onClick={() => console.log(errors, "errors")}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
