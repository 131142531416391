import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Dialog } from "../../components/layouts/commonLayouts/dialogBox/dialog";
import { Edit } from "@carbon/icons-react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import { StatusCard } from "../../components/layouts/commonLayouts/cards/StatusCard";
import UserDialouge from "../../components/users/UserDialouge";
import { CategoryDialog } from "../../components/AdminPanelLayout/CategoryDialog";
import { DeleteBox } from "../../components/layouts/commonLayouts/dialogBox";

export default function CategoriesCard({ row, key }) {
  console.log("Adimin card", row);

  return (
    <>
      <Box sx={{ minWidth: 275 }} key={key}>
        <Card variant="outlined" className="mt-[30px] shadow-lg transition duration-300 transform hover:-translate-y-1 hover:shadow-2xl">
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {row?.name}
            </Typography>
          <CategoryDialog isUpdate data={row} /><br/>
          <DeleteBox
          title="Department"
          url="/portal/categories"
          data={row.id}
          refetchUrl="/portal/categories/0"
        >
          Do you really want to delete this Category?
        </DeleteBox>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
