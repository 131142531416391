import React, { useEffect} from 'react';
import Navbar from '../../components/layouts/Navbar';
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from "../../hooks";
import { Link } from "react-router-dom";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import { columns } from "./IncidentColumns";
import { useMediaQuery } from '@mui/material';
import MyCard from './MyCard2';
import { PageHeader } from '../../components/layouts/pageHeader';
import { datahandleClick } from "../../components/DataLayer/ButtonClick";

const RaisedByMe = () => {
  // const isMobile = useMediaQuery('(max-width:600px)');  
  const isMobile = useMediaQuery('(max-width:1024px)');  //same for tab and phone
  useEffect(() => {
    document.title = "All Incidents Raise By Me";
    window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'page_view',
        page_data: {
          country: 'India',
          language: 'en',
          page_url: window.location.href,
          page_category: 'Incident Management',
          page_location: 'RaisedByMe',
          page_referrer: document.referrer,
          page_title: document.title,
          region: 'Pune',
          site_section: 'Support',
        },
        user_data: {
          user_id: JSON.parse(localStorage.getItem("data")).user_id,
          user_login_state: 'User',
          user_type: JSON.parse(localStorage.getItem("data")).user_role,
        }
      });
      console.log(window.dataLayer)

  }, []);


  const { data: incidents } = useGetAll({
    key: `/incidents/v2/myincidents`,
    params: {
      page: 0,
      limit: 25,
    },
  });


  return (
    <>
      <Navbar heading="INCIDENTS RAISED BY ME" subheading="" />
      <PageHeader header={"INCIDENTS RAISED BY ME"} className="mb-4" />

      <section className="main-container">
      {isMobile ? (
        incidents?.rows?.map((row, index) => <MyCard key={index} row={row}/>)
        ) : (
        <DataGrid
          columns={columns}
          url="/incidents/v2/myincidents"
          rows={incidents}
          getRowId={(row) => row?.inc_num}
          addButton={
            <div className="flex-row-reverse">
              <Link to="/create-incident">
                <Button onClick={() =>{ datahandleClick('Incident-Management', 'Raised-By-Me','New Incident', '/incidents-raised-by-me', )}} className="ml-4">New Incident</Button>
              </Link>
            </div>
          }
        />)}
      </section>
    </>
  );
};

export default React.memo(RaisedByMe);
