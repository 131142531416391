import React, { useEffect } from "react";
import { DepartmentDialog } from "../../components/AdminPanelLayout/DepartmentDialog";
import Navbar from "../../components/layouts/Navbar";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { DeleteBox } from "../../components/layouts/commonLayouts/dialogBox/delete";
import { useGetAll } from "../../hooks";
import { useMediaQuery } from "@mui/material";
import DepartmentCard from "./DepartmentCard";
import BUlkCsvUpload from "./BulkCsvUpload";

const columns = [
  {
    headerName: "Departments",
    field: "name",
    flex: 1,
    cellClassName: "text-dark",
  },
  {
    headerName: "Department Head Email",
    field: "manager",
    flex: 1,
    renderCell: ({ row }) => {
      // console.log(row);
      return <div>{row?.manager?.profile?.full_name}</div>;
    },
  },
  {
    headerName: "Description",
    field: "description",
    flex: 1,
  },

  {
    headerName: "Actions",
    field: "actions",
    flex: 1.2,
    renderCell: ({ row }) => (
      <div className="flex items-center">
        <DepartmentDialog isUpdate data={row} />
        {row.id !== 1 && <div className="border rounded-xl py-2 mx-3"></div>}
        {row.id !== 1 && (
          <DeleteBox
            title="Department"
            url="/portal/items"
            data={row.id}
            refetchUrl="/portal/items/0"
          >
            Do you really want to delete this Department?
          </DeleteBox>
        )}{" "}
      </div>
    ),
  },
];

const Departments = ({ history }) => {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      document.title = "All Departments";
    }
    //eslint-disable-next-line
  }, []);

  const { data: departments,refetch } = useGetAll({
    key: "/portal/departments/0",
    params: {
      page: 0,
      limit: 25,
    },
  });
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <>
      <Navbar heading="DEPARTMENTS" subheading="" />
      <section className="main-container ">
      {isMobile ? ( <DepartmentDialog />):""}
      {isMobile ? ( 
        departments?.rows?.map((row, index) => <DepartmentCard key={index} row={row || []} columns={columns}/>)
        ) : (
        <DataGrid
          columns={columns}
          url="/portal/departments/0"
          rows={departments}
          addButton={
            <div className="flex sm:flex-col tab:flex-col  flex-wrap mt-4" >
              <BUlkCsvUpload className={""} downloadAPI={"/portal/export/department"} uploadAPI={"/portal/upload/department"} onUploadSuccess={refetch} /><DepartmentDialog />
            </div>
          }
        />)}
      </section>
    </>
  );
};

export default Departments;
