import React, { useState, useContext, useEffect } from "react";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import { Link, Redirect } from "react-router-dom";
import UserAuthContext from "../../context/userAuthContext/userAuthContext";
import { useQueryClient } from "react-query";
import { useCreateOrUpdate } from "../../hooks";
import { url } from "../../urls";
import { toast } from "../../utils/toaster";
import { LOGIN_USER, REGISTER_USER } from "../../context/types";

const initialState = {
  email: "",
  password: "",
  password2: "",
};

const validations = yup.object({
  email: yup
    .string()
    .email("Valid email required")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
  password2: yup.string().required("Password is required"),
});

const UserRegisterNew = (props) => {
  const userAuthContext = useContext(UserAuthContext);
  const {
    sendRegData,
    userError,
    userSuccess,
    isAuthenticated,
    isActivated,
    hasProfile,
    dispatch,
  } = userAuthContext;
  const [alert, setAlert] = useState({ msg: "", type: "success" });
  const [loggedIn, setLoggedIn] = useState(false);

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreateOrUpdate({
    url: `${url}/accounts/register/nt/`,
    method: "post",
  });
  const redirect = props.location.search
    ? props.location.search.split("==")[1]
    : "/";
  const redirectToHasProfile = props.location.search
    ? `user-profile${props.location.search}`
    : "/user-profile";
  const redirectToNotActivated = props.location.search
    ? `/not-acivated${props.location.search}`
    : "/not-acivated";
  useEffect(() => {
    console.log(
      "AUTHENTICATED 1",
      props.history,
      isAuthenticated,
      isActivated,
      hasProfile
    );
    if (isAuthenticated) {
      console.log("AUTHENTICATED 2");
      if (hasProfile === false) {
        props.history.push(redirectToHasProfile);
      } else if (isActivated === false) {
        props.history.push(redirectToNotActivated);
      } else {
        props.history.push(redirect);
      }
    }
    document.title = "User Registration | ITSM";
    // eslint-disable-next-line
  }, [props.history, isAuthenticated, isActivated, hasProfile]);
  //   !localStorage.getItem("data")
  return !loggedIn ? (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: "888" }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* {userSuccess && <Alert severity={'success'}>Logged in Successfully!</Alert>}
      {userError && <Alert severity={'error'}>Invalid Credentials</Alert>} */}
      {alert.msg && <Alert severity={alert.type}>{alert.msg}</Alert>}

      <Grid container>
        <Grid item md={6} xs={12} className="sm:hidden">
          <Box className="h-screen relative">
            <img src="register.png" className="object-cover  h-full w-[100%]" />
            <Box className="glass text-base  absolute bottom-[80px]  p-8 left-1/2 translate-x-[-50%]  text-center w-3/4 text-white">
              Transforming IT service management for a better <br />
              tomorrow
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          className="flex items-center justify-center flex-col"
        >
          <Box className="flex items-start justify-center mt-10  flex-col w-3/5 py-10 sm:w-[90%]">
            <Typography className="text-4xl text-[#2F9DD0] font-bold mt-4">
              Register Account
            </Typography>
            <Formik
              initialValues={initialState}
              validationSchema={validations}
              onSubmit={(values, { resetForm }) => {
                if (values.password !== values.password2) {
                  toast(`Passwords must match!`, "error");
                  return;
                }
                mutate(
                  {
                    email: values.email.toLocaleLowerCase(),
                    password: values.password,
                    password2: values.password2,
                  },
                  {
                    onSuccess: async (e) => {
                      resetForm();
                      if (e.data?.user_errors?.email) {
                        toast(`This email is already taken`, "error");
                        console.log("wrong", e.data.error);
                      } else {
                        console.log("token ", e.data.Token);
                        setLoggedIn(true);
                        dispatch({
                          type: REGISTER_USER,
                          payload: e.data,
                        });
                        localStorage.setItem(
                          "data",
                          JSON.stringify(e.data.user)
                        );
                        localStorage.setItem(
                          "token",
                          JSON.stringify(e.data.Token)
                        );
                      }
                      console.log("+++++=", e);
                    },
                  }
                );
              }}
            >
              {({ values, submitForm, errors }) => (
                <div className="flex w-[130%] flex-col gap-3 sm:w-[100%] mt-4">
                  <Input
                    name="email"
                    defaultValue={values.email}
                    placeholder="Email"
                    className="my-2"
                    autoComplete="off"
                    outline
                  />
                  <Input
                    name="password"
                    defaultValue={values.password}
                    placeholder="Password"
                    type="password"
                    className="my-2"
                    autoComplete="off"
                    outline
                  />
                  <Input
                    name="password2"
                    defaultValue={values.password2}
                    placeholder="Confirm Password"
                    type="password"
                    className="my-2"
                    autoComplete="off"
                    outline
                  />

                  <Button
                    variant="contained w-full bg-[#2F9DD0] text-white rounded-md h-12 mt-4"
                    onClick={() => submitForm()}
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    Register
                  </Button>
                  <div class="mt-3 text-[#000000] text-[13px]">
                    Alredy have an account ?
                    <a
                      class=" text-[#2f9dd0] ms-2  border-bottom border-light"
                      href="/user-login"
                    >
                      &#8192;Login
                    </a>
                  </div>
                </div>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </>
  ) : (
    <Redirect to={"/incident-overview"} />
  );
};

export default React.memo(UserRegisterNew);
