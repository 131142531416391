import { NotificationNew } from "@carbon/icons-react";
import { Grid, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Dialog } from "../layouts/commonLayouts/dialogBox/dialog";
import { Button } from "../layouts/commonLayouts/buttons";
import { useCreateOrUpdate } from "../../hooks/useCreateOrUpdate";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "../../utils/toaster";
import { useGetAll } from "../../hooks";
import { AutoComplete, Label } from "../layouts/forms/inputs";
import { ActionCard } from "../layouts/commonLayouts/cards/ActionCards";
import {pushToDataLayer} from "../DataLayer/FormData"

const NotificationsFor = ["Incident"];
const initialState = {
  notifications_for: "Incident",
  agent: "",
  users: [],
};

const validationSchema = yup.object().shape({
  notifications_for: yup.string().required("Please select above"),
  agent: yup.object().shape({
    // id: yup.number().required("Agent is required"),
    // full_name: yup.string().required("Agent name is required"),
  }).required("Agent is required"),
  users: yup.array(),
});

export const NotificationDialog = ({ history }) => {
  const [name, setName] = useState("Incident");
  const { data: configData, refetch } = useGetAll({
    key: `/portal/notificationsconf/${name}`,
  });
  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/portal/notificationsconf/${name}`,
    method: "post",
    onSuccess: () => {
     
      toast("Asset Management added successfully.");
    },
  });
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      refetch();
      document.title = "Email Configuration";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      title={"Notification"}
      button={
        <ActionCard className="mt-8 w-96 h-32" variant="primary">
          <div className="mx-10 flex items-center justify-center">
            <NotificationNew size="32" />
            <Typography
              className="ml-1  xl:text-sm  2xl:text-base"
              sx={{ fontFamily: "'Century Gothic', 'sans-serif'" }}
            >
              Notification
            </Typography>
          </div>
        </ActionCard>
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{ ...initialState, ...configData }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const array = values?.users?.map((item) => item.id);
            mutate(
              {
                notifications_for: name,
                users: array.toString(),
                agent: values?.agent?.id,
              },
              {
                onSuccess: () => {
                  pushToDataLayer(values);
                  onClose();
                  refetch();
                  toast(`Notification saved successfully`);
                },
              }
            );
          }}
        >
          {({ values, errors,handleChange }) => (
            <Form
            onChange={(e) => {
              handleChange(e);
              pushToDataLayer(values);
          }}
            >
              <Grid container columnSpacing={4} className="mt-2">
                <Grid xs={12} item className="mt-4">
                  <AutoComplete
                    options={NotificationsFor}
                    className="mb-2"
                    name="notifications_for"
                    label="Receive notifications for"
                    required
                    value={name}
                    onChange={(e, v) => {
                      setName(v);
                      refetch();
                    }}
                  />
                </Grid>
                <Grid xs={12} item className="mt-4">
                  <AutoComplete
                    url="/accounts/agents"
                    className=" mb-2"
                    name="agent"
                    label="Agent"
                    required
                    getOptionLabel={(option) =>
                      option?.profile?.full_name || ""
                    }
                    helperText={
                      " Agent will receive notifications for all the Incidents"
                    }
                  />
                </Grid>
                <Grid xs={12} item className="mt-4">
                  <AutoComplete
                    url="/accounts/users/dropdown"
                    className=" mb-2"
                    name="users"
                    label="Users"
                    getOptionLabel={(opt) => opt?.profile?.full_name || ""}
                    multiple
                    helperText={
                      " These people will receive notifications for all the Incidents"
                    }
                  />
                </Grid>
                <Grid xs={12} item className="mt-4">
                  <Label
                    text={`Last Updated At : ${configData?.updated_on || "-"}`}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Box className="mt-5 d-flex justify-content-end">
                    <Button
                      color="secondary"
                      className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                      variant="contained"
                      onClick={onClose}
                    >
                      Discard
                    </Button>
                    <Button
                      isLoading={isLoading}
                      variant="contained"
                      className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                      type="submit"
                      onClick={() => console.log(errors, "errors")}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
