import React, { useEffect } from "react";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from "../../hooks";
import { Link } from "react-router-dom";
import {
  Button,
  SecondaryButton,
  
} from "../../components/layouts/commonLayouts/buttons";
import { Download } from "@carbon/icons-react";
import { useDownaloadFile } from "../../hooks";
import { columns } from "./IncidentColumns";
import { PageHeader } from "../../components/layouts/pageHeader";
import { useMediaQuery } from '@mui/material';
import MyCard from "./MyCard2";
import { datahandleClick } from "../../components/DataLayer/ButtonClick";

const AllIncidents = ({ history }) => {
  // const isMobile = useMediaQuery('(max-width:600px)');  
  const isMobile = useMediaQuery('(max-width:1024px)');  //same for tab and phone 
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role === "User") {
      history.push("/view-status");
    } else {
      document.title = "All Incidents";
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'page_view',
        page_data: {
          country: 'India',
          language: 'en',
          page_url: window.location.href,
          page_category: 'Incident Management',
          page_location: 'AllIncidents',
          page_referrer: document.referrer,
          page_title: document.title,
          region: 'Pune',
          site_section: 'Support',
        },
        user_data: {
          user_id: JSON.parse(localStorage.getItem("data")).user_id,
          user_login_state: 'User',
          user_type: JSON.parse(localStorage.getItem("data")).user_role,
        }
      });
      console.log(window.dataLayer)
    }
    // eslint-disable-next-line
  }, []);

  const { data: allIncident } = useGetAll({
    key: "/incidents/all-incidents",
    params: {
      page: 0,
      limit: 25,
    },
  });

  const { refetch, isLoading } = useDownaloadFile("/incidents/csv/0", () => {});
  const { refetch: refetch1, isLoading: isLoading1 } = useDownaloadFile(
    "/incidents/csv/1",
    () => {}
  );
  
  return (
    <>
 
      <PageHeader header={"All Incident"} className="mb-4" />  
      {
        isMobile?(<div className="flex-col h-60">
          <SecondaryButton
            className="capitalize ml-4 mt-8"
            startIcon={<Download />}
            variant="outlined"
            isLoading={isLoading}
            onClick={() => {
              refetch();
              datahandleClick('Incident', 'AllIncidents','Export All Pages', '/', );

            }}
          >
            Export All Pages
          </SecondaryButton>
          <SecondaryButton
            className="capitalize ml-4 mt-8"
            startIcon={<Download />}
            variant="outlined"
            isLoading={isLoading1}
            onClick={() => {
              refetch1();
              datahandleClick('Incident', 'AllIncidents','Export This Page', '/', );

            }}
          >
            Export This Page
          </SecondaryButton>
          <Link to="/create-incident">
            <Button onClick={() =>{ datahandleClick('Incident', 'AllIncidents','New Incident', '/create-incident', )}} className="ml-4 mt-8">New Incident</Button>
          </Link>
           
        </div>
      ):(<></>)
      }
        
      {isMobile ? (
      allIncident?.rows?.map((row, index) => <MyCard key={index} row={row}/>)
      ) : (
        <DataGrid
        columns={columns}
        url="/incidents/all-incidents"
        rows={allIncident}
        getRowId={(row) => row?.inc_num}
       addButton={
          <div className="flex-row-reverse">
            <SecondaryButton
              className="capitalize ml-4"
              startIcon={<Download />}
              variant="outlined"
              isLoading={isLoading}
              onClick={() => {
                datahandleClick('Incident', 'AllIncidents','Export All Pages', '/', );
                refetch();
              }}
            >
              Export All Pages
            </SecondaryButton>
            <SecondaryButton
              className="capitalize ml-4"
              startIcon={<Download />}
              variant="outlined"
              isLoading={isLoading1}
              onClick={() => {
                refetch1();
                datahandleClick('Incident', 'AllIncidents','Export This Page', '/', );
                
              }}
            >
              Export This Page
            </SecondaryButton>
            <Link to="/create-incident">
              <Button onClick={() =>{ datahandleClick('Incident-Management', 'All-Incidents','New Incident', '/all-incidents', )}} className="ml-4">New Incident</Button>
            </Link>

          </div>
        }
      />
      )}
    </>
  );
};

export default AllIncidents;
