import { Add, Edit } from "@carbon/icons-react";
import { Grid, Box } from "@mui/material";
import React from "react";
import { Input, AutoComplete } from "../../../components/layouts/forms/inputs";
import { Dialog } from "../../../components/layouts/commonLayouts/dialogBox/dialog";
import { Button } from "../../../components/layouts/commonLayouts/buttons";
import { useCreateOrUpdate } from "../../../hooks/useCreateOrUpdate";
import { useQueryClient } from "react-query";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "../../../utils/toaster";

const initialState = {
  name: "",
  manager: "",
  description: "",
};

const departmentValidations = yup.object({
  name: yup
    .string()
    .trim("This field cannot include leading and trailing spaces")
    .strict(true)
    .required("Name is required")
    .typeError("Name must be a string"),
  description: yup
    .string()
    .trim("This field cannot include leading and trailing spaces")
    .strict(true)
    .required("Description is required")
    .typeError("Description must be a string"),
});

export const ItemDailog = ({ isUpdate = false, data }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateOrUpdate({
    url: isUpdate ? `/portal/items/${data.id}` : "portal/items/0",
    method: isUpdate ? "put" : "post",
  });

  // console.log(managerData, "data");

  return (
    <Dialog
      title={`${isUpdate ? "Update" : "Add New"} Config Items`}
      button={
        isUpdate ? (
          <Button
            startIcon={<Edit />}
            className="capitalize xl:text-sm 2xl:text-semi-base"
            variant="text"
          >
            View/Edit
          </Button>
        ) : (
          <Button
            variant="outlined"
            className="px-4 ml-6 capitalize xl:text-sm  2xl:text-semi-base h-9"
            startIcon={<Add size={24} />}
          >
            New Item
          </Button>
        )
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{ ...initialState, ...data }}
          validationSchema={departmentValidations}
          onSubmit={(values, { resetForm }) => {
            mutate(
              {
                ...values,
                manager: values?.manager?.id,
              },
              {
                onSuccess: () => {
                  resetForm();
                  onClose();
                  queryClient.refetchQueries({
                    queryKey: ["/portal/items/0"],
                  });
                  toast(
                    `Item ${
                      isUpdate ? "updated" : "created"
                    } successfully`
                  );
                },
              }
            );
          }}
        >
          <Form>
            <Grid container columnSpacing={4} className="mt-2">
              <Grid xs={12} item>
                <Input
                  className="mt-2"
                  name="name"
                  label="Item Name"
                  required
                />
              </Grid>
              <Grid xs={12} item className="mt-2">
                <Input
                  className="mt-2"
                  name="description"
                  label="Description"
                  multiline
                  rows={2}
                  required
                />
              </Grid>

              <Grid xs={12} item>
                <Box className="mt-5 d-flex justify-content-end">
                  <Button
                    color="secondary"
                    className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                    variant="contained"
                    onClick={onClose}
                  >
                    Discard
                  </Button>
                  <Button
                    isLoading={isLoading}
                    variant="contained"
                    className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      )}
    </Dialog>
  );
};
