import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Dialog } from '../../components/layouts/commonLayouts/dialogBox/dialog';
import { Download, Edit } from '@carbon/icons-react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { StatusCard } from "../../components/layouts/commonLayouts/cards/StatusCard";



export default function RequestCard({row}) {
  console.log("request card---------------------------------- ",row);
  

  return (
    <>
    <Box sx={{ minWidth: 275 }} >
    <Card variant="outlined" className=' mt-[30px] shadow-lg transition duration-300 transform hover:-translate-y-1 hover:shadow-2xl'>
    <CardContent>
    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    Number:{row?.req_num?.req_num }
    </Typography>
    <Typography sx={{ fontSize: 15 }} component="div">
    Requested At:{row?.created_on}
    </Typography>
    <Typography sx={{ mb: 1.5 }} color="text.secondary">
    Return/Replace:{row?.returnORreplace}
    </Typography>
    <Typography variant="p" className='text-ellipsis overflow-hidden w-[30px]'>
    {row?.short_desc} <br/>
    Justification:{row?.justification    }
    </Typography>
   
    <Typography variant="button">
      {row?.state}
      </Typography>
    </CardContent>
   </Card>
    </Box>
    </>
  );
}