import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Grid, Box, Button } from '@mui/material';
import Navbar from '../../components/layouts/Navbar';
import {
    Input,
    AutoComplete,
    Label,
    Checkbox,
} from "../../components/layouts/forms/inputs";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from '../../utils/toaster';
import { useHistory } from "react-router-dom";
import { PageHeader } from "../../components/layouts/pageHeader";
import { importance } from "../../utils/IncidentUtils";
import serverAPI from "../../utils/serverAPI";
import { pushToDataLayer } from '../../components/DataLayer/FormData';

ClassicEditor.defaultConfig = {
    toolbar: {
        items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
        ],
    },
    image: {
        toolbar: [
            "imageStyle:full",
            "imageStyle:side",
            "|",
            "imageTextAlternative",
        ],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
};

// Validation Schema
const problemValidationSchema = yup.object({
    prb_statement: yup.string().required('Problem Statement is required')
});


const initialState = {
    affected_incidents: [],
    config_item: '',
    problem_category: '',
    service: '',
    state: 'New',
    impact: "Low",
    urgency: "Low",
    priority: "Normal",
    assigned_grp: '',
    assign_to: '',
    prb_statement: '',
    description: '',
    major_problem: false,
}

const CreateProblem = () => {
    const history = useHistory();
    const [prbStatement, setPrbStatement] = useState('');
    const [description, setDescription] = useState("");
    return (
        <>
            <Navbar heading="CREATE NEW PROBLEM" subheading="NEW PROBLEM" />
            <section className="main-container">
                <main className="d-flex flex-column px-4 pt-2">
                    <Formik
                        initialValues={{
                            ...initialState,
                            caller: localStorage.getItem("data")
                                ? JSON.parse(localStorage.getItem("data"))
                                : { value: "", name: "Select" },
                        }}
                        // validationSchema={problemValidationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            console.log("in submit in submit in submit in submit")
                            const payload = {
                                caller: values?.caller?.USER_ID || values?.caller?.id,
                                state: values?.state,
                                affected_incidents: values?.affected_incidents.map(incident => incident.id),
                                impact: values?.impact,
                                config_item: values?.config_item?.id || "",
                                urgency: values?.urgency,
                                problem_category: values?.problem_category?.id || "",
                                priority: values?.priority,
                                assigned_grp: values?.assigned_grp?.id,
                                service: values?.service,
                                assigned_to: values?.assigned_grp?.manager?.id,
                                major_problem: values?.major_problem || false,
                                prb_statement: prbStatement,
                                description: description,
                            };

                            if (prbStatement.length !== 0) {
                                console.log("before response");
                                const res = await serverAPI.post(`/problems/0`, payload);
                                if (res?.data) {
                                    console.log("in if block");
                                    toast("Problem created successfully");
                                    history.push(`/update-problem/${res.data.prb_num}`);
                                    resetForm();
                                    setDescription("");
                                    setPrbStatement("");
                                } else {
                                    toast("Something went wrong", "error");
                                }
                            } else {
                                toast("Description is required", "error");
                            }
                        }}
                    >
                        {({ values, errors,setFieldValue ,handleChange }) => (
                            <>
                                <Form 
                                 onChange={(e) => {
                                    handleChange(e);
                                    pushToDataLayer(values);
                                }}
                                >
                                    <PageHeader header={"Create New Incident"} className="mb-4" />

                                    <Grid
                                        container
                                        columnSpacing={6}
                                        className="sm:flex-col sm:grid-cols-1 p-4 tab:flex-col tab:grid-cols-1 "
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            className="mt-4 justify-between sm:max-w-full tab:max-w-full"
                                        >
                                            <AutoComplete
                                                url="/accounts/usernamewithid"
                                                getOptionLabel={(option) => option.full_name}
                                                isOptionEqualToValue={(option, value) => option.USER_ID === value.USER_ID}
                                                label="Raised By"
                                                value={values?.caller}
                                                name="caller"
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                                            <AutoComplete
                                                label="Status"
                                                name="state"
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                                            <AutoComplete
                                                url={"/problems/allincidents"}
                                                getOptionLabel={(opt) => opt?.inc_num || ""}
                                                label="Related Incident"
                                                name="affected_incidents"
                                                multiple
                                            />
                                        </Grid>

                                        <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                                            <AutoComplete
                                                options={importance}
                                                label="Impact"
                                                name="impact"
                                                required
                                                disabled
                                            />
                                        </Grid>

                                        <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                                            <AutoComplete
                                                url={"/portal/items/0"}
                                                getOptionLabel={(opt) => opt?.name || ""}
                                                label="Configuration Item"
                                                name="config_item"
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                                            <AutoComplete
                                                label="Urgency"
                                                name="urgency"
                                                options={importance}
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                                            <AutoComplete
                                                url={"/problems/allcats"}
                                                getOptionLabel={(opt) => opt?.name || ""}
                                                label="Category"
                                                name="problem_category"
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                                            <AutoComplete
                                                label="Priority"
                                                name="priority"
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                                            <AutoComplete
                                                url={"/portal/departments/0"}
                                                getOptionLabel={(opt) => opt.name || ""}
                                                label="Assigned group"
                                                name="assigned_grp"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="mt-4 sm:max-w-full">
                                            <Input
                                                label="Bssiness Service"
                                                name="service"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                                            <AutoComplete
                                                name="assigned_to"
                                                label="Assign To"
                                                options={[values?.assigned_grp?.manager?.profile]}
                                                getOptionLabel={(opt) => opt?.full_name || ""}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="mt-4 ml-10 sm:max-w-full">
                                            <Checkbox
                                                label="Major Problem"
                                                name="major_problem"
                                                checked={values.major_problem}
                                                value={values.major_problem}
                                                onChange={(e) => {
                                                    setFieldValue("major_problem", e.target.checked);

                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="mt-4 h-10px sm:max-w-full h-10px tab:max-w-full">
                                            <Label text="Short Description" required />
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onReady={(editor) => {
                                                    editor.editing.view.change((writer) => {
                                                        writer.setStyle(
                                                            "height",
                                                            "100px",
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setPrbStatement(data);
                                                }}
                                                onError={() => toast("Error in CKEditor", "error")}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="mt-4 h-20px sm:max-w-full tab:max-w-full">
                                            <Label text="Detailed Description" required />
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onReady={(editor) => {
                                                    editor.editing.view.change((writer) => {
                                                        writer.setStyle(
                                                            "height",
                                                            "300px",
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setDescription(data);
                                                }}
                                                onError={() => toast("Error in CKEditor", "error")}
                                            />
                                        </Grid>
                                        <Grid xs={12} item className="sm:self-center tab:self-center" >
                                            <Box className="mt-5 mb-4 d-flex justify-content-end">
                                                <Button
                                                    variant="contained"
                                                    className="capitalize ml-4 px-4 xl:text-sm 2xl:text-semi-base"
                                                    type="submit"
                                                    onClick={() => {
                                                        console.log('sssssssssssssssssssssssssssssssssssssss')
                                                        // console.log({ errors }, { values });
                                                        // console.log("clicked")
                                                    }}
                                                >
                                                    Raise Problem
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </>
                        )}
                    </Formik>
                </main>
            </section>
        </>
    );
};

export default CreateProblem;
