import { Grid } from "@mui/material";
import React from "react";
import Navbar from "../../components/layouts/Navbar";
import { SLAConfigDialog } from "../../components/AdminPanelLayout/SLAConfigDialog";
import { EmailConfigDialog } from "../../components/AdminPanelLayout/EmailConfigDialog";
import { NotificationDialog } from "../../components/AdminPanelLayout/NotificationDialog";
import { AssesstManageDialog } from "../../components/AdminPanelLayout/AssesstManageDialog";
import { SkipLevelDialog } from "../../components/AdminPanelLayout/SkipLevelDialog";

const Configuration = ({ data }) => {
  return (
    <>
      <Navbar heading="EMAIL NOTIFICATIONS" subheading="" />
      <section className="main-container">
        <Grid container columnSpacing={0} columnGap={0} className="ml-8 mr-6 sm:flex sm:flex-col">
          {/* <Grid item xs={4}>
            <SLAConfigDialog />
          </Grid> */}
           <Grid item xs={3} sm={6} md={4} minWidth={'300px'} >
            <EmailConfigDialog />
          </Grid>
          <Grid item xs={3} sm={6} md={4} minWidth={'300px'}  >
            <NotificationDialog />
          </Grid>
          <Grid item xs={3} sm={6} md={4} minWidth={'300px'}  >
            <SkipLevelDialog />
          </Grid>
          <Grid item xs={3} sm={6} md={4} minWidth={'300px'}  >
            <AssesstManageDialog />
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default Configuration;
