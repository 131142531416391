import { Close } from "@carbon/icons-react";
import {
  FormHelperText,
  FormLabel,
  Autocomplete as MuiAutoComplete,
  Popper,
  TextField,
} from "@mui/material";
import { colors } from "../../../../../constants/theme";
import { useField, useFormikContext } from "formik";
// import { Input } from "../input";
import { Chip } from "../../../commonLayouts/chip";
import { useGetAll } from "../../../../../hooks";

const defGetOptionLabel = (option) => option;

export const AutoComplete = ({
  label,
  options = [],
  getOptionLabel = defGetOptionLabel,
  url,
  name,
  onChange,
  required,
  noChip,
  ...otherProps
}) => {
  const { data } = useGetAll({
    key: url ? url : "",
    enabled: !options.length && Boolean(url),
  }); 
  console.log('djdjdjdddddddddddddddddddddddddddddd', options)
  // console.log(
  //   url,
  //   "url from autocomplete",
  //   data,
  //   "data from autocomplete",
  //   !options.length && Boolean(url)
  // );

  let textFieldConfig = {
    fullWidth: true,

    sx: {
      width: {
        xs: "85%",  // For small devices
        md: "70%",  // Default for medium and larger devices
      },
      "& .MuiInputBase-root": {
        // gridArea: "i",
        // width: "70%",
        // marginLeft: "auto",
        // width: '70%',
        padding: "11px 12px",
        background: "white",
        color: "#393939",
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
        // minWidth: '410px',
        // maxWidth: '565px'
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "500",

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
          border: "none",
        },
      },
      "& .MuiSvgIcon-root": {
        color: "black",
      },
      "& div.MuiTextField-root": {
        // flexDirection: "row",
        // alignItems: "center",
        // gap: "34px",
      },
      // "& label.MuiInputLabel-root": {
      //   flexShrink: "0",
      //   gridArea: "l",
      //   textAlign: "right",
      //   fontFamily: "Inter",
      // },
      "& .MuiInputBase-input.MuiFilledInput-input": {
        color: "black",
        fontSize: "14px",
      },
      "& .MuiInputLabel-root.MuiFormLabel-root": {
        fontSize: "13px",
        color: "#525252",
        fontWeight: "500",
        fontFamily: "Inter",
      },
      "& .MuiInputBase-root.Mui-disabled": {
        background: "#F4F4F4 !important",
        border: "none",
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "500",
      },

      ...otherProps.sx,
    },
    ...otherProps,
  };

  let setFieldValue;
  let setFieldTouched;

  if (name) {
    //eslint-disable-next-line
    const [field, meta] = useField(name || "");
    //eslint-disable-next-line
    const ctx = useFormikContext();
    setFieldValue = ctx.setFieldValue;
    setFieldTouched = ctx.setFieldTouched;

    textFieldConfig = {
      ...field,
      ...textFieldConfig,
      onBlur: () => {
        setFieldTouched(name, true);
      },
    };

    if (meta.touched && meta.error) {
      console.log('meta.error', name, meta.error)
      if (typeof meta.error === 'object' && Object.keys(meta.error).length>0){
        meta.error = meta.error[Object.keys(meta.error)[0]]
      }
      meta.error = "Please select valid option";
      textFieldConfig.error = true;
      textFieldConfig.helperText = meta.error;
    }
  }

  // console.log('textFieldConfig.helperText', textFieldConfig.helperText)
  const onChangeInner = (e, value, option) => {
    if (onChange) {
      return onChange(e, value, option);
    }
    if (name && setFieldValue) {
      setFieldValue(name, value);
    }
  };
  return (
    <>
      <div className="flex gap-x-4 items-center pl-0 sm:flex-col sm:items-start">
        <FormLabel
          sx={{
            width: "30%",
            textAlign: "right",
            fontSize: "13px",
            color: "#525252",
            fontWeight: "500",
            fontFamily: "Inter",
          }}
          className="sm:text-left"
        >
          {label}
        </FormLabel>
        <MuiAutoComplete
          // PopperComponent={PopperMy}
          options={(data && "rows" in data ? data.rows : data) || options || []}
          getOptionLabel={getOptionLabel || ""}
          size={"small"}
          className="text-dark"
          defaultValue={textFieldConfig?.value}
          value={textFieldConfig?.value}
          {...textFieldConfig}
          onChange={onChangeInner}
          ListboxProps={
            {
              // sx: {
              //   // width: "100px",
              //   fontSize: "14px",
              //   lineHeight: "18px",
              //   fontWeight: "500",
              // },
            }
          }
          propd
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map(
              (option, index) =>
                option &&
                (noChip ? (
                  <p {...getTagProps({ index })}>
                    {`${getOptionLabel(option)} ${
                      tagValue?.length !== index + 1 ? "," : " "
                    }`}
                  </p>
                ) : (
                  <Chip
                    {...getTagProps({ index })}
                    className="mb-2 mr-2 "
                    label={getOptionLabel(option)}
                    deleteIcon={<Close size={"16"} fill={colors.text.dark} />}
                  />
                ))
            );
          }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <TextField
                // label={label}
                required={required}
                // error={textFieldConfig?.error}
                // helperText={textFieldConfig?.helperText}
                inputProps={{
                  ...params.inputProps,
                }}
                // InputLabelProps={{ shrink: true }}
                {...params}
                className="sm:w-[130%] "
              />
            </div>
          )}
        />
      </div>
      {textFieldConfig?.error && (
        <FormHelperText className="w-[70%] ml-auto pl-4" error>
          {textFieldConfig?.helperText}
        </FormHelperText>
      )}
    </>
  );
};
const PopperMy = function (props) {
  return <Popper {...props} placement="bottom" />;
};
