import React, { useState } from "react";
import Navbar from "../../components/layouts/Navbar";
import { url } from "../../urls";
import { useEffect } from "react";
import serverAPI from "../../utils/serverAPI";
import { PageHeader } from "../../components/layouts/pageHeader";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import removeTags from "../../utils/removeTags";
import UpdateChangeDialog from "./UpdateChangeDialog";

const ChangesAssignedToMe = () => {
  const [changes, setChanges] = useState([]);
  const [updateChange, setUpdateChange] = useState(true);

  // const user_id = "-";
  const user_id =
    localStorage.getItem("data") &&
    JSON.parse(localStorage.getItem("data")).USER_ID;

  // GET_ALL CHANGES
  const getAllChanges = async () => {
    console.log("Getting all the changes Assigned To Me");
    serverAPI
      .get(url + `/changes/assignedtome/1`)
      .then((res) => {
        setChanges(res.data.data);
        console.log("All Changes Assigned To Me=========>", res.data.data);
      })
      .catch((err) => console.log(err.response));
  };
  useEffect(() => {
    getAllChanges();
    // return () => {};
  }, [updateChange]);

  console.log("Changes =========>", changes);
  console.log("USER ID IN ASSIGNED TO ME CHANGES", user_id);

  const columns = [
    {
      headerName: "Change Number",
      field: "chng_num",
      flex: 1.5,
    },
    {
      headerName: "Service",
      field: "service",
      flex: 1.5,
    },
    // {
    //   headerName: "Description",
    //   field: "description",
    //   flex: 2.5,
    //   renderCell: ({ row }) => {
    //     return removeTags(row?.description);
    //   },
    // },
    {
      headerName: "Short Description",
      field: "short_desc",
      flex: 2,
      renderCell: ({ row }) => {
        return removeTags(row?.short_desc);
      },
    },
    // {
    //   headerName: "Priority",
    //   field: "priority",
    //   flex: 1,
    // },
    {
      headerName: "State",
      field: "state",
      flex: 1,
    },
    // {
    //   headerName: "Impact",
    //   field: "impact",
    //   flex: 1,
    // },
    // {
    //   headerName: "Risk",
    //   field: "risk",
    //   flex: 1,
    // },
    // {
    //   headerName: "Config Item",
    //   field: "config_item",
    //   flex: 1.5,
    // },
    // {
    //   headerName: "Category",
    //   field: "category",
    //   flex: 1.5,
    // },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1.3,
      renderCell: ({ row }) => {
        return (
          <UpdateChangeDialog
            data={row}
            onUpdateChange={() => setUpdateChange(!updateChange)}
          />
          //   <Button>EDIT</Button>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader header="Changes Assigned To Me" />
      <Navbar heading="CHANGE MANAGEMENT / CHANGES ASSIGNED TO ME" />
      <section className="main-container task-list-page">
        <div className="flex flex-col task-list-wrapper">
          {/* {changes.length > 0 ? ( */}
          <DataGrid
            rows={changes}
            columns={columns}
            getRowId={(row) => row?.chng_num || row?.id}
            addButton={
              <div className="flex-row-reverse">
                <Link to="/create-new-change">
                  <Button variant="contained">Add Change</Button>
                </Link>
              </div>
            }
          />
          {/* ) : (
            <div>No data available</div>
          )} */}
        </div>
      </section>
    </>
  );
};

export default ChangesAssignedToMe;
