import React, { useEffect } from "react";
import { DeleteBox } from "../../../components/layouts/commonLayouts/dialogBox/delete";
import { useGetAll } from "../../../hooks";
import { DataGrid } from "../../../components/layouts/commonLayouts/dataGrid";
import CategoriesCard from "../CategoriesCard";
import { useMediaQuery } from "@mui/material";
import { PageHeader } from "../../../components/layouts/pageHeader";
import { CategoryDialog } from "./CategoryDialog";

const columns = [
  {
    headerName: "Problem Categories",
    field: "name",
    flex: 1,
    cellClassName: "text-dark", 
    renderCell: ({ row }) => (
        <p className="m-0 text-truncate">{row?.name}</p>

    ),
  },
  {
    headerName: "Actions",
    field: "actions",
    flex: 1,
    renderCell: ({ row }) => (
      <div className="flex items-center">
        <CategoryDialog isUpdate data={row} />
        <div className="border rounded-xl py-2 mx-3"></div>
        <DeleteBox
          title="Categories"
          url="/problems/problem_category"
          data={row.id}
          refetchUrl="/problems/problem_category/list"
        >
          Do you really want to delete this Category?
        </DeleteBox>
      </div>
    ),
  },
];

const ProblemCategories = ({ history }) => {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      document.title = "Problem Categories";
    }
    //eslint-disable-next-line
  }, []);

  const { data: categories,refetch } = useGetAll({
    key: "/problems/problem_category/list",
    params: {
      page: 0,
      limit: 25,
    },
  });
  const isMobile = useMediaQuery('(max-width:600px)');  
  return (
    <>
    <PageHeader header={'Problem categories'}/>
      <section className="main-container">
      {isMobile ? ( <CategoryDialog />):''}
      {isMobile ? ( 
        categories?.rows?.map((row, index) => <CategoriesCard key={index} row={row || []} columns={columns}/>)
        ) : (
        <DataGrid
          columns={columns}
          rows={categories}
          url="/portal/categories/0"
          addButton={<div className="flex sm:flex-col tab:flex-col  flex-wrap mt-4" >
            <CategoryDialog />
            </div>}
        />)}
      </section>
    </>
  );
};

export default React.memo(ProblemCategories);
