import React, { useEffect, useState } from "react";
import HeroScreen from "../../../public/landingPageImages/hero_screen.webp";
import HeroRight from "../../../public/landingPageImages/hero-right.svg";
import HeroRightDesktop from "../../../public/landingPageImages/hero-right-mobile.png";
import IncMgmt from "../../../public/landingPageImages/inc-mgmt.webp";
import ReqMgmt from "../../../public/landingPageImages/req_mgmt.webp";
import TaskMgmt from "../../../public/landingPageImages/task_mgmt.webp";
import ExpLeft from "../../../public/landingPageImages/expertise-left.svg";
import ExpRight from "../../../public/landingPageImages/expertise-right.svg";
import AIScreen from "../../../public/landingPageImages/ai_image_pic.webp";
import EllipseRight from "../../../public/landingPageImages/expertise-right.svg";
import BlueTick from "../../../public/landingPageImages/circle-right-blue.svg";
import WhiteTick from "../../../public/landingPageImages/circle-right-white.svg";
import ITSMLogo from "../../../public/landingPageImages/itsm.svg";
import DotsLeft from "../../../public/landingPageImages/dots-left-svg.svg";
import DotsRight from "../../../public/landingPageImages/dots-right-svg.svg";
import LeftArrow from "../../../public/landingPageImages/left-arrow.svg";
import RightArrow from "../../../public/landingPageImages/right-arrow.svg";
import QuoteMark from "../../../public/landingPageImages/quotes.svg";
import FooterLeft from "../../../public/landingPageImages/footer-left-ellipse.svg";
import FooterRight from "../../../public/landingPageImages/footer-right-ellipse.svg";
import Streamline from "../../../public/landingPageImages/streamline-bg.png";
import test1 from "../../../public/landingPageImages/test1.png";
import test2 from "../../../public/landingPageImages/test2.png";
import Av1 from "../../../public/landingPageImages/avatar-1.png";
import Av2 from "../../../public/landingPageImages/avatar-2.png";
import Av3 from "../../../public/landingPageImages/avatar-3.png";
import Av4 from "../../../public/landingPageImages/avatar-4.png";
import Av5 from "../../../public/landingPageImages/avatar-5.png";
import Av6 from "../../../public/landingPageImages/avatar-6.png";
import HomeBg from "../../../public/landingPageImages/home-bg.png";
import ItBg from "../../../public/landingPageImages/it-bg.png";
import testiImg from "../../../public/landingPageImages/test-img.png";

import testiPic from "../../../public/landingPageImages/test-pic.png";
import testBg1 from "../../../public/landingPageImages/tesi-bg-1.png";

import testBg2 from "../../../public/landingPageImages/testi-bg-2.png";
import HeroRightMobile from "../../../public/landingPageImages/hero-mobile.png";
// import test-Bg2 from "../../../public/landingPageImages/test-pic.png";
// import test-Bg3 from "../../../public/landingPageImages/test-pic.png";

import { Link, Route } from "react-router-dom";

import serverAPI from "../../../utils/serverAPI";
import { tenantApiUrl } from "../../../urls";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import WebsiteLayout from "../components/WebsiteLayout";

const HomePage = () => {
  const [plans, setPlans] = useState([
    {
      id: 1,
      name: "Base Plan",
      cost: 10,
      description: "For large agency and businesses",
      plan_details: {
        users: "Unlimited",
        support: "24 hours support",
        features: "Access all features",
        accessibility: "Worldwide accessibility",
        automation_actions: "Unlimited",
      },
      is_active: true,
      order: 1,
    },
    {
      id: 3,
      name: "Free Trial",
      cost: 0,
      description: "For simple and personal project",
      plan_details: {
        users: "5",
        support: "24 hours support",
        features: "Access all features",
        accessibility: "Worldwide accessibility",
        automation_actions: "25",
      },
      is_active: true,
      order: 1,
    },
    {
      id: 2,
      name: "Enterprise Plan",
      cost: 10,
      description: "For large organisations",
      plan_details: {
        users: "unlimited",
        // support: "24 hours support",
        features: "Access all features",
        accessibility: "Worldwide accessibility",
        automation_actions: "Unlimited",
      },
      is_active: true,
      order: 1,
    },
  ]);

  // const getPlanDetails = async () => {
  //   try {
  //     const response = await serverAPI.get(
  //       `${tenantApiUrl}/tenants/plans/nt/`
  //     );

  //     console.log(response.data, "Reponse ^^");
  //     setPlans(response.data);
  //   } catch (error) {
  //     console.log(error, "ERROR");
  //   }
  // };

  // useEffect(() => {
  //   // getPlanDetails();
  // }, []);

  return (
    <WebsiteLayout>
      <section className=" hero-container min-h-screen w-full bg-[#E6EFFF] flex justify-center items-center relative pb-[100px] pt-[200px] montserrat phone:pt-[153px] phone:pb-[20px] phone:mb-[0px] tab:pt-[223px] tab:pb-[106px] ">
        <div className=" relative z-50 container w-full flex !pr-0  justify-between items-center lg:flex-nowrap phone:flex-wrap phone:justify-center phone:gap-[50px] phone:flex-col">
          <div
            className="h-[422px] flex flex-col justify-center align-start ml-[3rem] mr-12  phone:w-full phone:m-0 phone:px-[17px] lg:w-[40%] phone:h-auto  flex-[0_1_744px] phone:justify-start phone:flex-[0]
           
          "
          >
            <p
              className="text-[2.3rem] leading-[47px] text-left mb-4 text-[#1d1d1d] isolate phone:text-[31px] phone:leading-[39px] phone:mb-[24px]
            tab:text-[39px] tab:leading-[51px] tab:mb-[23px]
              font-[700] gillory 
            "
            >
              Transform your
              {/* <span className="inline-block relative "> */}
              {/* <div
                  className="h-full absolute bg-[#ffffffb5] rounded-[8px] top-0 -right-1 -left-40"
                  style={{ zIndex: "-1 " }}
                ></div> */}
              <br />
              <span className="text-[#3D77DD] blue-g px-[5px]">
                {" "}
                IT service management{" "}
              </span>
              {/* </span> */}
              with
              <span className="inline-block"> our powerful solution </span>
            </p>
            <p className="mb-4 text-[#2F2F2F] text-sm phone:text-[12px] phone:leading-[16px] phone:mb-[24px]  tab:text-[13px] tab:leading-[23px] tab:mb-[23px]  font-[400]  poppins">
              Say goodbye to IT service challenges with our comprehensive
              platform, designed to simplify your IT operations and maximize
              your team's productivity
            </p>
            <button className="text-sm border-0 inline w-fit bg-[#242424] text-white py-[10px] px-[24px] rounded-[22px] lg:text-[14px] phone:text-[10px] phone:px-[16px] phone:py-[8px]  tab:text-[11px] tab:px-[19px] tab:py-[9px]  font-[500]  poppins ">
              Get Started
            </button>
          </div>

          <img
            src={HeroRightDesktop}
            alt="hero"
            className="flex-[0_1_526px] aspect-auto min-w-0 max-h-[422px] max-w-[714px] phone:w-[80%] phone:hidden            "
          />

          <img
            src={HeroRightMobile}
            alt="hero"
            className="flex-[0_1_526px] aspect-auto min-w-0 max-h-[422px] hidden max-w-[714px] phone:w-[80%] phone:flex-initial  phone:flex-[0] phone:flex phone:w-[90%]"
          />
        </div>

        <img
          src={HeroRight}
          alt="svg"
          className="absolute top-0 right-0 phone:w-[208px]"
        />
      </section>

      <div className="relative">
        <section
          id="features"
          className=" container expertise-container pt-[150px] pb-[150px] tab:pt-[157px]  w-100 flex flex-col justify-center items-center relative montserrat 
          phone:pt-[100px]
          phone:pb-[40px] first-letter:phone:overflow-x-clip"
        >
          <h3
            className="text-[1.8rem] text-center mt-2 phone:!my-0 phone:text-[25px] phone:leading-[30px]  font-[700] gillory
           tab:!my-0 tab:text-[28px] tab:leading-[33px]"
          >
            Special expertise from us
          </h3>
          <svg
            className="mt-3 mb-5 phone:mb-[45px] phone:w-[47px] phone:h-[5px]  phone:mt-[13px]
            tab:mb-[53px] tab:w-[47px] tab:h-[5px]  tab:mt-[18px]"
            width="76"
            height="8"
            viewBox="0 0 76 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.222229"
              y="8"
              width="8"
              height="75.5555"
              rx="4"
              transform="rotate(-90 0.222229 8)"
              fill="#3D77DD"
            />
          </svg>

          <div className="card-container py-2 flex flex-wrap w-[85vw] justify-center gap-[56px]  tab:gap-[41px] phone:gap-0 items-start z-50 phone:w-full phone:flex-nowrap phone:flex-col phone:items-center phone:!p-0 max-w-[1199px]     ">
            <div className="w-[290.2px] tab:!w-[254px] flex flex-col justify-center items-start mb-5  phone:mb-[60px] phone:w-[88%]">
              <img
                src={IncMgmt}
                alt="Incident Management"
                className="object-cover rounded-[16.63px] w-full h-[203px] mb-4 phone:mb-[18px] phone:h-auto aspect-[331/225] tab:mb-[18px] tab:h-[172px]"
              />
              <h3 className="text-[22px] text-[#272D4E] mb-2 phone:text-[18px] phone:leading-[34px] tab:text-[15px] tab:leading-[26px] tab:mb-[5px]  font-[600] gillory">
                Incident Management
              </h3>
              <p className="text-[14px] text-[#94A2B3] mb-2 phone:mb-[4px] phone:text-[13px] phone:leading-[21px] tab:text-[10px] tab:leading-[18px] tab:mb-[5px]  font-[400] poppins">
                Our incident management service promotes cross-team
                collaboration, fast incident response, and continuous learning
                to prevent future incidents.
              </p>
              <button className="border-0 bg-transparent text-[#299EF3] text-[14px] phone:text-[13px] phone:leading-[20px] tab:text-[10px] tab:leading-[15px]  font-[700] poppins">
                Know More &gt;
              </button>
            </div>

            <div className="w-[290.2px] flex flex-col justify-center items-start mb-5  phone:mb-[60px] phone:w-[88%]">
              <img
                src={ReqMgmt}
                alt="Incident Management"
                className="object-cover rounded-[16.63px] w-full h-[203px] mb-4 phone:mb-[18px] phone:h-auto aspect-[331/225] tab:mb-[18px] tab:h-[172px]"
              />
              <h3 className="text-[1.3rem] text-[#272D4E] mb-2 phone:text-[18px] phone:leading-[34px] tab:text-[15px] tab:leading-[26px] tab:mb-[5px]  font-[600] gillory">
                Request Management
              </h3>
              <p className="text-[14px] text-[#94A2B3] mb-2 phone:mb-[4px] phone:text-[13px] phone:leading-[21px] tab:text-[10px] tab:leading-[18px] tab:mb-[5px]  font-[400] poppins">
                To ensure that your staff and customers receive the assistance
                they require efficiently, manage work across teams using a
                single tool.
              </p>
              <button className="border-0 bg-transparent text-[#299EF3] text-[14px] phone:text-[13px] phone:leading-[20px] tab:text-[10px] tab:leading-[15px] font-[700] poppins">
                Know More &gt;
              </button>
            </div>

            <div className="w-[290.2px] flex flex-col justify-center items-start mb-5  phone:mb-[60px] phone:w-[88%]">
              <img
                src={TaskMgmt}
                alt="Incident Management"
                className="object-cover rounded-[16.63px] w-full h-[203px] mb-4 phone:mb-[18px] phone:h-auto aspect-[331/225] tab:mb-[18px] tab:h-[172px]"
              />
              <h3 className="text-[1.3rem] text-[#272D4E] mb-2 phone:text-[18px] phone:leading-[34px] tab:text-[15px] tab:leading-[26px] tab:mb-[5px]  font-[600] gillory">
                Task Management
              </h3>
              <p className="text-[14px] text-[#94A2B3] mb-2 phone:mb-[4px] phone:text-[13px] phone:leading-[21px] tab:text-[10px] tab:leading-[18px] tab:mb-[5px]  font-[400] poppins">
                Organizing tasks, establishing goals, and tracking progress
                enable teams to work effectively, which helps to increase
                efficiency.
              </p>
              <button className="border-0 bg-transparent text-[#299EF3] text-[14px] phone:text-[13px] phone:leading-[20px] tab:text-[10px] tab:leading-[15px] font-[700] poppins">
                Know More &gt;
              </button>
            </div>
          </div>
        </section>
        {/* <img
          src={ExpLeft}
          alt="svg"
          className="absolute left-0 top-20 z-[-2] phone:left-[-175px] phone:top-[109px] "
        />
        <img src={ExpRight} alt="svg" className="absolute right-0 bottom-0" /> */}
      </div>

      <section className="ai-container bg-[#E6EFFF63] min-h-[686px] w-100 flex flex-col justify-center items-center relative montserrat phone:min-h-0 phone:pt-[60px] phone:h-auto phone:pb-[16px]">
        <div className=" container  relative w-[85vw]  gap-1 flex justify-between items-center z-10 phone:flex-col phone:!gap-[78px] phone:text-[23px] phone:leading-[31px] phone:h-auto">
          <div className="w-[40%] py-2 pr-[10px] flex flex-[0_1_500px] phone:flex-[0_0_auto] flex-col justify-center phone:w-full phone:text-center phone:items-center phone:!py-[0]">
            <h1 className="text-[#0C0047] text-[40px] tab:text-[26px] tab:leading-[36px] tab:mb-[16px] phone:text-[26px] lg:text-[2.2rem] lg:leading-[45px]  mb-8 phone:mb-[16px] font-[700] gillory ">
              Streamline your IT service management with AI technology
            </h1>
            <p className="lg:text-sm text-[#94A2B3] mb-10 tab:text-[12px] tab:leading-[18px] tab:mb-[22px] phone:text-[13px] phone:leading-[22px] phone:mb-[28px]  font-[400] poppins">
              By leveraging the power of AI and machine learning, IT teams can
              streamline processes, reduce costs, and focus on delivering
              strategic value to the business.
            </p>
            <button
              className="border-0 bg-[#3D77DD] text-[#fff] rounded-[8px] py-[8px] px-[14px] self-start text-[0.8rem] 
              phone:px-[16px] phone:py-[10px] phone:text-[14px] phone:leading-[18px] phone:rounded-[18px]
              tab:text-[10px] tab:leading-[13px] tab:px-[16px] tab:py-[9px]  font-[500] poppins phone:self-center
            "
            >
              Coming Soon
            </button>
          </div>

          <img
            src={AIScreen}
            alt="ai dashboard"
            className="w-min-[0px] flex-[0_1_633px] w-auto  h-auto aspect-auto min-w-0 phone:!flex-[0_1.7_auto]   phone:!w-[100%] z-[3] ml-[60px] phone:ml-0"
          />
          <img
            className="hidden phone:inline-block absolute bottom-[-19px] w-[100vw]"
            src={Streamline}
            alt=""
          />
        </div>
        <img
          src={ItBg}
          alt="hero"
          className="absolute phone:hiden phone:hidden top-0 bottom-0 right-0 h-full"
        />
        {/* <img
          src={EllipseRight}
          alt="ellipse"
          className="absolute right-0 top-0 bottom-0 phone:hidden"
        /> */}
      </section>

      <section
        id="benefits"
        className="pricing-container min-h-[100vh - 400px] w-100 flex flex-col justify-center items-center text-center py-[200px] tab:pb-[111px] tab:pt-[0] montserrat phone:mb-[100px] phone:py-0 phone:h-auto"
      >
        <h3 className="text-[#0C0047] text-[2rem] tab:text-[28px] tab:mt-[110px] tab:mb-[13px] tab:leading-[35px] mt-12 phone:w-[90%] phone:mt-[125px] phone:mb-[20px] phone:text-[40px] phone:leading-[49px]  phone:text-[#0C0047]  font-[700] gillory">
          Our pricing
        </h3>
        <p className="text-[#969696] text-[0.8rem] tab:text-[9px]  font-[400]  poppins  phone:w-[90%] phone:mb-[60px] phone:text-[#969696] tab:mb-[0]">
          Pay securely online and manage the booking via desktop or via the
          mobile app.{" "}
        </p>

        <div className="container pricing-card-container bg-white flex flex-wrap justify-center w-[100%] mt-10 tab:mt-[43px] phone:flex-col phone:items-center phone:mt-[0]">
          <div className="pricing-card mb-9 flex flex-col items-center w-[298px] tab:w-[219px] rounded-[10.8px] py-10  px-4 phone:py-[40px] phone:h-auto  phone:w-[79%] phone:mx-0 tab:py-[27px] tab:px-[13px] tab:pb-0  tab:mb-0 ">
            <h4 className="text-[#3D77DD] text-lg mb-3 tab:text-[13px] tab:leading-[17px] tab:mb-[13px] phone:text-[19px] phone:leading-[25px]  font-[700] gillory">
              {plans[1]?.name ?? ""}
            </h4>
            <p className="text-[#969696] text-[0.75rem] phone:text-[14px] phone:leading-[20px] tab:text-[9px] tab:leading-[14px] tab:mb-[20px] phone:mb-[28px] font-[400]  poppins">
              {plans[1]?.description ?? ""}
            </p>
            <div className="flex items-end mt-4 mb-8  tab:mt-[20px] tab:mb-[29px]  phone:mb-[32px] phone:items-baseline phone:!mt-0">
              <h1 className="text-[#3D77DD]  text-[2.5rem] leading-6  phone:text-[44px] phone:leading-[47px] phone:mb-[18px] tab:text-[30px] tab:leading-[33px] tab:mb-0 font-[700] gillory">
                {`$${plans[1]?.cost ?? 0}`}
              </h1>
              <span className="text-[#969696] text-[0.7rem]  phone:text-[13px] phone:leading-[19px] tab:text-[8px] tab:leading-[13px] font-[500] gillory ">
                / user
              </span>
            </div>
            <Link
              to={{
                pathname: "/onboard",
                state: "5",
              }}
              className="border-0 bg-[#3D77DD] text-white rounded-[6.2px]  py-[9px] px-[18px] w-full text-[0.7rem] mb-9
              phone:text-[12px] phone:leading-[19px] phone:px-[18px] phone:py-[9px] phone:mb-[37px] tab:px-[13px] tab:py-[7px] tab:text-[8px]
              font-[700]  poppins
            "
            >
              Try For Free
            </Link>

            <div className="flex flex-col items-start tab:text-[9px] text-[#616161] text-[0.7rem] text-semi-bold w-full ">
              <p className="flex items-center mb-4 phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px]  font-[500]  poppins">
                <img
                  src={WhiteTick}
                  alt="right"
                  className="mr-2 phone:w-[15px] font-[500] poppins phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />
                {plans[1]?.plan_details?.users ?? ""} Users
              </p>
              <p className="flex items-center mb-4 phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px]  font-[500]  poppins">
                <img
                  src={WhiteTick}
                  alt="right"
                  className="mr-2 phone:w-[15px] font-[500] poppins phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />

                {plans[1]?.plan_details?.accessibility ?? ""}
              </p>
              <p className="flex items-center mb-4 phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px]  font-[500]  poppins">
                <img
                  src={WhiteTick}
                  alt="right"
                  className="mr-2 phone:w-[15px] font-[500] poppins phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />{" "}
                {`${plans[1]?.plan_details?.automation_actions} automation actions` ??
                  ""}
              </p>
              <p className="flex items-center mb-4 phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px]  font-[500]  poppins">
                <img
                  src={WhiteTick}
                  alt="right"
                  className="mr-2 phone:w-[15px] font-[500] poppins phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />{" "}
                {plans[1]?.plan_details?.features ?? ""}
              </p>
              {/* <p className="flex items-center mb-4 phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px]  font-[500]  poppins">
                <img
                  src={WhiteTick}
                  alt="right"
                  className="mr-2 phone:w-[15px] font-[500] poppins phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />
                {plans[1]?.plan_details?.support ?? ""}
              </p> */}
            </div>
          </div>

          <div className="pricing-card mx-7 tab:mx-[16px] mb-9 bg-[#3D77DD] flex flex-col items-center  w-[298px] tab:w-[219px] rounded-[10.8px] py-10  px-4 phone:py-[40px] phone:h-auto phone:w-[79%] phone:mx-0 tab:py-[27px] tab:px-[13px]   tab:!pb-0 tab:mb-0">
            <h4 className="text-white text-lg mb-3 tab:text-[13px] tab:leading-[17px] tab:mb-[13px] phone:text-[19px] phone:leading-[25px]  font-[700] gillory">
              {plans[0]?.name ?? ""}
            </h4>
            <p className="text-white text-[0.75rem]  phone:text-[14px] phone:leading-[20px] tab:text-[9px] tab:leading-[14px] tab:mb-[20px] phone:mb-[28px]  font-[400]  poppins">
              {plans[0]?.description ?? ""}
            </p>
            <div className="flex items-end mt-4 mb-8 tab:mt-[20px] tab:mb-[29px] phone:mb-[32px] phone:items-baseline  phone:!mt-0">
              <h1 className="text-white text-[2.5rem] leading-6  phone:text-[44px] phone:leading-[47px] phone:mb-[18px] tab:text-[30px] tab:leading-[33px] tab:mb-0  font-[700] gillory ">
                {`$${plans[0]?.cost ?? 0}`}
              </h1>
              <span className="text-white text-[0.7rem]  phone:text-[13px] phone:leading-[19px] tab:text-[8px] tab:leading-[13px]  font-[500] gillory ">
                / user
              </span>
            </div>
            <Link
              to={{
                pathname: "/onboard",
                state: "100",
              }}
              className="border-0 bg-white text-[#3D77DD] rounded-[6.2px]  py-[9px] px-[18px] w-full text-[0.7rem]  mb-9    phone:text-[12px] phone:leading-[19px] phone:px-[18px] phone:py-[9px] phone:mb-[37px] tab:px-[13px] tab:py-[7px] tab:text-[8px]
            font-[700]  poppins"
            >
              Try This Plan
            </Link>

            <div className="flex flex-col items-start text-white text-[0.7rem] tab:text-[9px] text-semi-bold w-full ">
              <p className="flex items-center mb-4  phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px]  font-[500] poppins">
                <img
                  src={BlueTick}
                  alt="right"
                  className="mr-2  phone:w-[15px] phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />
                {plans[0]?.plan_details?.users ?? ""} Users
              </p>
              <p className="flex items-center mb-4  phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px] font-[500] poppins">
                <img
                  src={BlueTick}
                  alt="right"
                  className="mr-2  phone:w-[15px] phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />
                {plans[0]?.plan_details?.accessibility ?? ""}
              </p>
              <p className="flex items-center mb-4  phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px] font-[500] poppins">
                <img
                  src={BlueTick}
                  alt="right"
                  className="mr-2  phone:w-[15px] phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />
                {`${plans[0]?.plan_details?.automation_actions} automation actions` ??
                  ""}
              </p>
              <p className="flex items-center mb-4  phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px] font-[500] poppins">
                <img
                  src={BlueTick}
                  alt="right"
                  className="mr-2  phone:w-[15px] phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />
                {plans[0]?.plan_details?.features ?? ""}
              </p>
              <p className="flex items-center mb-4  phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px] font-[500] poppins">
                <img
                  src={BlueTick}
                  alt="right"
                  className="mr-2  phone:w-[15px] phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />
                {plans[0]?.plan_details?.support ?? ""}
              </p>
            </div>
          </div>

          <div className="pricing-card mb-9 bg-white flex flex-col items-center w-[298px] tab:w-[219px] rounded-[10.8px] py-10  px-4  phone:w-[79%] phone:mx-0 tab:py-[27px] tab:px-[13px]  phone:py-[40px] phone:h-auto tab:!pb-0 tab:mb-0">
            <h4 className="text-[#3D77DD] text-lg mb-3 tab:text-[13px] tab:leading-[17px] tab:mb-[13px]  phone:text-[19px] phone:leading-[25px]  font-[700] gillory">
              {" "}
              {plans[2]?.name ?? ""}
            </h4>
            <p className="text-[#969696] text-[0.75rem]  phone:text-[14px] phone:leading-[20px] tab:text-[9px] tab:leading-[14px] tab:mb-[0px] phone:mb-[28px]  font-[400]  poppins">
              {plans[2]?.description ?? ""}
            </p>
            <div className="flex items-end mt-4 mb-8  tab:mt-[20px] tab:mb-[29px]   phone:mb-[32px] phone:items-baseline  phone:!mt-0">
              <h1 className="text-[#3D77DD] text-[1.7rem] leading-6  phone:text-[22px] phone:leading-[47px] phone:mb-[18px] tab:text-[20px] tab:leading-normal tab:mb-0  font-[700] gillory">
                Custom price*
              </h1>
            </div>
            <Link className="w-full block" to={"/contact-us"}>
              <button
                className="border-0 bg-[#3D77DD] text-white rounded-[6.2px]  py-[9px] px-[18px] w-full text-[0.7rem] font-bold mb-9
              phone:text-[12px] phone:leading-[19px] phone:px-[18px] phone:py-[9px] phone:mb-[37px] tab:px-[13px] tab:py-[7px] tab:text-[8px]
              font-[700]  poppins
            "
              >
                Contact Us
              </button>
            </Link>
            <div className="flex flex-col tab:text-[9px] items-start text-[#616161] text-[0.7rem] text-semi-bold w-full ">
              <p className="flex items-center mb-4  phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px]   font-[500]  poppins">
                <img
                  src={WhiteTick}
                  alt="right"
                  className="mr-2 phone:w-[15px] phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />
                {plans[2]?.plan_details?.users ?? ""} Users
              </p>
              <p className="flex items-center mb-4  phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px]   font-[500]  poppins">
                <img
                  src={WhiteTick}
                  alt="right"
                  className="mr-2 phone:w-[15px] phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />
                {plans[1]?.plan_details?.accessibility ?? ""}
              </p>
              <p className="flex items-center mb-4  phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px]   font-[500]  poppins">
                <img
                  src={WhiteTick}
                  alt="right"
                  className="mr-2 phone:w-[15px] phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />
                {`${plans[2]?.plan_details?.automation_actions} automation actions` ??
                  ""}
              </p>
              <p className="flex items-center mb-4  phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px]   font-[500]  poppins">
                <img
                  src={WhiteTick}
                  alt="right"
                  className="mr-2 phone:w-[15px] phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />
                {plans[1]?.plan_details?.features ?? ""}
              </p>
              <p className="flex items-center mb-4  phone:text-[10px] phone:leading-[16px] phone:mb-[18px] tab:mb-[13px]   font-[500]  poppins">
                <img
                  src={WhiteTick}
                  alt="right"
                  className="mr-2 phone:w-[15px] phone:h-[15px] tab:w-[10px] tab:h-[10px]"
                />
                {plans[1]?.plan_details?.support ?? ""}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* old testimonails */}
      {/* <div className="phone:hidden testimonial  tab:overflow-x-hidden">
        <section className="container test  min-h-[100vh - 375px] w-full flex items-start justify-center tab:pt-[125px] tab:pb-[250px] pt-[125px] pb-[250px] montserrat">
          <div className="test__imgs h-[580px] w-[250px] px-2 flex flex-col justify-between">
            <div className="w-full flex justify-end items-center big-avatar">
              <img src={Av1} alt="avatar" className="tab:w-[72px]" />
            </div>
            <div className=" w-full flex justify-start items-center">
              <img src={Av2} alt="avatar" className="tab:w-[49px]" />
            </div>
            <div className=" w-full flex justify-end items-center big-avatar">
              <img src={Av3} alt="avatar" className="tab:w-[56px]" />
            </div>
          </div>

          <div className="test__cnt w-[547px] flex flex-col items-center h-[500px] text-center mx-[4.5rem]">
            <h4 className="test__cnt__heading mb-[4.5rem] text-[#272D4E] text-[2rem] font-bold tab:text-[24px]">
              Trusted by many business owner & organisations
            </h4>
            <div className="test__cnt__desc bg-white  rounded-[15px] w-[463.5px] h-[463.5px] relative">
              <div className="testimonial-card-shadow bg-white rounded-[15px] w-[508.5px] h-[272.25px] shadow-[ 0px 13.5px 39.6403px rgba(215, 228, 249, 0.5)] absolute bottom-4 -right-[22.5px] ">
                <img
                  src={DotsRight}
                  alt="svg"
                  className="absolute -right-[73px] -top-3 z-0"
                />
                <img
                  src={DotsLeft}
                  alt="svg"
                  className="absolute -left-[73px] -bottom-[60px] z-0"
                />
                <div className="flex absolute -bottom-[66px] right-0">
                  <img src={LeftArrow} alt="arrow left" className="mr-5" />

                  <img src={RightArrow} alt="arrow right" />
                </div>
                <div className="testimonial-card-shadow bg-white h-[293px] w-full rounded-[15px] flex justify-center items-center px-7 py-5 text-center z-9999 !relative bottom-9 right-5">
                  <img
                    src={QuoteMark}
                    alt="quotation mark"
                    className="absolute top-10"
                  />
                  <p className=" text-[#94A2B3] text-base z-50 tab:text-[13px]">
                    " Implementing ITSM has been a game-changer for our
                    business. The system's self-service portal has made it easy
                    for our employees to submit service requests and find the
                    information they need, reducing the burden on our IT team.”
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="test__imgs h-[500px] w-[250px] px-2 flex flex-col justify-between ">
            <div className="w-full flex justify-start items-center small-avatar">
              <img src={Av4} alt="avatar" className="tab:w-[50x]" />
            </div>
            <div className=" w-full flex justify-end items-center big-avatar">
              <img src={Av5} alt="avatar" className="tab:w-[82px]" />
            </div>
            <div className="w-full flex justify-start items-center small-avatar">
              <img src={Av6} alt="avatar" className="tab:w-[47px]" />
            </div>
          </div>
        </section>
      </div>
      <div className="hidden phone:block phone:mb-[262px]">
        <div className=" w-full flex flex-col items-center text-center m-0 pt-[70px] phone:mb-[155px] ">
          <h4 className="mb-[4.5rem] text-[#272D4E] text-[2rem] font-bold">
            Trusted by many business owner & organisations
          </h4>
          <div className=" rounded-[15px]">
            <div className="testimonial-card-shadow  rounded-[15px] shadow-[ 0px 13.5px 39.6403px rgba(215, 228, 249, 0.5)] ">
              <div className="testimonial-card-shadow bg-white w-[90vw] px-5 pt-[62px] pb-6 relative rounded-[15px] flex justify-center items-center text-center phone:m-auto phone:w-[88%] phone:p-[20px] phone:pt-[60px]">
                <img
                  src={test1}
                  alt=""
                  width="262"
                  height="198"
                  className="absolute z-[-1] bottom-[-40%] left-[-6vw]"
                />
                <img
                  src={test2}
                  alt=""
                  width="205"
                  height="139"
                  className="absolute  z-[-1] top-[-22%] right-[-6vw]"
                />

                <img
                  src={QuoteMark}
                  alt="quotation mark"
                  className="absolute top-10 phone:w-[83px]  phone:top-[23px]"
                />
                <p className=" text-[#94A2B3] text-base z-50">
                  " Implementing ITSM has been a game-changer for our business.
                  The system's self-service portal has made it easy for our
                  employees to submit service requests and find the information
                  they need, reducing the burden on our IT team.”
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* new testimonial */}
      <section className="testi-wrapper">
        <div className="testi px-[16px]">
          <header className="testi__header">
            <img
              src={testiImg}
              alt=""
              width="37"
              height="27"
              className="testi__header__icon"
            />
            <h3 className="testi__header__sub-heading font-[600] gillory ">
              Testimonial
            </h3>
            <h2 className="testi__header__heading  font-[800] gillory ">
              What Our Customers Say
            </h2>
          </header>
          <main className="testi__list">
            <div className="testi__list__person">
              <p className="testi__list__person__feedback text-[#616161] font-[400]  poppins">
                "Implementing ITSM has been a game-changer for our business. The
                system's self-service portal has made it easy for our employees
                to submit service requests and find the information they need,
                reducing the burden on our IT team.”
              </p>
              <div className="testi__list__person__ratings">
                {[1, 2, 3, 4].map((e) => (
                  <span className="testi__list__person__ratings__given-star">
                    &#9733;
                  </span>
                ))}

                <span className="testi__list__person__ratings__ungiven-star">
                  &#9733;
                </span>
              </div>
              <div className="testi__list__person__bio">
                <img
                  src={testiPic}
                  width="37"
                  height="37"
                  alt=""
                  className="testi__list__person__bio__img"
                />
                <div className="testi__list__person__bio__details font-[600]  poppins">
                  Justus Menke, Los Angeles CA
                </div>
              </div>
            </div>
          </main>
        </div>
        <img src={testBg1} className="testi-wrapper-bg-1" alt="" />
        <img src={testBg2} className="testi-wrapper-bg-2" alt="" />
      </section>
    </WebsiteLayout>
  );
};

export default HomePage;
