import React, { useEffect, useState } from "react";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useCreateOrUpdate } from "../../hooks";
import Navbar from "../../components/layouts/Navbar";
import { AllIncidentDialog } from "../../components/incidents/AllIncidentsDialog";
import { StatusCard } from "../../components/layouts/commonLayouts/cards/StatusCard";
import { Form, Formik } from "formik";
import { Box, Grid, CircularProgress } from "@mui/material";
import { Input } from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import { Search } from "@carbon/icons-react";
import ResultsNotfoud from "../../components/layouts/ResultsNotfoud";
import { Link } from "react-router-dom";
import { columns } from "./IncidentColumns";

const initialValues = {
  serach: "",
};
const IncSearch = ({ history }) => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role === "User") {
      history.push("/view-status");
    } else {
      document.title = "Search Incidents...";
      document.title = "All Incidents UnAssigned";
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page_data: {
        country: 'India',
        language: 'en',
        page_url: window.location.href,
        page_category: 'Incident Management',
        page_location: 'SearchIncident',
        page_referrer: document.referrer,
        page_title: document.title,
        region: 'Pune',
        site_section: 'Support',
      },
      user_data: {
        user_id: JSON.parse(localStorage.getItem("data")).user_id,
        user_login_state: 'User',
        user_type: JSON.parse(localStorage.getItem("data")).user_role,
      }
    });
    console.log(window.dataLayer)
    }
    // eslint-disable-next-line
  }, []);

  const { mutate, isLoading } = useCreateOrUpdate({
    url: "/incidents/search/",
    method: "post",
  });

  return (
    <>
      <Navbar heading="SEARCH INCIDENTS" subheading="" />
      <section className="main-container">
        <Formik
          initialValues={{ initialValues }}
          onSubmit={(values) => {
            setSearch(values.search);
            mutate(
              { q: values.search },
              {
                onSuccess: (data) => {
                  setData(data.data);
                },
              }
            );
          }}
        >
          {(formik) => (
            <Form>
              <Grid container columnSpacing={3} className="mt-2 mb-4">
                <Grid xs={10} item>
                  <Input
                    className="mt-2"
                    name="search"
                    placeholder="Search...."
                  />
                </Grid>
                <Grid xs={2} item>
                  <Box className="mt-2">
                    <Button
                      startIcon={<Search />}
                      size="large"
                      className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                      variant="contained"
                      type="submit"
                      isLoading={isLoading}
                    >
                      Search
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              {isLoading === true ? (
                <Box className="flex justify-center items-center h-full absolute top-20 left-[50%]">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {formik.submitCount !== 0 && data.length === 0 ? (
                    <ResultsNotfoud search={search} />
                  ) : (
                    <DataGrid
                      columns={columns}
                      rows={data}
                      isLoading
                      getRowId={(row) => row?.inc_num}
                      noSearch
                    />
                  )}
                </>
              )}
            </Form>
          )}
        </Formik>
      </section>
    </>
  );
};

export default IncSearch;
