import React, { Fragment, useEffect } from "react";
import Navbar from "../../components/layouts/Navbar";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from "../../hooks";
import { Link } from "react-router-dom";
import removeTags from '../../utils/removeTags';
import { useMediaQuery } from "@mui/material";
import RequestCard2 from "./RequestCard";
import { PageHeader } from "../../components/layouts/pageHeader";
const RequestReturnReplace = ({ history }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  useEffect(() => {
    if (
      !JSON.parse(localStorage.getItem("data")).in_assets_team &&
      JSON.parse(localStorage.getItem("data")).user_role !== "Admin"
    ) {
      history.push("/requests-raised-by-me");
    } else {
      document.title = "All Requests For Return & Replace";
      window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page_data: {
        country: 'India',
        language: 'en',
        page_url: window.location.href,
        page_category: 'Incident Management',
        page_location: 'RequestReturn&Replace',
        page_referrer: document.referrer,
        page_title: document.title,
        region: 'Pune',
        site_section: 'Support',
      },
      user_data: {
        user_id: JSON.parse(localStorage.getItem("data")).user_id,
        user_login_state: 'User',
        user_type: JSON.parse(localStorage.getItem("data")).user_role,
      }
    });
    console.log(window.dataLayer)
    }
    // eslint-disable-next-line
  }, []);

  const { data } = useGetAll({
    key: "/requests/product/return/0",
    params: {
      page: 0,
      limit: 25,
    },
  });
  const columns = [
    {
      headerName: "Number",
      field: "req_num",
      // minWidth: 130,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Link to={`/update-request/${row?.req_num?.req_num}`}>
            <div className="text-black underline">{row?.req_num?.req_num}</div>
          </Link>
        );
      },
    },
    {
      headerName: "Requested At",
      field: "created_on",
      // minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Justification",
      field: "justification",
      // minWidth: 150,
      flex: 1.5,
      renderCell: ({ row }) => {
        return removeTags(row?.justification)
        // return row?.justification
        //   .replace(/(<([^>]+)>)/gi, "")
        //   .replace(/&(nbsp|amp|quot|lt|gt);/g, "");
      },
    },
    {
      headerName: "Return/Replace",
      field: "returnORreplace",
      // minWidth: 150,
      flex: 1,
    },
  ];
  return (
    <>
      <Navbar
        heading={"All Requests For Return & Replace".toUpperCase()}
        subheading=""
      />
      <PageHeader header={"All Requests For Return & Replace"} className="mb-4" />
      {isMobile ? (
        data?.rows?.map((row, index) => <RequestCard2 key={index} row={row}/>)
       ) :(
      <DataGrid
        columns={columns}
        rows={data || []}
        url={"/requests/product/return/0"}
      />)}
    </>
  );
};

export default RequestReturnReplace;
