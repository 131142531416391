import React, { useState } from "react";
import { Tabs as MuiTabs, Tab, Box } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="h-full overflow-y-auto border-2"
      sx={{
        border: '2px solid #ccc',
      }}
      {...other}
    >
      {value === index && <Box sx={{ p: 0,  border: "2px solid #E0E0E0" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function Tabs({ tabs, query }) {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
 
  return (
    <Box className="w-full h-full overflow-y-auto">
      <div className="overflow-x-auto">
        <MuiTabs
          value={value}
          onChange={handleChange}
          variant={"standard"}
          aria-label="basic tabs example"
          className="flex justify-between "
        >
          {tabs.map((item) => (
            <Tab
              className={`${
                item.id !== value ? "text-dark" : "text-primary"
              } capitalize xl:text-sm 2xl:text-base`}
              key={item.id}
              label={item.buttonLabel}
              sx={{
                borderTop: "2px solid #E0E0E0",  
                borderLeft: "2px solid #E0E0E0",  
                borderRight: "2px solid #E0E0E0",  
              }}
              {...a11yProps(item.id)}
            />
          ))}
        </MuiTabs>
      </div>
      <Box >
        {tabs.map((item) => (
          <TabPanel value={value} index={item.id} key={item.id} sx={{
            border: "2px solid #E0E0E0",  
          }}>
            {typeof item.component === "string"
              ? item.component
              : React.cloneElement(
                  item.component,
                  item?.componentProps
                    ? { ...item?.componentProps, query }
                    : { query }
                )}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}
