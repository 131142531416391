import { Grid, Box } from "@mui/material";
import React from "react";
import { AutoComplete, Input } from "../layouts/forms/inputs";
import { Dialog } from "../layouts/commonLayouts/dialogBox/dialog";
import { Button } from "../layouts/commonLayouts/buttons";
import { Form, Formik } from "formik";
import { Edit } from "@carbon/icons-react";
import { Link } from "react-router-dom";
import removeTags from "../../utils/removeTags";

export const AllIncidentDialog = ({ data }) => {
  return (
    <Dialog
      title="INCIDENT PREVIEW"
      button={
        <Button
          startIcon={<Edit />}
          className="capitalize xl:text-sm 2xl:text-semi-base"
          variant="text"
        >
          View/Edit
        </Button>
      }
    >
      {({ onClose }) => (
        <Formik initialValues={{ ...data, short_desc: removeTags(data.short_desc) }} onSubmit={() => {}}>
          {({ values }) => (
            <Form>
              <Grid container columnSpacing={4} className="mt-2 grid-cols-1 sm:flex-col tab:grid-cols-2 p-4">
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="inc_num"
                    label="Number"
                    required
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="contact_type"
                    label="Preferred Contact Mode"
                    defultValue={values?.contact_type ? values?.contact_type : "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <AutoComplete
                    disabled
                    className="mt-2"
                    name="raised_by"
                    label="Raised By"
                    required
                    getOptionLabel={(option) =>
                      option?.profile?.full_name || "-"
                    }
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="state"
                    label="Status"
                    defultValue={values?.state ? values?.state : "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <AutoComplete
                    disabled
                    className="mt-2"
                    name="caller"
                    label="Raised For"
                    required
                    getOptionLabel={(option) =>
                      option?.profile?.full_name || "-"
                    }
                  />
                </Grid>

                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="impact"
                    label="Impact"
                    defultValue={values?.impact ? values?.impact : "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <AutoComplete
                    disabled
                    className="mt-2"
                    name="category"
                    label="Category"
                    getOptionLabel={(option) => option?.name || "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="urgency"
                    label="Urgency"
                    defultValue={values?.urgency ? values?.urgency : "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <AutoComplete
                    disabled
                    className="mt-2"
                    name="sub_category"
                    label="Sub Category"
                    getOptionLabel={(option) => option?.name || "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="priority"
                    label="Priority"
                    defultValue={values?.priority ? values?.priority : "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <AutoComplete
                    disabled
                    className="mt-2"
                    name="assigned_grp"
                    label="Department"
                    getOptionLabel={(option) => option?.name || "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="resolved_on"
                    label="Closed at" //Change this to resolved at later
                    defultValue={
                      values?.resolved_on ? values?.resolved_on : "-"
                    }
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <AutoComplete
                    disabled
                    className="mt-2"
                    name="assigned_to"
                    label="Assigned To"
                    getOptionLabel={(option) =>
                      option?.profile?.full_name || "-"
                    }
                  />
                </Grid>
                <Grid xs={12} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="service"
                    label="Affected Service"
                    defultValue={values?.service ? values?.service : "-"}
                  />
                </Grid>
                <Grid xs={12} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="short_desc"
                    label="Short Description"
                    multiline
                    maxRows={3}
                    defultValue={values?.short_desc ? values?.short_desc : "-"}
                  />
                </Grid>

                <Grid xs={12} item>
                  <Box className="mt-5 d-flex justify-content-end">
                    <Link to={`/update-incident/${data?.inc_num}`}>
                      <Button
                        startIcon={<Edit />}
                        className="px-4 ml-4 capitalize  xl:text-sm 2xl:text-semi-base"
                        variant="contained"
                        onClick={onClose}
                      >
                        Edit
                      </Button>
                    </Link>
                    <Button
                      color="secondary"
                      className="px-4 ml-4 capitalize  xl:text-sm 2xl:text-semi-base"
                      variant="contained"
                      onClick={onClose}
                    >
                      Close
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
