import { Add, Edit } from "@carbon/icons-react";
import { Grid, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Input, AutoComplete, Checkbox } from "../layouts/forms/inputs";
import { Dialog } from "../layouts/commonLayouts/dialogBox/dialog";
import { Button } from "../layouts/commonLayouts/buttons";
import { useCreateOrUpdate } from "../../hooks/useCreateOrUpdate";
import { useQueryClient } from "react-query";
import { Form, Formik } from "formik";
import { toast } from "../../utils/toaster";
import { mediaUrl, url } from "../../urls";
import * as yup from "yup";

// const validations = yup.object({
//   name: yup
//     .string()
//     .trim("This field cannot include leading and trailing spaces")
//     .strict(true)
//     .required("Name is required")
//     .typeError("Name must be a string"),
//   description: yup
//     .string()
//     .trim("This field cannot include leading and trailing spaces")
//     .strict(true)
//     .required("Description is required")
//     .typeError("Description must be a string"),
//   additional_detail: yup
//     .string()
//     .trim("This field cannot include leading and trailing spaces")
//     .strict(true)
//     .required("Description is required")
//     .typeError("Description must be a string"),
// });

export const ProductDialog = ({ isUpdate = false, data, product_type }) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreateOrUpdate({
    url:
      product_type === "service"
        ? isUpdate
          ? `/requests/product/Service/${data.id}`
          : "/requests/product/Service/0"
        : isUpdate
        ? `/requests/product/Product/${data.id}`
        : "/requests/product/Product/0",
    method: isUpdate ? "put" : "post",
  });
  const { mutate: imageMutate } = useCreateOrUpdate({
    url: `/requests/product/image/${data?.id}`,
    method: "put",
  });
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [Image, setImage] = useState(null);
const validations = yup.object({
  
});

  const initialState = {
    name: "",
    image: "",
    description: "",
    category: {},
    sub_category: {},
    is_ad_related: false,
    additional_detail: "",
    price: 0,
    product_type: product_type,
    is_active: true,
  };
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <Dialog
      title={`${isUpdate ? "Update" : "Add New"} ${product_type}`}
      button={
        isUpdate ? (
          <Button
            startIcon={<Edit />}
            className="capitalize xl:text-sm 2xl:text-semi-base"
            variant="text"
          >
            View/Edit
          </Button>
        ) : (
          <Button
            variant="outlined"
            className="px-4 ml-6 capitalize xl:text-sm  2xl:text-semi-base h-9"
            startIcon={<Add size={24} />}
          >
            New Product
          </Button>
        )
      }
    >
      {({ onClose }) => (
        <Formik
          validationSchema={validations}
          initialValues={{
            ...initialState,
            ...data,
          }}
          // validationSchema={validations}
          onSubmit={(values, { resetForm }) => {
            if (isUpdate) {
              const formdata = new FormData();
              formdata.append("image", Image);
              formdata.append("id", data?.id);
              mutate(
                {
                  additional_detail: values?.additional_detail,
                  category: values?.category?.id,
                  description: values?.description,
                  is_active: true,
                  is_ad_related: values?.is_ad_related,
                  name: values?.name,
                  price: values?.price,
                  product_type:
                    product_type === "service" ? "Service" : "Product",
                  sub_category: values?.sub_category?.id,
                },
                {
                  onSuccess: () => {
                    toast(`${product_type} updated successfully`);
                    if (Image) {
                      imageMutate(formdata, {
                        onSuccess: () => {
                          resetForm();
                          onClose();
                          setPreview(undefined);
                          queryClient.refetchQueries({
                            queryKey: [
                              product_type === "service"
                                ? "/requests/product/Service/0"
                                : "/requests/product/Product/0",
                            ],
                          });
                          toast(`${product_type} image updated successfully`);
                        },
                      });
                    }
                    resetForm();
                    onClose();
                    queryClient.refetchQueries({
                      queryKey: [
                        product_type === "service"
                          ? "/requests/product/Service/0"
                          : "/requests/product/Product/0",
                      ],
                    });
                  },
                }
              );
            } else {
              const formData = new FormData();
              formData.append("name", values?.name);
              formData.append("image", values?.image);
              formData.append("description", values?.description);
              formData.append("category", values?.category?.id);
              formData.append("sub_category", values?.sub_category?.id);
              formData.append("is_ad_related", values?.is_ad_related);
              formData.append("additional_detail", values?.additional_detail);
              formData.append("price", values?.price);
              formData.append(
                "product_type",
                product_type === "service" ? "Service" : "Product"
              );
              formData.append("is_active", values?.is_active);
              mutate(formData, {
                onSuccess: () => {
                  resetForm();
                  onClose();
                  setPreview(undefined);
                  queryClient.refetchQueries({
                    queryKey: [
                      product_type === "service"
                        ? "/requests/product/Service/0"
                        : "/requests/product/Product/0",
                    ],
                  });
                  toast(`${product_type} created successfully`);
                },
              });
            }
          }}
        >
          {({ errors, values, setFieldValue }) => (
            <Form>
              <Grid container columnSpacing={4} className="mt-2">
                <img
                  src={preview ? preview : `${mediaUrl}${data?.image}`}
                  alt=""
                  className="d-block mx-auto mb-5"
                  id="productimage1"
                  style={{ height: "250px", width: "300px" }}
                />
                <Grid xs={12} item>
                  <Input
                    className="mt-2 mb-4 h-4"
                    name="image"
                    label={`${product_type} Image`}
                    value={""}
                    type="file"
                    onChange={(e) => {
                      if (isUpdate) {
                        setImage(e.target.files[0]);
                      }
                      if (!e.target.files || e.target.files.length === 0) {
                        setSelectedFile(undefined);
                        return;
                      }
                      // I've kept this example simple by using the first image instead of multiple
                      setSelectedFile(e.target.files[0]);
                      setFieldValue("image", e.target.files[0]);
                    }}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Input className="mt-2" name="name" label="Name" required />
                </Grid>
                <Grid xs={12} item>
                  <Input className="mt-2" name="price" type="number" label="Price" />
                </Grid>
                <Grid xs={12} item>
                  <AutoComplete
                    url="/requests/product-categories/"
                    getOptionLabel={(options) => options?.name || ""}
                    className="mt-2"
                    name="category"
                    label="Category"
                    required
                  />
                </Grid>
                <Grid xs={12} item>
                  <AutoComplete
                    options={values?.category?.sub_categories}
                    getOptionLabel={(opt) => opt?.sc_name || ""}
                    className="mt-2"
                    name="sub_category"
                    label="Sub Category"
                    required
                  />
                </Grid>

                <Grid xs={12} item>
                  <Checkbox
                    name="is_ad_related"
                    label="Is Ad Related"
                    className="mt-4 text-dark"
                    checked={values.is_ad_related}
                    value={values.is_ad_related}
                    onChange={(e) => {
                      setFieldValue("is_ad_related", e.target.checked);
                    }}
                  />
                </Grid>

                <Grid xs={12} item>
                  <Input
                    className="mt-2"
                    name="description"
                    label="Description"
                    multiline
                    rows={2}
                    required
                  />
                </Grid>
                <Grid xs={12} item>
                  <Input
                    className="mt-2"
                    multiline
                    rows={2}
                    name="additional_detail"
                    label="Additional Details"
                    required
                  />
                </Grid>

                <Grid xs={12} item>
                  <Box className="mt-5 d-flex justify-content-end">
                    <Button
                      color="secondary"
                      className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                      variant="contained"
                      onClick={onClose}
                    >
                      Discard
                    </Button>
                    <Button
                      isLoading={isLoading}
                      variant="contained"
                      className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                      type="submit"
                      onClick={() => console.log({ errors }, { values })}
                    >
                      {isUpdate ? "Update" : "Save"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
