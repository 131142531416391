import React, { Fragment, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProblemContext from '../../context/ProblemContext/problemContext';
import Spinner from '../../components/layouts/Spinner';
import { PageHeader } from '../../components/layouts/pageHeader';
import UserAuthContext from '../../context/userAuthContext/userAuthContext';
import Trend from '../../components/layouts/Trend';
import MonthComparison from '../../components/layouts/MonthComparison';

const ProblemOverview = () => {
    const history = useHistory();
    const userAuthContext = useContext(UserAuthContext);
    const { clearError } = userAuthContext;
    const problemContext = useContext(ProblemContext);
    const {
        linechartData,
        pichartData,
        tableData,
        getPrbOverviewData,
        barData,
        loading,
    } = problemContext;

    const {
        discard,
        closed,
        fix_in_progress,
        root_cause_analysis,
        assess,
        resolved,
    } = pichartData || {}; // Destructure with fallback to prevent errors

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('data')).user_role === 'User') {
            history.push('/view-status');
        } else {
            getPrbOverviewData();
            document.title = 'Overview Of All Problems';
        }
        // eslint-disable-next-line
    }, []);

    if (loading) {
        return <Spinner />;
    }

    const data = {
        labels: ['WEEK1', 'WEEK2', 'WEEK3', 'WEEK4', 'WEEK5'],
        datasets: [
            {
                data: linechartData?.data1 || [], // Handle undefined data gracefully
                borderColor: '#1e6091',
                fill: false,
                label: linechartData?.label2 || '', // Handle undefined label gracefully
                borderWidth: 1.5,
            },
            {
                data: linechartData?.data2 || [],
                borderColor: '#d62828',
                fill: false,
                label: linechartData?.label1 || '',
                borderWidth: 1.5,
            },
        ],
    };

    const options = {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontColor: '#333',
                usePointStyle: true,
            },
        },
        responsive: true,
        scales: {
            x: {
                grid: {
                    color: '#f5f3f4',
                },
                ticks: {
                    font: {
                        size: 12,
                        weight: 'semi-bold',
                    },
                    color: '#90a0b7',
                },
            },
            y: {
                grid: {
                    color: '#f5f3f4',
                },
                ticks: {
                    font: {
                        size: 12,
                        weight: 'semi-bold',
                    },
                    color: '#90a0b7',
                },
            },
        },
    };
    const data1 = {
        labels: barData.lables,
        datasets: [
            {
                label: barData.this_month,
                backgroundColor: '#1e6091',
                data: barData.data2,
            },
            {
                label: barData.last_month,
                backgroundColor: '#d62828',
                data: barData.data1,
            },
        ],
    };
    const options1 = {
        indexAxis: 'y',
        responsive: true,
        title: {
            display: true,
        },
        scales: {
            x: {
                grid: {
                    color: '#f5f3f4',
                },
                ticks: {
                    beginAtZero: true,
                    font: {
                        size: 12,
                        weight: 'semi-bold',
                    },
                    color: '#90a0b7',
                },
            },
            y: {
                grid: {
                    color: '#f5f3f4',
                },
                ticks: {
                    font: {
                        size: 12,
                        weight: 'semi-bold',
                    },
                    color: '#90a0b7',
                },
            },
        },
    };


    return (
        <Fragment>
            <PageHeader header={"Problem Overview"} className="mb-4" />
            {/* Your additional content goes here */}
            {/* Ensure data is properly rendered after it's loaded */}
            <section className='main-container'>
                {loading && <Spinner />}
                {!loading && (
                    <div className='d-flex py-3 justify-content-between'>
                        <div
                            className='d-flex flex-column bg-white justify-content-between'
                            style={{
                                boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.1)',
                                width: '55%',
                            }}>
                            <div className='d-flex justify-content-between'>
                                <h2 className='fs-6 pt-3 ps-3 text-center sec-gary'>
                                    PROBLEM OVERVIEW
                                </h2>
                            </div>
                            <div className='row justify-content-between px-3 mt-3'>
                                <Link
                                    to={`/problem-proggress-bar/problems?state=New`}
                                    className='card col-md-4'>
                                    <div style={{ width: '150px', height: '150px' }}>
                                        <VisibilitySensor>
                                            {({ isVisible }) => {
                                                const percentage = isVisible ? pichartData.new : 0;
                                                return (
                                                    <CircularProgressbar
                                                        styles={buildStyles({
                                                            pathColor: `#1e6091`,
                                                            textColor: '#6c757d',
                                                            trailColor: '#f0eff4',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                        value={percentage}
                                                        text={`${percentage}%`}
                                                    />
                                                );
                                            }}
                                        </VisibilitySensor>
                                    </div>
                                    <div className='text mt-1 text-center'>New</div>
                                </Link>

                                <Link
                                    to={`/problem-proggress-bar/problems?state=Assess`}
                                    className='card col-md-4'>
                                    <div style={{ width: '150px', height: '150px' }}>
                                        <VisibilitySensor>
                                            {({ isVisible }) => {
                                                const percentage = isVisible ? assess : 0;
                                                return (
                                                    <CircularProgressbar
                                                        styles={buildStyles({
                                                            pathColor: '#0d6efd',
                                                            textColor: '#6c757d',
                                                            trailColor: '#f0eff4',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                        value={percentage}
                                                        text={`${percentage}%`}
                                                    />
                                                );
                                            }}
                                        </VisibilitySensor>
                                    </div>
                                    <div className='text mt-1 text-center'>Assess</div>
                                </Link>
                                <Link
                                    to={`/problem-proggress-bar/problems?state=Root-Cause-Analysis`}
                                    className='card col-md-4 p-0'>
                                    <div style={{ width: '150px', height: '150px' }}>
                                        <VisibilitySensor>
                                            {({ isVisible }) => {
                                                const percentage = isVisible ? root_cause_analysis : 0;
                                                return (
                                                    <CircularProgressbar
                                                        styles={buildStyles({
                                                            pathColor: '#0dcaf0',
                                                            textColor: '#6c757d',
                                                            trailColor: '#f0eff4',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                        value={percentage}
                                                        text={`${percentage}%`}
                                                    />
                                                );
                                            }}
                                        </VisibilitySensor>
                                    </div>
                                    <div className='text mt-1 text-center'>
                                        Root Cause Analysis
                                    </div>
                                </Link>
                                <Link
                                    to={`/problem-proggress-bar/problems?state=Fix-In-Progress`}
                                    className='card onhold col-md-4'>
                                    <div style={{ width: '150px', height: '150px' }}>
                                        <VisibilitySensor>
                                            {({ isVisible }) => {
                                                const percentage = isVisible ? fix_in_progress : 0;
                                                return (
                                                    <CircularProgressbar
                                                        styles={buildStyles({
                                                            pathColor: '#ffc107',
                                                            textColor: '#6c757d',
                                                            trailColor: '#f0eff4',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                        value={percentage}
                                                        text={`${percentage}%`}
                                                    />
                                                );
                                            }}
                                        </VisibilitySensor>
                                    </div>
                                    <div className='text mt-1 text-center'>Fix In Progress</div>
                                </Link>
                            </div>
                            <div className='row justify-content-around px-3 mt-5'>
                                <Link
                                    to={`/problem-proggress-bar/problems?state=Resolved`}
                                    className='card closed col-md-4'>
                                    <div style={{ width: '150px', height: '150px' }}>
                                        <VisibilitySensor>
                                            {({ isVisible }) => {
                                                const percentage = isVisible ? resolved : 0;
                                                return (
                                                    <CircularProgressbar
                                                        styles={buildStyles({
                                                            pathColor: '#198754',
                                                            textColor: '#6c757d',
                                                            trailColor: '#f0eff4',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                        value={percentage}
                                                        text={`${percentage}%`}
                                                    />
                                                );
                                            }}
                                        </VisibilitySensor>
                                    </div>
                                    <div className='text mt-1 text-center'>Resolved</div>
                                </Link>
                                <Link
                                    to={`/problem-proggress-bar/problems?state=Closed`}
                                    className='card cancelled col-md-4'>
                                    <div style={{ width: '150px', height: '150px' }}>
                                        <VisibilitySensor>
                                            {({ isVisible }) => {
                                                const percentage = isVisible ? closed : 0;
                                                return (
                                                    <CircularProgressbar
                                                        styles={buildStyles({
                                                            pathColor: '#d62828',
                                                            textColor: '#6c757d',
                                                            trailColor: '#f0eff4',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                        value={percentage}
                                                        text={`${percentage}%`}
                                                    />
                                                );
                                            }}
                                        </VisibilitySensor>
                                    </div>
                                    <div className='text mt-1 text-center'>Closed</div>
                                </Link>
                                <Link
                                    to={`/problem-proggress-bar/problems?state=Discard`}
                                    className='card cancelled col-md-4'>
                                    <div style={{ width: '150px', height: '150px' }}>
                                        <VisibilitySensor>
                                            {({ isVisible }) => {
                                                const percentage = isVisible ? discard : 0;
                                                return (
                                                    <CircularProgressbar
                                                        styles={buildStyles({
                                                            pathColor: '#6c757d',
                                                            textColor: '#6c757d',
                                                            trailColor: '#f0eff4',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                        value={percentage}
                                                        text={`${percentage}%`}
                                                    />
                                                );
                                            }}
                                        </VisibilitySensor>
                                    </div>
                                    <div className='text mt-1 text-center'>Discard</div>
                                </Link>
                            </div>
                            {/* <div className='row justify-content-between px-3 mt-5'>
                            </div> */}
                            <div className='table-responsive incOverviewTable mt-5'>
                                <table className='table incOverviewTB mb-0'>
                                    <thead>
                                        <tr
                                            className='border newRow'
                                            style={{ height: '60px', alignItems: 'center' }}>
                                            <td
                                                className='table-gary text-center align-middle'
                                                style={{ minWidth: '130px', opacity: '0' }}>
                                                hello
                                            </td>
                                            <th
                                                scope='col'
                                                className='sec-gary flex-col-grow text-center align-middle'
                                                style={{
                                                    fontWeight: '400',
                                                    fontSize: '13px',
                                                    minWidth: '100px',
                                                }}>
                                                NEW
                                            </th>
                                            <th
                                                scope='col'
                                                className='sec-gary flex-col-grow text-center align-middle'
                                                style={{
                                                    fontWeight: '400',
                                                    fontSize: '13px',
                                                    minWidth: '100px',
                                                }}>
                                                ASSESS
                                            </th>
                                            <th
                                                scope='col'
                                                className='sec-gary text-center flex-col-grow align-middle'
                                                style={{
                                                    fontWeight: '400',
                                                    fontSize: '13px',
                                                    minWidth: '200px',
                                                }}>
                                                ROOT CUAUSE ANALYSIS
                                            </th>
                                            <th
                                                scope='col'
                                                className='sec-gary text-center flex-col-grow align-middle'
                                                style={{
                                                    fontWeight: '400',
                                                    fontSize: '13px',
                                                    minWidth: '150px',
                                                }}>
                                                FIX IN PROGRESS
                                            </th>
                                            <th
                                                scope='col'
                                                className='sec-gary text-center flex-col-grow align-middle'
                                                style={{
                                                    fontWeight: '400',
                                                    fontSize: '13px',
                                                    minWidth: '100px',
                                                }}>
                                                RESOLVED
                                            </th>
                                            <th
                                                scope='col'
                                                className='sec-gary text-center flex-col-grow align-middle'
                                                style={{
                                                    fontWeight: '400',
                                                    fontSize: '13px',
                                                    minWidth: '100px',
                                                }}>
                                                CLOSED
                                            </th>
                                            <th
                                                scope='col'
                                                className='sec-gary text-center flex-col-grow align-middle'
                                                style={{
                                                    fontWeight: '400',
                                                    fontSize: '13px',
                                                    minWidth: '100px',
                                                }}>
                                                DISCARD
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-body'>
                                        {Object.entries(tableData).map(([key, value]) => (
                                            <tr
                                                key={key}
                                                className='border inc-list newRow'
                                                style={{
                                                    height: '60px',
                                                    fontSize: '13px',
                                                    minWidth: '100px',
                                                    alignItems: 'center',
                                                }}>
                                                <th
                                                    className='text-center sec-gary align-middle text-uppercase'
                                                    style={{ fontWeight: '400', minWidth: '130px' }}>
                                                    {key}
                                                </th>
                                                <td
                                                    className=' text-center flex-col-grow blue p-0'
                                                    style={{ minWidth: '100px', height: '100%' }}>
                                                    <Link
                                                        className='d-flex justify-content-center align-items-center'
                                                        style={{ width: '100%', height: '100%' }}
                                                        to={`/problem-table/problems?category=${key}&state=New`}>
                                                        {value.new}
                                                    </Link>
                                                </td>
                                                <td
                                                    className=' text-center flex-col-grow blue p-0'
                                                    style={{ minWidth: '100px', height: '100%' }}>
                                                    <Link
                                                        className='d-flex justify-content-center align-items-center'
                                                        style={{ width: '100%', height: '100%' }}
                                                        to={`/problem-table/problems?category=${key}&state=Assess`}>
                                                        {value.assess}
                                                    </Link>
                                                </td>
                                                <td
                                                    className=' text-center flex-col-grow blue p-0'
                                                    style={{ minWidth: '200px', height: '100%' }}>
                                                    <Link
                                                        className='d-flex justify-content-center align-items-center'
                                                        style={{ width: '100%', height: '100%' }}
                                                        to={`/problem-table/problems?category=${key}&state=Root-Cause-Analysis`}>
                                                        {value.root_cause_analysis}
                                                    </Link>
                                                </td>
                                                <td
                                                    className=' text-center flex-col-grow blue p-0'
                                                    style={{ minWidth: '150px', height: '100%' }}>
                                                    <Link
                                                        className='d-flex justify-content-center align-items-center'
                                                        style={{ width: '100%', height: '100%' }}
                                                        to={`/problem-table/problems?category=${key}&state=Fix-In-Progress`}>
                                                        {value.fix_in_progress}
                                                    </Link>
                                                </td>
                                                <td
                                                    className=' text-center flex-col-grow blue p-0'
                                                    style={{ minWidth: '100px', height: '100%' }}>
                                                    <Link
                                                        className='d-flex justify-content-center align-items-center'
                                                        style={{ width: '100%', height: '100%' }}
                                                        to={`/problem-table/problems?category=${key}&state=Resolved`}>
                                                        {value.resolved}
                                                    </Link>
                                                </td>
                                                <td
                                                    className=' text-center flex-col-grow blue p-0'
                                                    style={{ minWidth: '100px', height: '100%' }}>
                                                    <Link
                                                        className='d-flex justify-content-center align-items-center'
                                                        style={{ width: '100%', height: '100%' }}
                                                        to={`/problem-table/problems?category=${key}&state=Closed`}>
                                                        {value.closed}
                                                    </Link>
                                                </td>
                                                <td
                                                    className=' text-center flex-col-grow blue p-0'
                                                    style={{ minWidth: '100px', height: '100%' }}>
                                                    <Link
                                                        className='d-flex justify-content-center align-items-center'
                                                        style={{ width: '100%', height: '100%' }}
                                                        to={`/problem-table/problems?category=${key}&state=Discard`}>
                                                        {value.discard}
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div
                            style={{ width: '43%' }}
                            className='d-flex flex-column justify-content-between'>
                            <Trend name='PROBLEM' data={data} options={options} />
                            <MonthComparison
                                name='PROBLEM'
                                data1={data1}
                                options1={options1}
                            />
                        </div>
                    </div>
                )}
            </section>


        </Fragment>
    );
};

export default ProblemOverview;
