  import { Grid, Box, CircularProgress } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Form, Formik } from "formik";
  import { Input } from "../../../components/layouts/forms/inputs";

  const AnalysisInformation = ({ state,setAnalysisInfo,data }) => {
    const [loading, setLoading] = useState(true);
    
    const initialState = {
      cause_notes: "",
      description: "",
      work_around: "",
    };
    useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 200);
  
      return () => clearTimeout(timer);
    }, []);
  
    if (loading) {
      return (
        <Box className="d-flex justify-content-center align-items-center w-full" style={{ height: '50vh' }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <>
        
        <Formik
          initialValues={{ ...initialState, ...data }}
        >
          {({ values,handleChange, setFieldValue}) =>(
          <Form>
            <Grid container spacing={3} className="p-4 sm:flex-col justify-content-left sm:grid-cols-1 tab:flex-col tab:grid-cols-1">
              <Grid item xs={11}>
                <Input name="cause_notes" label="Cause Notes" multiline rows={2} disabled={state !== 'Root Cause Analysis'} onChange={(e) => {
                    handleChange(e);
                    setFieldValue("cause_notes", e.target.value);
                    setAnalysisInfo({ ...values, cause_notes: e.target.value });
                  }} required />
              </Grid>
              <Grid item xs={11}>
                <Input name="work_around" label="Workaround" multiline rows={2} disabled={state !== 'Root Cause Analysis'} 
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue("work_around", e.target.value);
                  setAnalysisInfo({ ...values, work_around: e.target.value });
                }}
                required />
              </Grid>
            </Grid>
          </Form>
          )}
        </Formik>
      </>
    );
  };

  export default AnalysisInformation;
