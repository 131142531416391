import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { RequestPreview } from "../../components/requests/RequestPreview";
import { Dialog } from "../../components/layouts/commonLayouts/dialogBox/dialog";
import { Download, Edit } from "@carbon/icons-react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { StatusCard } from "../../components/layouts/commonLayouts/cards/StatusCard";

export default function RequestCard2({ row }) {
  console.log("request card", row);

  return (
    <>
      <Box sx={{ minWidth: 275 }}>
        <Card
          variant="outlined"
          className="mt-[30px] shadow-lg transition duration-300 transform hover:-translate-y-1 hover:shadow-2xl"
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {row.req_num}
            </Typography>
            <Typography variant="h5" component="div">
              {row?.raised_by?.profile?.full_name ||
                row?.requested_by?.profile?.full_name}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {row.prio}
            </Typography>
            <Typography
              variant="p"
              className="text-ellipsis overflow-hidden w-[30px]"
            >
              {row?.short_desc || row?.description}
            </Typography>
            <br />
            <div className='flex'>
            <Typography sx={{mt:1}} color="text.secondary" className='mr-3' >
              STATUS:  
            </Typography>
              <StatusCard
                text={row?.state}
                variant={row?.state?.toLowerCase()?.replace(/ /g, "_")}
              />
            </div>
          </CardContent>

          <RequestPreview data={row}></RequestPreview>
        </Card>
      </Box>
    </>
  );
}
