/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { BiGitPullRequest } from "react-icons/bi";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  Avatar,
  Button,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import userAuthContext from "../../../context/userAuthContext/userAuthContext";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useLocation, useHistory } from "react-router-dom";
import { FiHelpCircle, FiSettings } from "react-icons/fi";
import { MdOutlineReportProblem, MdErrorOutline } from "react-icons/md";
import { BiTask } from "react-icons/bi";
import { url } from "../../../urls";
import { Search } from "../forms/inputs";
import { LogoIcon } from "../../../utils/Icons";
import { CaretDown, CaretLeft, CaretRight } from "@carbon/icons-react";
import "material-icons/iconfont/material-icons.css";
import { useMediaQuery } from "@mui/material";

const drawerWidth = 300;

const topBarHeight = 70;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);
const prod = process.env.REACT_APP_PROD;
const isProd = prod == 1 ? true : false;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: `calc(100% - ${drawerWidth}px)`,
  marginLeft: `${drawerWidth}px`,
  marginTop: `${topBarHeight}px`,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(!open && {
    width: "100%",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

let menus = [
  {
    name: "Incident Management",
    icon: MdOutlineReportProblem,
    show: () => true,
    childrens: [
      {
        name: "Incident Overview",
        path: "/incident-overview",
        show: (user) => user?.user_role !== "User",
      },
      {
        name: "Create New",
        path: "/create-incident",
        show: () => true,
      },
      {
        name: "All Incidents",
        path: "/all-incidents",
        show: (user) => user?.user_role !== "User",
      },
      // {
      //   name: "Search Incidents",
      //   path: "/search-incidents",
      //   show: (user) => user?.user_role !== "User",
      // },
      {
        name: "Incidents Assigned to me",
        path: "/incidents-assigned-to-me",
        show: () => true,
      },
      {
        name: "Incidents Raised By Me",
        path: "/incidents-raised-by-me",
        show: () => true,
      },
      // {
      //   name: "Open-Unassigned Incidents",
      //   path: "/open-unassigned-incidents",
      //   show: (user) => user?.user_role !== "User",
      // },
      {
        name: "Open Incidents",
        path: "/open-incidents",
        show: (user) => user?.user_role !== "User",
      },
      {
        name: "Resolved Incidents",
        path: "/resolved-incidents",
        show: (user) => user?.user_role !== "User",
      },
    ],
  },
  {
    name: "Request Management",
    icon: BiGitPullRequest,
    show: () => true,
    childrens: [
      {
        name: "Request Overview",
        path: "/request-overview",
        show: (user) => user?.user_role !== "User",
      },
      {
        name: "Create Service Request",
        path: "/request-services",
        show: () => true,
      },
      {
        name: "Create Product Request",
        path: "/request-products",
        show: () => true,
      },

      {
        name: "Requests Raised By Me",
        path: "/requests-raised-by-me",
        show: () => true,
      },
      {
        name: "First Approval Pending",
        path: "/first-approval-pending-requests",
        show: (user) =>
          user?.user_role === "Service Manager" || user?.user_role === "Admin",
      },
      {
        name: "Second Approval Pending",
        path: "/second-approval-pending-requests",
        show: (user) => user?.is_skip_manager || user?.user_role === "Admin",
      },
      // {
      //   name: "Search Requests",
      //   path: "/search-requests",
      //   show: (user) => user?.in_assets_team || user?.user_role === "Admin",
      // },
      {
        name: "Awaiting FullFillment",
        path: "/requests-for-fullfillment",
        show: (user) => user?.in_assets_team || user?.user_role === "Admin",
      },
      {
        name: "Requests For Return/Replace",
        path: "/requests-for-return-and-replace",
        show: (user) => user?.in_assets_team || user?.user_role === "Admin",
      },
      {
        name: "All Requests",
        path: "/all-requests",
        show: (user) => user?.in_assets_team || user?.user_role === "Admin",
      },
      {
        name: "Open Requests",
        path: "/open-requests",
        show: (user) => user?.in_assets_team || user?.user_role === "Admin",
      },
      {
        name: "Closed Complete Requests",
        path: "/closed-complete-requests",
        show: (user) => user?.in_assets_team || user?.user_role === "Admin",
      },
      {
        name: "Other Product/Service Requests",
        path: "/other-requests",
        show: (user) => user?.is_asset_manager || user?.user_role === "Admin",
      },
    ],
  },
  {
    name: "Problem Management",
    icon: MdErrorOutline,
    show: () => true,
    childrens: [
      {
        name: "Problem Overview",
        path: "/problem-overview",
        show: () => true,
      },
      {
        name: "Create New",
        path: "/create-problem",
        show: () => true,
      },
      {
        name: "All New Problem",
        path: "/all-new-create-problem",
        show: (user) => user?.user_role !== "User",
      },
      {
        name: "Problems Assigned to me",
        path: "/problems-assigned-to-me",
        show: () => true,
      },
    ],
  },
  {
    name: "Task Management",
    icon: BiTask,
    show: () => true,
    childrens: [
      // {
      //   name: "Create New",
      //   path: "/create-task",
      //   show: () => true,
      // },
      {
        name: "My Tasks",
        path: "/my-tasks",
        show: () => true,
      },
      {
        name: "Assigned Tasks",
        path: "/assigned-tasks",
        show: () => true,
      },
      // {
      //   name: "All Tasks",
      //   path: "/all-tasks",
      //   show: (user) => user?.user_role === "Admin",
      // },
      {
        name: "Task",
        path: "/task",
        show: () => true,
      },
    ],
  },
  {
    name: "Change Management",
    icon: BiTask,
    show: () => true,
    childrens: [
      // {
      //   name: "Change Overview",
      //   path: "/change-overview",
      //   show: () => true,
      // },
      {
        name: "Create New",
        path: "/create-new-change",
        show: () => true,
      },
      {
        name: "All Changes",
        path: "/all-changes",
        show : () => true,
      },
      {
        name: "Changes Assigned To Me",
        path: "/changes-assigned-to-me",
        show : () => true,
      },
      {
        name: "Changes Raised By Me",
        path: "/changes-raised-by-me",
        show : () => true,
      },
      {
        name: "Open Changes",
        path: "/open-changes",
        show : () => true,
      },
      {
        name: "Implement Changes",
        path: "/implement-changes",
        show : () => true,
      }
    ],
  },
  {
    name: "Settings",
    icon: FiSettings,
    show: (user) => user?.user_role === "Admin" || user?.is_asset_manager,
    childrens: [
      {
        name: "Users",
        path: "/users",
        show: (user) => user?.user_role === "Admin",
      },
      // {
      //   name: "Workflow",
      //   path: "/workflow-configuration",
      //   show: (user) => user?.user_role === "Admin",
      // },
      // {
      //   name: "SLA Configuration",
      //   path: "/sla-configuration",
      //   show: (user) => user?.user_role === "Admin",
      // },
      // {
      //   name: "Email Configuration",
      //   path: "/email-config",
      //   show: (user) => user?.user_role === "Admin",
      // },
      // {
      //   name: "Notifications",
      //   path: "/notifications",
      //   show: (user) => user?.user_role === "Admin",
      // },
      // {
      //   name: "Skip Level Management",
      //   path: "/request-approval",
      //   show: (user) => user?.user_role === "Admin",
      // },
      // {
      //   name: "Asset Management",
      //   path: "/asset-management",
      //   show: (user) => user?.user_role === "Admin",
      // },
      {
        name: "Configuration",
        path: "/configuration",
        show: (user) => user?.user_role === "Admin",
      },
      {
        name: "Category",
        path: "/category",
        show: (user) => user?.user_role === "Admin",
      },
      {
        name: "Departments",
        path: "/departments",
        show: (user) => user?.user_role === "Admin",
      },
      { 
        name: "Problem Config Items",
        path: "/items-configuration",
        show: (user) => user?.user_role === "Admin",
      },
      {
        name: "Problem Category",
        path: "/problem-category",
        show: (user) => user?.user_role === "Admin",
      },
      {
        name: "Products",
        path: "/product",
        show: (user) => user?.is_asset_manager || user?.user_role === "Admin",
      },
      {
        name: "Services",
        path: "/service",
        show: (user) => user?.is_asset_manager || user?.user_role === "Admin",
      },
    ],
  },
];

export default function SideNavbar2({ children }) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const nav = isMobile ? false : true;
  const { userInfo, logout } = React.useContext(userAuthContext);
  const { pathname } = useLocation();
  const { push, goBack } = useHistory();
  const [open, setSidebarOpen] = React.useState(nav);
  const [menu, setMenu] = React.useState("");
  const [appBarTitle, setAppBarTitle] = React.useState("");

  const handleClick = (menu) => {
    setMenu(menu);
  };

  React.useEffect(() => {
    if (pathname.includes("problem")) {
      setAppBarTitle("Problem Management");
    } else if (pathname.includes("incident")) {
      setAppBarTitle("Incident Management");
    } else if (pathname.includes("task")) {
      setAppBarTitle("Task Management");
    } else if (pathname.includes("request")) {
      setAppBarTitle("Request Management");
    } else if (pathname.includes("change")) {
      setAppBarTitle("Change Management");
    } else {
      setAppBarTitle("Setting");
    }
  }, []);
  const [avatarAnchorEl, setAnchorEl] = React.useState(null);
  const avatarMenuOpen = Boolean(avatarAnchorEl);
  const avatarMenuOnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const avatarHandleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MuiAppBar
        position="fixed"
        sx={{
          height: `${topBarHeight}px`,
          width: "100%",
          margin: "0px",
          background: "#fff",
          borderBottom: "1px solid #E5E5E5",
        }}
      >
        <Toolbar className="flex justify-between items-center">
          <Box className="flex">
            <LogoIcon className="mt-1 mr-1" />
            <Typography
              variant="h6"
              sx={{ color: "#242424", fontSize: "26px" }}
            >
              ITSM
            </Typography>
          </Box>
          <Box className="cursor-pointer text-dark flex p-1">
            <Search className="mr-4 mt-2" />

            <div>
              <IconButton
                id="basic-button"
                aria-controls={avatarMenuOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={avatarMenuOpen ? "true" : undefined}
                onClick={avatarMenuOnClick}
              >
                <Avatar
                  sx={{ width: 40, height: 40 }}
                  alt={`${userInfo?.full_name}` || "Username"}
                  src={url + userInfo?.profile_pic}
                  className="mt-1"
                />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={avatarAnchorEl}
                open={avatarMenuOpen}
                onClose={avatarHandleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          </Box>
        </Toolbar>
      </MuiAppBar>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} sx={{ background: "#fff" }}>
          <Toolbar className="flex justify-between items-center">
            <Box className="flex">
              {/* <Box
                className="p-1 rounded mr-2 mt-1 cursor-pointer"
                onClick={() => goBack()}
                sx={{
                  height: "32px",
                  width: "32px",
                  backgroundColor: "#7F8DE1",
                }}
              >
                <CaretLeft size={24} fill="#161616" />
              </Box> */}
              <Box className="" style={{ zIndex: "99999999999999" }}>
                <Button
                  onClick={() => {
                    setSidebarOpen(!open);
                  }}
                >
                  {open ? <CaretLeft size={35} /> : <CaretRight size={35} />}
                </Button>
              </Box>

              <Box className="ml-4">
                <Box className="flex">
                  <Typography
                    variant="h6"
                    sx={{ color: "#242424", fontSize: "18px" }}
                  >
                    {appBarTitle}
                  </Typography>
                  <CaretDown size={24} fill="#000" className="ml-2 mt-1" />
                </Box>
                <Typography
                  variant="h6"
                  className="capitalize"
                  sx={{ color: "#6F6F6F", fontSize: "13px" }}
                >
                  {pathname.replace(/-/g, " ").replace(/\//, "").split("/")[0]}

                  {pathname
                    .replace(/-/g, " ")
                    .replace(/\//, "")
                    .split("/")[1] && (
                    <span className="text-fc-main">
                      {" "}
                      |{" "}
                      {
                        pathname
                          .replace(/-/g, " ")
                          .replace(/\//, "")
                          .split("/")[1]
                      }
                    </span>
                  )}
                </Typography>
              </Box>
            </Box>
            {/* <Box className="cursor-pointer text-dark" onClick={() => logOut()}>
              <IconButton>
                <Logout />
              </IconButton>
              Logout
            </Box> */}
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: open ? drawerWidth : "0px",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              boxShadow: "4px 0px 4px 0 rgba(0, 0, 0, 0.2)",
              width: drawerWidth,
              boxSizing: "border-box",
              marginTop: `${topBarHeight}px`,
              transition: "width 2s",
            },
          }}
          variant="persistent"
          anchor="left"
          className="scroll sm:h-screen sm:fixed sm:z-40 "
          open={open}
        >
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            className="mt-3 sm:h-screen"
          >
            {menus.map((item) => {
              return (
                <>
                  {item?.show(userInfo) && (
                    <>
                      <ListItemButton
                        onClick={() => {
                          // setAppBarTitle(item.name);
                          handleClick(menu === item.name ? "" : item.name);
                        }}
                        className={
                          menu === item.name
                            ? "!text-primary bg-[#D7EDFF] !border-primary border-l-4 border-solid !font-medium px-2"
                            : "px-2"
                        }
                      >
                        <ListItemIcon sx={{ minWidth: "30px" }}>
                          <item.icon
                            size={22}
                            className={
                              menu === item.name ? "!text-primary" : ""
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                        {menu === item.name ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse
                        in={menu === item.name}
                        timeout="auto"
                        className="m-0 w-full"
                        sx={{
                          "& .MuiCollapse-wrapper, & .MuiCollapse-wrapper .MuiCollapse-wrapperInner":
                            {
                              margin: 0,
                            },
                        }}
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {item.childrens.map(
                            (child) =>
                              child.show(userInfo) && (
                                <>
                                  <ListItemButton
                                    onClick={() => {
                                      setAppBarTitle(item.name);

                                      push(child.path); // Navigate to the clicked path
                                      isMobile
                                        ? setSidebarOpen(false)
                                        : setSidebarOpen(true); // Close or open the sidebar based on the device type
                                    }}
                                    className={`flex justify-start items-center !text-secondary  ${
                                      pathname === child.path
                                        ? "!text-secondary !font-medium !bg-secondary-light border-[#ABABAB] border-l-4 border-solid"
                                        : ""
                                    }`}
                                    sx={{ ml: 5 }}
                                  >
                                    <ListItemText primary={child.name} />
                                  </ListItemButton>
                                </>
                              )
                          )}
                        </List>
                      </Collapse>
                    </>
                  )}
                </>
              );
            })}
          </List>

          <Box>
            <Divider />
            <a
              target="_blank"
              rel="noreferrer"
              className="helpLink !text-secondary"
              href={process.env.REACT_APP_HELP_DOCS_URL}
            >
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  <FiHelpCircle size={22} />
                </ListItemIcon>
                <ListItemText primary={"Help"} className="!text-secondary" />
              </ListItemButton>
            </a>
          </Box>
        </Drawer>
        {/* <Box
          className="fixed bottom-5 float-end "
          style={{ zIndex: "99999999999999" }}
        >
          <Button
            onClick={() => {
              setSidebarOpen(!open);
            }}
          >
            {open ? <CaretLeft size={35} /> : <CaretRight size={35} />}
          </Button>
        </Box> */}
        <Main
          open={open}
          sx={{
            background: "#FFFFFF",
            minHeight: "100vh",
            marginTop: `${topBarHeight + 40}px`,
            marginLeft: open ? "40px" : "0px",
          }}
        >
          <DrawerHeader />
          {children}
        </Main>
      </Box>
    </>
  );
}
