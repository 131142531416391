import { Cicsplex } from "@carbon/icons-react";
import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import { Input, Label } from "../layouts/forms/inputs";
import { Dialog } from "../layouts/commonLayouts/dialogBox/dialog";
import { Button } from "../layouts/commonLayouts/buttons";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { ActionCard } from "../layouts/commonLayouts/cards/ActionCards";
import { useCreateOrUpdate } from "../../hooks";
import { toast } from "../../utils/toaster";
import {pushToDataLayer} from "../DataLayer/FormData"

const initialState = {
  p1_day: 0,
  p1_time: "",
  p2_day: 0,
  p2_time: "",
  p3_day: 0,
  p3_time: "",
};

const validations = yup.object({
  p1_day: yup.number().required("Field is required"),
  p1_time: yup.string().required("Field is required"),
  p2_day: yup.number().required("Field is required"),
  p2_time: yup.string().required("Field is required"),
  p3_day: yup.number().required("Field is required"),
  p3_time: yup.string().required("Field is required"),
});

export const SLAConfigDialog = () => {
  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/portal/sla-configuration`,
    method: "post",
  });
  return (
    <Dialog
      title={"SLA Configuration"}
      button={
        <ActionCard className="mt-8 w-96 h-32" variant="primary">
          <div className="mx-10 flex items-center justify-center">
            <Cicsplex size="32" />
            <Typography
              className="ml-1 xl:text-sm  2xl:text-base"
              sx={{ fontFamily: "'Century Gothic', 'sans-serif'" }}
            >
              SLA Configuration
            </Typography>
          </div>
        </ActionCard>
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{ ...initialState }}
          validationSchema={validations}
          onSubmit={(values, { resetForm }) => {
            mutate(values, {
              onSuccess: () => {
                pushToDataLayer(values);
                resetForm();
                onClose();
                toast("Configuration saved successfully");
              },
            });
          }}
        >
          {({ errors }) => (
            <Form>
              <Grid container columnSpacing={4} className="mt-2">
                <Grid xs={12} item className="mt-4">
                  <Label text="P1-Critical" required />
                  <Grid container columnSpacing={3}>
                    <Grid xs={6} item>
                      <Input type="number" name="p1_day" />
                    </Grid>
                    <Grid xs={6} item>
                      <Input type="time" name="p1_time" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} item className="mt-4">
                  <Label text="P2-High" required />
                  <Grid container columnSpacing={3}>
                    <Grid xs={6} item>
                      <Input type="number" name="p2_day" />
                    </Grid>
                    <Grid xs={6} item>
                      <Input type="time" name="p2_time" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} item className="mt-4">
                  <Label text="P3-Normal" required />
                  <Grid container columnSpacing={3}>
                    <Grid xs={6} item>
                      <Input type="number" name="p3_day" />
                    </Grid>
                    <Grid xs={6} item>
                      <Input type="time" name="p3_time" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={12} item>
                  <Box className="mt-5 d-flex justify-content-end">
                    <Button
                      color="secondary"
                      className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                      variant="contained"
                      onClick={onClose}
                    >
                      Discard
                    </Button>
                    <Button
                      isLoading={isLoading}
                      variant="contained"
                      className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                      type="submit"
                      onClick={() => console.log(errors, "errors")}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
