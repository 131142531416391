import React from "react";
import ApprovedUsers from "./ApprovedUsersNew";
import UnapprovedUsers from "./UnapprovedUsersNew";
import { ButtonGroup } from "../../components/layouts/commonLayouts/buttons";
import BUlkCsvUpload from "./BulkCsvUpload";
const Users = () => {
  return (
    <>
      <ButtonGroup
        disableElevation
        variant="contained"
        aria-label="outlined primary button group"
        style={{
          marginBottom: "20px",
        }}
        buttons={[
          {
            key: "0",
            text: "Approved Users",
          },
          {
            key: "1",
            text: "Unapproved Users",
          },
        ]}
      >
        <ApprovedUsers key={"0"} />
        <UnapprovedUsers key={"1"} />
        <BUlkCsvUpload/>
      </ButtonGroup>
    </>
  );
};
export default Users;
