import { Add } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useCreateOrUpdate } from "../../../hooks";
import { toast } from "../../../utils/toaster";
import { Button } from "../../layouts/commonLayouts/buttons";
import { Dialog } from "../../layouts/commonLayouts/dialogBox/dialog";
import { Input } from "../../layouts/forms/inputs";

const userInfo = {
  user: localStorage.getItem("data")
    ? JSON.parse(localStorage.getItem("data"))
    : {},
};

const initialState = {
  name: "",
  ref_link: "",
  description: "",
};

const validations = yup.object({});

export const OtherDialog = () => {
  const { mutate, isLoading } = useCreateOrUpdate({
    url: "/requests/product/request/0",
    method: "post",
  });
  return (
    <Dialog
      title="Send Request For Other Service"
      button={
        <Button
          variant="contained"
          className="px-4 py-2 ml-6 capitalize xl:text-sm  2xl:text-semi-base h-9"
          startIcon={<Add size={24} />}
        >
          Other
        </Button>
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{ ...initialState }}
          validationSchema={validations}
          onSubmit={(values) => {
            mutate(
              {
                ...values,
                requested_by: userInfo?.user?.USER_ID,
              },
              {
                onSuccess: () => {
                  onClose();
                  toast("Request for other service sent succefully");
                },
              }
            );
          }}
        >
          {({ errors, values }) => (
            <Form>
              <Grid container columnSpacing={4} className="mt-2">
                <Grid xs={12} item>
                  <Input className="mt-2" name="name" label="Name" required />
                </Grid>
                <Grid xs={12} item>
                  <Input
                    className="mt-2"
                    name="ref_link"
                    label="Reference Link"
                  />
                </Grid>

                <Grid xs={12} item>
                  <Input
                    className="mt-2"
                    name="description"
                    label="Description"
                    required
                  />
                </Grid>

                <Grid xs={12} item>
                  <Box className="mt-5 d-flex justify-content-end">
                    <Button
                      color="secondary"
                      className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                      variant="contained"
                      onClick={onClose}
                    >
                      Discard
                    </Button>
                    <Button
                      isLoading={isLoading}
                      variant="contained"
                      className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                      type="submit"
                      onClick={() => console.log({ errors }, { values })}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
