// // variables
// const incState = [
//   { id: 1, name: 'New' },
//   { id: 2, name: 'In Progress' },
//   { id: 3, name: 'Resolved' },
//   { id: 4, name: 'On Hold' },
//   { id: 5, name: 'Closed' },
//   { id: 6, name: 'Cancelled' },
// ];

// const impt = [
//   { id: 1, name: 'Low' },
//   { id: 2, name: 'Medium' },
//   { id: 3, name: 'High' },
// ];

// const priority = [
//   { id: 1, name: 'Normal' },
//   { id: 2, name: 'Important' },
//   { id: 3, name: 'Critical' },
// ];

// // functions

// const CalculatePriority = (impact, urgency) => {
//   if (impact === 'High' && urgency === 'High') {
//     return 'Critical';
//   } else if (impact === 'High' && urgency === 'Medium') {
//     return 'Important';
//   } else if (impact === 'Medium' && urgency === 'High') {
//     return 'Important';
//   } else if (impact === 'Medium' && urgency === 'Medium') {
//     return 'Important';
//   } else if (impact === 'Low' && urgency === 'High') {
//     return 'Normal';
//   } else if (impact === 'Low' && urgency === 'Medium') {
//     return 'Normal';
//   } else if (impact === 'Low' && urgency === 'Low') {
//     return 'Normal';
//   } else if (impact === 'Medium' && urgency === 'Low') {
//     return 'Normal';
//   } else if (impact === 'High' && urgency === 'Low') {
//     return 'Normal';
//   }
// };

// const getSearchInc = (text, tableData, setSearch) => {
//   const data = tableData.filter((inc) => {
//     if (text === '') {
//       setSearch();
//       return inc;
//     } else {
//       const regx = new RegExp(`${text}`, 'gi');
//       return (
//         (inc.inc_num ? inc.inc_num.match(regx) : '') ||
//         (inc.caller &&
//           (inc.caller.profile
//             ? inc.caller.profile.full_name.match(regx)
//             : '-')) ||
//         (inc.raised_by &&
//           (inc.raised_by.profile
//             ? inc.raised_by.profile.full_name.match(regx)
//             : '-')) ||
//         (inc.short_desc ? inc.short_desc.match(regx) : '') ||
//         (inc.assigned_grp ? inc.assigned_grp.name.match(regx) : '') ||
//         (inc.priority ? inc.priority.match(regx) : '') ||
//         (inc.state ? inc.state.match(regx) : '') ||
//         (inc.category ? inc.category.name.match(regx) : '') ||
//         (inc.assigned_to &&
//           (inc.assigned_to.profile
//             ? inc.assigned_to.profile.full_name.match(regx)
//             : '-')) ||
//         (inc.sub_category ? inc.sub_category.name.match(regx) : '')
//       );
//     }
//   });
//   return data;
// };

// export { getSearchInc, CalculatePriority, incState, impt, priority };

const incState = [
  { id: 1, name: "New" },
  { id: 2, name: "In Progress" },
  { id: 3, name: "Resolved" },
  { id: 4, name: "On Hold" },
  { id: 5, name: "Closed" },
  { id: 6, name: "Cancelled" },
];

const importance = [
  { id: 1, name: "Low" },
  { id: 2, name: "Medium" },
  { id: 3, name: "High" },
];

const priorities = [
  { id: 1, name: "Normal" },
  { id: 2, name: "Important" },
  { id: 3, name: "Critical" },
];

const CalculatePriority = (impact, urgency) => {
  console.log('xxx', impact, urgency)
  const prioritiesMap = {
    Low: {
      Low: "Normal",
      Medium: "Normal",
      High: "Normal",
    },
    Medium: {
      Low: "Normal",
      Medium: "Important",
      High: "Important",
    },
    High: {
      Low: "Normal",
      Medium: "Important",
      High: "Critical",
    },
  };

  return prioritiesMap[impact][urgency];
};

const getSearchInc = (text, tableData, setSearch) => {
  if (text === "") {
    setSearch();
    return tableData;
  }

  const regx = new RegExp(`${text}`, "gi");
  return tableData.filter(inc => {
    const incNumMatch = inc.inc_num ? inc.inc_num.match(regx) : "";
    const callerMatch = inc.caller ? inc.caller.profile.full_name.match(regx) : "";
    const raisedByMatch = inc.raised_by ? inc.raised_by.profile.full_name.match(regx) : "";
    const shortDescMatch = inc.short_desc ? inc.short_desc.match(regx) : "";
    const assignedGrpMatch = inc.assigned_grp ? inc.assigned_grp.name.match(regx) : "";
    const priorityMatch = inc.priority ? inc.priority.match(regx) : "";
    const stateMatch = inc.state ? inc.state.match(regx) : "";
    const categoryMatch = inc.category ? inc.category.name.match(regx) : "";
    const assignedToMatch = inc.assigned_to ? inc.assigned_to.profile.full_name.match(regx) : "";
    const subCategoryMatch = inc.sub_category ? inc.sub_category.name.match(regx) : "";

    return incNumMatch || callerMatch || raisedByMatch || shortDescMatch || assignedGrpMatch || priorityMatch || stateMatch || categoryMatch || assignedToMatch || subCategoryMatch;
  });
};

export { getSearchInc, CalculatePriority, incState, importance, priorities };
