import { Add, Edit } from "@carbon/icons-react";
import { Grid, Box } from "@mui/material";
import React from "react";
import { Input, AutoComplete, Checkbox } from "../layouts/forms/inputs";
import { Dialog } from "../layouts/commonLayouts/dialogBox/dialog";
import { Button } from "../layouts/commonLayouts/buttons";
import { useCreateOrUpdate } from "../../hooks/useCreateOrUpdate";
import { useQueryClient } from "react-query";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "../../utils/toaster";
import { useGetAll } from "../../hooks";
import { FormHelperText } from "@mui/material";


const initialState = {
  name: "",
  manager: "",
  description: "",
  is_sequencing:false
};

const departmentValidations = yup.object({
  name: yup
    .string()
    .trim("This field cannot include leading and trailing spaces")
    .strict(true)
    .required("Name is required")
    .typeError("Name must be a string"),
  description: yup
    .string()
    .trim("This field cannot include leading and trailing spaces")
    .strict(true)
    .required("Description is required")
    .typeError("Description must be a string"),
  manager: yup
    .object()
    .required("Manager is required")
    .typeError("Please select valid head"),
});

export const DepartmentDialog = ({ isUpdate = false, data }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateOrUpdate({
    url: isUpdate ? `/portal/departments/${data.id}` : "portal/departments/0",
    method: isUpdate ? "put" : "post",
  });

  const { data: managerData } = useGetAll({
    key: "/accounts/managers",
  });
  // console.log(managerData, "data");

  return (
    <Dialog
      title={`${isUpdate ? "Update" : "Add New"} Department`}
      button={
        isUpdate ? (
          <Button
            startIcon={<Edit />}
            className="capitalize xl:text-sm 2xl:text-semi-base"
            variant="text"
          >
            View/Edit
          </Button>
        ) : (
          <Button
            variant="outlined"
            className="px-4 ml-6 capitalize xl:text-sm  2xl:text-semi-base h-9"
            startIcon={<Add size={24} />}
          >
            New Department
          </Button>
        )
      }
    >
      {({onClose }) => (
        <Formik
          initialValues={{ ...initialState, ...data }}
          validationSchema={departmentValidations}
          onSubmit={(values, { resetForm }) => {
            mutate(
              {
                ...values,
                manager: values?.manager?.id,
              },
              {
                onSuccess: () => {
                  resetForm();
                  onClose();
                  queryClient.refetchQueries({
                    queryKey: ["/portal/departments/0"],
                  });
                  toast(
                    `Department ${
                      isUpdate ? "updated" : "created"
                    } successfully`
                  );
                },
              }
            );
          }}
        >
          {({ values, errors, setFieldValue }) => (
          <Form>
            <Grid container columnSpacing={4} className="mt-2">
              <Grid xs={12} item>
                <Input
                  className="mt-2"
                  name="name"
                  label="Department Name"
                  required
                />
              </Grid>

              <Grid xs={12} item>
                <AutoComplete
                  options={managerData}
                  getOptionLabel={(options) =>
                    options?.profile?.full_name || "--------"
                  }
                  className="mt-2 mb-2"
                  name="manager"
                  label="Department Head"
                  required
                />
              </Grid>
              <Grid xs={12} item>
                <Input
                  className="mt-4"
                  name="description"
                  label="Description"
                  required
                />
              </Grid>
              <Grid item xs={6} className="mt-4 ml-10 sm:max-w-full">
                      <Checkbox
                        label="Enable Sequencing"
                        name="is_sequencing"
                        checked={values.is_sequencing}
                        // defaultChecked={values?.major_problem}
                        value={values.is_sequencing}
                        onChange={(_, checked) => {
                          setFieldValue("is_sequencing", checked);

                        }}
                      />
                      <FormHelperText>
                    Note: Enabling sequencing will auto-generate sequence numbers for incidents.
                  </FormHelperText>
                    </Grid>

              <Grid xs={12} item>
                <Box className="mt-5 d-flex justify-content-end">
                  <Button
                    color="secondary"
                    className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                    variant="contained"
                    onClick={onClose}
                  >
                    Discard
                  </Button>
                  <Button
                    isLoading={isLoading}
                    variant="contained"
                    className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
