import * as yup from "yup";
import {
  Grid,
  Box,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  AutoComplete,
  Label,
  Checkbox,
} from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
// import Navbar from "../../components/layouts/Navbar";
import { toast } from "../../utils/toaster";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useGetAll } from "../../hooks";
import { PageHeader } from "../../components/layouts/pageHeader";
import serverAPI from "../../utils/serverAPI";
import { useHistory, useLocation } from "react-router-dom";
import { CalculatePriority, importance } from "../../utils/IncidentUtils";
import { url } from "../../urls";
import Uitabs from "./component/Uitabs";
import { Tabs } from "../../components/layouts/commonLayouts/tabs";
import NotesForm from "./component/Notes";
import AnalysisInformation from "./component/Analysis";
import ResolutionInformation from "./component/Resolution.jsx";
import { SecondaryAccordion } from "../../components/layouts/commonLayouts/accordion/SecondaryAccordion.jsx";

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

const UpdateProblem = () => {
  const formikRef = useRef();
  const handleSubmit = () => {
    console.log("----------------->aaaaaaaaaaaaaaaaaaaa");

    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };
  const history = useHistory();
  const location = useLocation();
  const arr = location.pathname.split("/");
  // const [majorProblem, setMajorProblem] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [analysisInfo, setAnalysisInfo] = useState(null);
  const [resolutionInfo, setresolutionInfo] = useState(null);
  const [notesInfo, setNotesInfo] = useState(null);
  const [statusOption, setStatusOption] = useState([]); // Move this inside the component
  const { data, refetch } = useGetAll({
    key: `/problems/update/${arr[arr.length - 1]}`,
  });
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setDescription(data?.description || "");
    getPossibleStates(data?.state);
    setAnalysisInfo(data?.analysis_info || "");
    setresolutionInfo(data?.resolution_info || "");
    setNotesInfo(data?.notes || "");
  }, [data]);

  // const userInfo = {
  //   user: localStorage.getItem("data")
  //     ? JSON.parse(localStorage.getItem("data"))
  //     : {},
  // };

  // {id: 1 name : "New" }
  //   {id: 2, name : "Assess"}
  //   {id: 3, name :"Root Cause Analysis"}
  //   {id: 4, name : "Fix in Progress"},
  //   {id: 5, name : "Resolved"}
  //   {id: 6, name : "Discard" }
  //   {id: 7, name : "Duplicate"}
  //   {id: 8, name : "Closed"}

  const getPossibleStates = (currState) => {
    if (currState === "New") {
      setStatusOption([
        { id: 1, name: "New" },
        { id: 2, name: "Assess" },
      ]);
    } else if (currState === "Assess") {
      setStatusOption([
        { id: 2, name: "Assess" },
        { id: 3, name: "Root Cause Analysis" },
        { id: 6, name: "Discard" },
        { id: 7, name: "Duplicate" },
        { id: 8, name: "Closed" },
      ]);
    } else if (currState === "Root Cause Analysis") {
      setStatusOption([
        { id: 4, name: "Fix in Progress" },
        { id: 6, name: "Discard" },
        { id: 7, name: "Duplicate" },
        { id: 8, name: "Closed" },
      ]);
    } else if (currState === "Fix in Progress") {
      setStatusOption([
        { id: 3, name: "Root Cause Analysis" },
        { id: 2, name: "Fix in Progress" },
        { id: 4, name: "Resolved" },
        { id: 6, name: "Discard" },
        { id: 7, name: "Duplicate" },
        { id: 8, name: "Closed" },
      ]);
    } else if (currState === "Resolved") {
      setStatusOption([{ id: 8, name: "Closed" }]);
    } else if (currState === "Closed" || currState === "Discard") {
      setStatusOption([
        { id: 2, name: "Fix in Progress" },
        { id: 3, name: "Assess" },
        { id: 5, name: "Closed" },
        { id: 6, name: "Discard" },
      ]);
    }
  };
  if (isLoading) {
    return (
      <Box
        className="d-flex justify-content-center align-items-center w-full"
        style={{ height: "50vh" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const New = data?.state === "New" ? false : true;
  const Assess = data?.state === "Assess" ? false : true;
  const Analysis = data?.state === "Root Cause Analysis" ? false : true;
  const Fix = data?.state === "Fix in Progress" ? false : true;
  const Resolved = data?.state === "Resolved" ? false : true;
  const Closed = data?.state === "Closed" ? false : true;
  const Discard = data?.state === "Discard" ? false : true;

  // {
  //   caller: data?.caller ? data?.caller : {},
  //   problem_category: data?.problem_category || { id: "", name: "Select" },
  //   config_item: data?.config_item || { id: "", name: "Select" },
  //   assigned_grp: data?.assigned_grp || { id: "", name: "Select" },
  //   state: data?.state || "New",
  //   impact: data?.impact || "Low",
  //   urgency: data?.urgency || "Low",
  //   priority: data?.priority || "Normal",
  //   service: data?.service || "",
  //   assigned_to: data?.assigned_to || { id: "", name: "Select" },
  //   major_problem: data?.major_problem || false,
  //   affected_incidents: data?.affected_incidents || [],

  // }
  const initValues = { ...data, comment: "" };

  return (
    <>
      <section className="main-container">
        <main className="d-flex flex-column px-4 pt-2 sm:p-0 sm:w-[100%]">
          <Formik
            initialValues={initValues}
            innerRef={formikRef}
            onSubmit={async (values, { resetForm }) => {
              // console.log('!!!!!!!!!!!!!!!!!!!!!!')
              const payload = {
                caller: values?.caller?.USER_ID || values?.caller?.id,
                state: values?.state.name,
                affected_incidents: values?.affected_incidents.map(
                  (incident) => incident.id
                ),
                impact: values?.impact.name,
                config_item: values?.config_item?.id || "",
                urgency: values?.urgency.name,
                problem_category: values?.problem_category?.id || "",
                priority: values?.priority,
                assigned_grp: values?.assigned_grp?.id,
                service: values?.service,
                assigned_to: values?.assigned_grp?.manager?.id,
                major_problem: values?.major_problem,
                prb_statement: values?.prb_statement,
                description: description,
                analysis_info: analysisInfo,
                resolution_info: {
                  resolved_by: resolutionInfo?.resolved_by,
                  resolved_notes: resolutionInfo?.resolved_notes || "",
                  resolved_at: resolutionInfo?.resolved_at || "",
                },
                notes: notesInfo || "",
                comment: values?.comment || "",
              };
              if (description.length !== 0) {
                // console.log('analysisInfoanalysisInfoanalysisInfo',analysisInfo)
                // if(data?.state==='Root Cause Analysis' && analysisInfo===null){
                //   toast("Enter the Analysis Info",'error');
                // }
                // else{
                const config = {
                  headers: {
                    "Content-Type":
                      "multipart/form-data; boundary=<calculated when request is sent>",
                  },
                };
                const res = await serverAPI.put(
                  `${url}/problems/update/${data?.prb_num}`,
                  payload
                );
                if (
                  !analysisInfo.cause_notes &&
                  !analysisInfo.cause_notes &&
                  data?.state === "Root Cause Analysis"
                ) {
                  toast("Add analysis info", "error");
                }
                else if (!resolutionInfo.resolved_by && !resolutionInfo.resolved_notes && data?.state=='Resolved'){
                  toast("Add Resolution info", "error");
                } else if (res?.status === 202) {
                  toast("Problem Updated successfully");
                  refetch();
                  resetForm();
                } else {
                  toast("Something went wrong", "error");
                }
              } else {
                toast("Description is required", "error");
              }
            }}
          >
            {({ values, errors, setFieldValue }) => (
              <>
                <Form>
                  <PageHeader header={"Update Problem"} className="mb-4" />
                  <div className="flex justify-between sm:flex-col">
                    <Uitabs title="Newwwww" disable={New}>
                      New
                    </Uitabs>
                    <Uitabs title="Newwwww2" disable={Assess}>
                      Assess
                    </Uitabs>
                    <Uitabs title="Newwwww3" disable={Analysis}>
                      Root Cause Analysis
                    </Uitabs>
                    <Uitabs title="Newwwww4" disable={Fix}>
                      Fix in Progress
                    </Uitabs>
                    <Uitabs title="Newwwww5" disable={Resolved}>
                      Resolved
                    </Uitabs>
                    <Uitabs title="Newwwww6" disable={Closed}>
                      Closed
                    </Uitabs>
                    <Uitabs title="Newwwww7" disable={Discard}>
                      Discard
                    </Uitabs>
                  </div>
                  <Grid
                    container
                    columnSpacing={6}
                    className="sm:flex-col sm:grid-cols-1 pr-6 tab:flex-col tab:grid-cols-1 "
                  >
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input label="Problem No." name="prb_num" disabled />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className="mt-4 justify-between sm:max-w-full tab:max-w-full"
                    >
                      <AutoComplete
                        label="Raised By"
                        name="caller"
                        getOptionLabel={(option) =>
                          option?.profile?.full_name || "-"
                        }
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <AutoComplete
                        options={statusOption}
                        getOptionLabel={(opt) => opt?.name || opt || ""}
                        label="Status"
                        name="state"
                        // disabled={
                        //   (data?.caller || data?.raised_by) &&
                        //   (data?.state === "Closed" ||
                        //     (data?.assigned_to &&
                        //     data?.assigned_to.id === data?.caller
                        //       ? true
                        //       : userInfo?.user?.USER_ID !== data?.caller))
                        //     ? false
                        //     : true
                        // }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className="mt-4 sm:max-w-full tab:max-w-full"
                    >
                      <AutoComplete
                        url={"/incidents/all-incidents"}
                        getOptionLabel={(opt) => opt?.inc_num || ""}
                        label="Related Incident"
                        name="affected_incidents"
                        multiple
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <AutoComplete
                        options={importance}
                        getOptionLabel={(opt) => opt?.name || opt || ""}
                        label="Impact"
                        name="impact"
                        // disabled={
                        //   !data?.caller ||
                        //   !data?.raised_by ||
                        //   (data?.raised_by &&
                        //     userInfo?.user.USER_ID === data?.raised_by?.id) ||
                        //   data?.state === "Closed" ||
                        //   data?.state === "Discard"
                        //     ? true
                        //     : false
                        // }
                        onChange={(_, value) => {
                          let pt = CalculatePriority(
                            value?.name || values?.impact,
                            values?.urgency?.name || values?.urgency
                          );
                          setFieldValue("priority", pt);
                          // impact: $("#Impact").val(),
                          // urgency: $("#Urgency").val()
                          setFieldValue("urgency", values?.urgency);
                          setFieldValue("impact", value);
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      className="mt-4 sm:max-w-full tab:max-w-full"
                    >
                      <AutoComplete
                        url={"/portal/items/0"}
                        getOptionLabel={(opt) => opt?.name || ""}
                        label="Configuration Item"
                        name="config_item"
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <AutoComplete
                        options={importance}
                        getOptionLabel={(opt) => opt?.name || opt || ""}
                        label="Urgency"
                        name="urgency"
                        onChange={(_, value) => {
                          let pt = CalculatePriority(
                            values?.impact?.name || values?.impact,
                            value?.name || values?.urgency
                          );
                          setFieldValue("priority", pt?.name);
                          // impact: $("#Impact").val(),
                          // urgency: $("#Urgency").val()
                          setFieldValue("impact", values?.impact);
                          setFieldValue("urgency", value);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className="mt-4 sm:max-w-full tab:max-w-full"
                    >
                      <AutoComplete
                        url={"/portal/allcats"}
                        getOptionLabel={(opt) => opt?.name || ""}
                        label="Category"
                        name="problem_category"
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input label="Priority" name="priority" disabled />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className="mt-4 sm:max-w-full tab:max-w-full"
                    >
                      <AutoComplete
                        url={"/portal/departments/0"}
                        getOptionLabel={(opt) => opt.name}
                        label="Assigned group"
                        name="assigned_grp"
                        required
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input label="Bssiness Service" name="service" required />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className="mt-4 sm:max-w-full tab:max-w-full"
                    >
                      <AutoComplete
                        name="assigned_to"
                        label="Assign To"
                        options={[values?.assigned_grp?.manager]}
                        getOptionLabel={(opt) => opt?.profile?.full_name || ""}
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 ml-10 sm:max-w-full">
                      <Checkbox
                        label="Major Problem"
                        name="major_problem"
                        checked={values.major_problem}
                        // defaultChecked={values?.major_problem}
                        value={values.major_problem}
                        onChange={(_, checked) => {
                          setFieldValue("major_problem", checked);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="mt-4 h-10px sm:max-w-full h-10px tab:max-w-full"
                    >
                      <Input
                        label="Problem Statement"
                        name="prb_statement"
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="mt-4 h-20px sm:max-w-full tab:max-w-full"
                    >
                      <Label text="Detailed Description" required />
                      <CKEditor
                        editor={ClassicEditor}
                        data={description}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "300px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescription(data);
                        }}
                        onError={() => toast("Error in CKEditor", "error")}
                      />
                    </Grid>
                    <Grid item xs={12} className="mt-4 sm:w-[90%]">
                      <Input
                        multiline
                        rows={2}
                        label="Update Log"
                        name="comment"
                        required
                      />
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
          <Grid
            container
            columnSpacing={6}
            className="sm:flex-col sm:grid-cols-1 p-4 tab:flex-col tab:grid-cols-1 "
          >
            <Grid
              item
              xs={12}
              className="mt-4 justify-between sm:max-w-full tab:max-w-full"
            >
              <Tabs
                tabs={[
                  {
                    id: 0,
                    buttonLabel: "Notes",
                    component: (
                      <NotesForm
                        data={data?.notes}
                        setNotesInfo={setNotesInfo}
                      />
                    ),
                    visible: true,
                  },
                  {
                    id: 1,
                    buttonLabel: "Analysis Info",
                    component: (
                      <AnalysisInformation
                        data={data?.analysis_info}
                        state={data?.state}
                        setAnalysisInfo={setAnalysisInfo}
                      />
                    ),
                    visible: data?.state !== "New",
                  },
                  {
                    id: 2,
                    buttonLabel: "Resolved Info",
                    component: (
                      <ResolutionInformation
                        data={data?.resolution_info}
                        state={data?.state}
                        setresolutionInfo={setresolutionInfo}
                      />
                    ),
                    visible:
                      data?.state !== "New" &&
                      data?.state !== "Closed" &&
                      data?.state !== "Discard",
                  },
                ].filter((tab) => tab.visible === true)}
              />
            </Grid>
            <Grid xs={12} item className="sm:self-center tab:self-center">
              <Box className="mt-5 mb-4 d-flex justify-content-end">
                <Button
                  variant="contained"
                  className="capitalize ml-4 px-4 xl:text-sm 2xl:text-semi-base"
                  type="button"
                  onClick={handleSubmit}
                >
                  Update Problem
                </Button>
              </Box>
            </Grid>
          </Grid>
          {data?.update_logs && data?.update_logs?.length !== 0 && (
            <main className="d-flex flex-column px-4 pt-2 pb-8 mt-5 mb-5 sm:w-[100%]">
              <Label
                text="Update Log History"
                colorClass={"dark-blue"}
                className="mt-4 mb-4 text-2xl font-extrabold underline"
              />
              {data?.update_logs?.map((item) => (
                <SecondaryAccordion
                  status={`Updated By: ${item?.updated_by || ""}`}
                  key={item?.id}
                  header={` ${item?.created_on?.split("T")[0] || ""} At ${
                    item?.created_on?.split("T")[1]?.split(".")[0]
                  }`}
                  className="mt-1 sm:flex sm:flex-wrap sm:w-[100%]"
                >
                  <Typography className="text-lg mb-3">
                    <span className="ml-2 font-bold">Update Log : </span>
                    {item?.text || ""}
                  </Typography>
                  {item?.logs?.length !== 0 && (
                    <>
                      <Grid
                        container
                        className="mt-2 ml-0 border-b-2 border-black"
                        columnSpacing={2}
                      >
                        <Grid xs={4} item>
                          Field
                        </Grid>
                        <Grid xs={4} item>
                          Previous Value
                        </Grid>
                        <Grid xs={4} item>
                          Updated Value
                        </Grid>
                      </Grid>
                      <Divider />
                      {item?.logs?.map((item) => (
                        <Grid container className="mt-2 ml-0" columnSpacing={2}>
                          <Grid xs={4} item>
                            {item?.changed_field_name
                              ? item?.changed_field_name
                              : ""}
                          </Grid>
                          <Grid xs={4} item>
                            {item?.old_value ? item?.old_value : ""}
                          </Grid>
                          <Grid xs={4} item>
                            {item?.new_value ? item?.new_value : ""}
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )}
                </SecondaryAccordion>
              ))}
            </main>
          )}
        </main>
      </section>
    </>
  );
};

export default UpdateProblem;
