import { Grid, Box, CircularProgress, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Checkbox, Input, Label } from "../../components/layouts/forms/inputs";
import { DateTimePicker } from "../../components/layouts/forms/inputs/dateTimePicker";
import serverAPI from "../../utils/serverAPI";
// import { Input } from "../../../components/layouts/forms/inputs";

const ScheduleForm = ({ data, setScheduleInfo, plannedStartDateRef, plannedEndDateRef }) => {
  const [loading, setLoading] = useState(true);
  // const [checked, setChecked] = useState(false);
  // const initialState = {
  //   planned_start_date: data?.planned_start_date,
  //   planned_end_date: data?.planned_end_date,
  //   actual_start_date: data?.actual_start_date,
  //   actual_end_date: data?.actual_end_date,
  //   CAB_recommendation: data?.CAB_recommendation,
  // };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, []);
  if (loading) {
    return (
      <Box
        className="d-flex justify-content-center align-items-center w-full"
        style={{ height: "50vh" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // const scheduleValidation = yup.object({
  //   planned_start_date: yup.string("Not in string format"),
  // });

  return (
    <>
      <Formik
        initialValues={{
          // ...initialState,
          ...data,
        }}
        // validationSchema={scheduleValidation}
        enableReinitialize
        onSubmit={async (values) => {
          const payload = {
            planned_start_date: values?.planned_start_date,
            planned_end_date: values?.planned_end_date,
            actual_start_date: values?.actual_start_date,
            actual_end_date: values?.actual_end_date,
          };
          console.log("payload===============>", payload);
        }}
      >
        {({ errors, values, handleChange, setFieldValue }) => (
          <Form>
            <Grid
              container
              spacing={3}
              className="p-4 sm:flex-col justify-content-left sm:grid-cols-1 tab:flex-col tab:grid-cols-1"
            >
              <Grid item xs={6}>
                <DateTimePicker
                  label="Planned Start Date"
                  name="planned_start_date"
                  value={values.planned_start_date}
                  disablePast = {true}
                  ref={plannedStartDateRef}
                  onChange={(newValue) => {
                    if(newValue) {
                      const value = newValue.toISOString();
                      console.log("------------------date",newValue)
                      setScheduleInfo({
                        ...values,
                        planned_start_date: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  label="Actual Start Date"
                  name="actual_start_date"
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  label="Planned End Date"
                  name="planned_end_date"
                  disablePast={true}
                  ref={plannedEndDateRef}
                  onChange={(newValue) => {
                    if(newValue) {
                      const value = newValue.toISOString();
                      console.log("------------------date",value)
                      setScheduleInfo({
                        ...values,
                        planned_end_date: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  label="Actual End Date"
                  name="actual_end_date"
                  disabled
                />
              </Grid>
              {/* <Grid item xs={6}>
                <Checkbox
                  label="CAB required"
                  className="pl-[3%]"
                  onChange={() => {
                    // setFieldValue("CAB_recommendation", "");
                    setChecked(!checked);
                  }}
                /> 
              </Grid> */}
              {/* {checked && ( */}
              <Grid item xs={11}>
                <Input
                  name="CAB_recommendation"
                  label="CAB Recommendation"
                  multiline
                  rows={2}
                  value={values.CAB_recommendation}
                  onChange={(e) => {
                    // handleChange(e);
                    setFieldValue("CAB_recommendation", e.target.value);
                    setScheduleInfo({
                      ...values,
                      CAB_recommendation: e.target.value,
                    });
                  }}
                />
              </Grid>
              {/* )} */}
              <Grid item xs={12}>
                <Box className="mt-5 d-flex justify-content-end"></Box>
              </Grid>
            </Grid>
            {/* <Button
              type="submit"
              onClick={() => {console.log("Errors =========> ", errors); console.log("Values =======>", values)}}
            >
              Submit
            </Button> */}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ScheduleForm;
