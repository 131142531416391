import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  CardActions,
} from "@mui/material";
import { url, mediaUrl } from "../../../urls";
import defaultImg from "../../../public/img/default_product.png";
import defaultserviceImg from "../../../public/img/default_service.png";
import { colors } from "../../../constants/theme";
import { ServiceDetailDialoge } from "./ServiceDetailDialog";

const ServiceCard = ({ data, history }) => {
  return (
    <>
      <Card className="shadow-sm border">
        <CardActionArea sx={{ height: 380 }}>
          <div className="p-2" style={{ height: "240px" }}>
            <CardMedia
              component="img"
              image={
                data.image
                  ? mediaUrl + data.image
                  : data.product_type === "Product"
                  ? defaultImg
                  : defaultserviceImg
              }
              alt={data.name}
              style={{ width: "100%", height: "100%" }}
              className="img-fuild ml-lg-5"
            />
          </div>
          <CardContent style={{ width: "100%", height: 140 }}>
            <Typography
              variant="h5"
              className="font-semibold mb-1 text-capitalize"
              color={colors?.primary?.dark}
            >
              {data?.name}
            </Typography>

            <Typography
              variant="body"
              color={colors?.tertiary?.dark}
              className="mb-1 text-capitalize block"
            >
              {data.category ? data.category.name : "-"}
            </Typography>

            <Typography
              variant="body"
              color={colors?.tertiary?.dark}
              className="mb-1 text-capitalize block"
            >
              {data.sub_category ? data.sub_category.sc_name : "-"}
            </Typography>

            {data.price !== "-" && (
              <Typography
                variant="h6"
                color={colors?.primary?.dark}
                className="mb-1 text-capitalize block"
              >
                Price : {data.price}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
        <CardActions className="d-flex justify-content-end">
          <ServiceDetailDialoge data={data} history={history} />
        </CardActions>
      </Card>
    </>
  );
};

export default ServiceCard;
