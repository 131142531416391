import React, { useEffect } from "react";
import Navbar from "../../components/layouts/Navbar";
import { CategoryDialog } from "../../components/AdminPanelLayout/CategoryDialog";
import { DeleteBox } from "../../components/layouts/commonLayouts/dialogBox/delete";
import { useGetAll } from "../../hooks";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { Link } from "react-router-dom";
import CategoriesCard from "./CategoriesCard";
import { useMediaQuery } from "@mui/material";
import BUlkCsvUpload from "./BulkCsvUpload";

const columns = [
  {
    headerName: "Categories",
    field: "name",
    flex: 1,
    cellClassName: "text-dark",
    renderCell: ({ row }) => (
      <Link
        className="text-decoration-underline text-dark"
        to={`/sub-categories/${row.name}/${row.id}`}
      >
        <p className="m-0 text-truncate">{row?.name}</p>
      </Link>
    ),
  },
  {
    headerName: "Actions",
    field: "actions",
    flex: 1,
    renderCell: ({ row }) => (
      <div className="flex items-center">
        <CategoryDialog isUpdate data={row} />
        <div className="border rounded-xl py-2 mx-3"></div>
        <DeleteBox
          title="Categories"
          url="/portal/categories"
          data={row.id}
          refetchUrl="/portal/categories/0"
        >
          Do you really want to delete this Category?
        </DeleteBox>
      </div>
    ),
  },
];

const Categories = ({ history }) => {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      document.title = "All Categories";
    }
    //eslint-disable-next-line
  }, []);

  const { data: categories,refetch } = useGetAll({
    key: "/portal/categories/0",
    params: {
      page: 0,
      limit: 25,
    },
  });
  const isMobile = useMediaQuery('(max-width:600px)');  
  return (
    <>
      <Navbar heading="CATEGORIES" subheading="" />
      <section className="main-container">
      {isMobile ? ( <CategoryDialog />):''}
      {isMobile ? ( 
        categories?.rows?.map((row, index) => <CategoriesCard key={index} row={row || []} columns={columns}/>)
        ) : (
        <DataGrid
          columns={columns}
          rows={categories}
          url="/portal/categories/0"
          addButton={<div className="flex sm:flex-col tab:flex-col  flex-wrap mt-4" >
            <BUlkCsvUpload className={""} downloadAPI={"/portal/export/category"} uploadAPI={"/portal/upload/category"} onUploadSuccess={refetch} /> 
            <CategoryDialog />
            </div>}
        />)}
      </section>
    </>
  );
};

export default React.memo(Categories);
