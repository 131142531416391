import { useEffect } from "react";
import Navbar from "../../components/layouts/Navbar";
import { PageHeader } from "../../components/layouts/pageHeader";
import serverAPI from "../../utils/serverAPI";

const Task = () => {
  useEffect(() => {
    const fetchTask = async () => {
      const res = await serverAPI.get("/tasks/single/6");
      console.log("Result ==>", res.data.title);
    };
    fetchTask();
  }, []);

  return (
    <div>
      <PageHeader header="Task" />
      <Navbar heading="TASK MANAGEMENT / MY TASKS" />
      <section className="main-container task-list-page">
        <div className="flex flex-col task-list-wrapper">Task</div>
      </section>
    </div>
  );
};

export default Task;
