import { Upload } from "@carbon/icons-react";
import { Button, FormHelperText, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { Label } from "../label";
import { colors } from "../../../../../constants/theme";

export const DropZone = ({
  name,
  label,
  onChange,
  fileType,
  setFile,
  ...otherProps
}) => {
  const { setFieldValue, values } = useFormikContext();
  const [errors, setError] = useState([]);

  return (
    <Dropzone
      minSize={0}
      accept={{ "image/*": [".png", ".gif", ".jpeg", ".jpg"], ...fileType }}
      onDrop={(acceptedFiles, rejectedFiles) => {
        if (!acceptedFiles.length) {
          let err = [];
          rejectedFiles.forEach((item) => {
            err = [...err, ...item.errors.map((error) => error.message)];
          });
          setError(err);
        }
        if (otherProps.multiple) {
          setFieldValue(name, acceptedFiles);
          setFile(acceptedFiles);
        } else {
          setFieldValue(name, acceptedFiles[0]);
          setFile(acceptedFiles);
        }
        console.log(setFile, "Set FIle");
        console.log(acceptedFiles, "Set FIle");

        onChange && onChange(acceptedFiles, rejectedFiles);
        setError([]);
      }}
      {...otherProps}
      className=""
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <>
          {label && (
            <Label
              className="text-sm ml-0 text-[#525252] text-[13px] font-medium"
              text={label}
            />
          )}
          <div
            {...getRootProps()}
            className="drop-zone mt-2"
            style={{
              background: isDragActive ? colors.tertiary.light : "#fff",
            }}
          >
            <input {...getInputProps()} />
            {Array.isArray(values[name]) || values[name] ? (
              <Typography color="primary">
                {Array.isArray(values[name]) ? values[name].length : 1} file
                selected
              </Typography>
            ) : isDragActive ? (
              <Typography color="primary">Drop here...</Typography>
            ) : (
              <Button
                variant="outlined"
                startIcon={<Upload className="text-[#0F62FE]" />}
                className="border-[#0F62FE4D] px-[13px] py-2 text-[#0F62FE] capitalize bg-none"
              >
                Click to upload your file
              </Button>
            )}
          </div>
          {errors[0] && (
            <FormHelperText className="text-red-600">
              {errors[0]}
            </FormHelperText>
          )}
        </>
      )}
    </Dropzone>
  );
};
