  import { Link } from "react-router-dom";
import { StatusCard } from "../../components/layouts/commonLayouts/cards/StatusCard";
import removeTags from "../../utils/removeTags";
import { AllProblemDialogBox } from "./AllProblemDialogBox";

export const columns = [
  {
    headerName: "Number",
    field: "prb_num",
    minWidth: 120,
    flex: 0.5,
    cellClassName: "text-dark",
    renderCell: ({ row }) => {
      return (
        <Link to={`/update-problem/${row?.prb_num}`}>
          <div className=" text-black underline">{row?.prb_num}</div>
        </Link>
      );
    },
  },
  {
    headerName: "Opened",
    field: "created_on",
    minWidth: 160,
  },
  {
    headerName: "Short Description",
    field: "prb_staement",
    minWidth: 300,
    flex: 1.5,
    renderCell: ({ row }) => {
      return removeTags(row?.prb_statement)
    },
  },
  {
    headerName: "Category",
    field: "problem_category",
    flex: 1,
    renderCell: ({ row }) => {
      if (row?.problem_category?.name) {
        return <div>{row?.problem_category?.name}</div>;
      } else {
        return <div>-</div>;
      }
    },
  },
  {
    headerName: "Raised By",
    field: "raised_by",
    flex: 1,
    renderCell: ({ row }) => {
      if (row?.caller?.profile?.full_name) {
        return <div>{row?.caller?.profile?.full_name}</div>;
      } else {
        return <div>-</div>;
      }
    },
  },
  {
    headerName: "Priority",
    field: "priority",
    minWidth: 100,
    flex: 0.5,
  },
  // {
  //   headerName: "Status",
  //   field: "state",
  //   // minWidth: 150,
  //   flex: 1,
  //   // headerAlign: "center",
  //   align: "center",
  //   renderCell: ({ row }) => (
  //     <StatusCard
  //       text={row?.state}
  //       variant={row?.state?.toLowerCase()?.replace(/ /g, "_")}
  //     />
  //   ),
  // },

  {
    headerName: "Actions",
    field: "actions",
    flex: 1,
    // minWidth: 135,
    renderCell: ({ row }) => {
      return (
       
        <AllProblemDialogBox data={row} />
        
      );
    },
  },
];
