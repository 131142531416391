import { Grid, Box } from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Input } from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import Navbar from "../../components/layouts/Navbar";
import { toast } from "../../utils/toaster";
import { UserAvatar } from "@carbon/icons-react";
import { openUserInfoBox, closeUserInfoBox } from "../../utils/PortalUtils";
import { useHistory } from "react-router-dom";
import { useCreateOrUpdate, useGetAll } from "../../hooks";
import serverAPI from "../../utils/serverAPI";
import { url } from "../../urls";
import removeTags from "../../utils/removeTags";

const userInfo = {
  user: localStorage.getItem("data")
    ? JSON.parse(localStorage.getItem("data"))
    : {},
};

const incidentValidations = yup.object({});

const ApprovalScreen = ({ match, location }) => {
  const history = useHistory();
  const { data } = useGetAll({
    key: `/requests/${match.params.req_num}/0`,
  });
  const [tooltipInfo, setTooltipInfo] = useState({
    username: "",
    email: "",
    phoneno: "",
    userRole: "",
    department: "",
  });
  const initialState = {
    raised_by: JSON.parse(localStorage.getItem("data")),
    requested_by: JSON.parse(localStorage.getItem("data")),
    product: "",
    quantity: 1,
    due_date: "",
    approval: "Requested",
    state: "Pending Approval",
    short_desc: "",
    justification: "",
    comments1: "",
    comments2: "",
  };

  // console.log(data, "request data from backend");
  //   console.log(userInfo?.userInfo?.user?, "login userInfo?.user? data");

  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/requests/${data?.req_num}/0`,
    method: "put",
  });

  const onReject = (values) => {
    mutate(
      {
        requested_by: values?.requested_by?.id,
        product: values?.product?.id,
        quantity: values?.quantity,
        approval: "Rejected",
        state: "Cancelled",
        short_desc: values?.short_desc,
        justification: values?.justification,
        comments1: values?.comments1,
        comments2: values?.comments2,
        comment: values?.comments2 ? values?.comments2 : values?.comments1,
      },
      {
        onSuccess: () => {
          toast("Request Rejected Succefully");
          if (data?.approval === "Requested") {
            history.push("/first-approval-pending-requests");
          } else if (data?.approval === "1st level approval") {
            history.push("/second-approval-pending-requests");
          }
        },
      }
    );
  };
  const fetchData = async () => {
    if (data.requested_by) {
      const res1 = await serverAPI.get(
        `${url}/accounts/basicinfo/${data.requested_by.id}`
      );
      setTooltipInfo({
        username: res1.data.full_name,
        email: res1.data.email,
        phoneno: res1.data.phone,
        userRole: res1.data.user_role,
      });
    }
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      // console.log("1");
      if (data.approval === "Requested") {
        // console.log("11");
        if (
          JSON.parse(localStorage.getItem("data"))?.USER_ID !== data.manager_id
        ) {
          // console.log("12");
          history.push(`/update-request/${data.req_num}`);
        }
      } else if (!JSON.parse(localStorage.getItem("data")).is_skip_manager) {
        // console.log("2");
        if (userInfo?.user?.USER_ID === data.requested_by.id) {
          // console.log("21");
          history.push(`/update-request/${data.req_num}`);
          // console.log("211");
        } else {
          history.push(`/view-status`);
        }
      }
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    if (data?.approval === "Approved") {
      history.push("/page-not-found");
      toast("Requeste already have been appoved");
    } else if (data?.approval === "Requested") {
      document.title = "First Approval Pending Requests";
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'page_view',
        page_data: {
          country: 'India',
          language: 'en',
          page_url: window.location.href,
          page_category: 'Request Management',
          page_location: 'FirstApproval',
          page_referrer: document.referrer,
          page_title: document.title,
          region: 'Pune',
          site_section: 'Support',
        },
        user_data: {
          user_id: JSON.parse(localStorage.getItem("data")).user_id,
          user_login_state: 'User',
          user_type: JSON.parse(localStorage.getItem("data")).user_role,
        }
      });
      console.log(window.dataLayer)
    } else if (data?.approval === "1st level approval") {
      document.title = "Second Approval Pending Requests";
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'page_view',
        page_data: {
          country: 'India',
          language: 'en',
          page_url: window.location.href,
          page_category: 'Request Management',
          page_location: 'SecondApproval',
          page_referrer: document.referrer,
          page_title: document.title,
          region: 'Pune',
          site_section: 'Support',
        },
        user_data: {
          user_id: JSON.parse(localStorage.getItem("data")).user_id,
          user_login_state: 'User',
          user_type: JSON.parse(localStorage.getItem("data")).user_role,
        }
      });
      console.log(window.dataLayer)
    }
    // eslint-disable-next-line
  }, [data?.approval, history]);
  return (
    <>
      <Navbar heading="CREATE NEW INCIDENT" subheading="NEW INCIDENT" />
      <section className="main-container ">
        <main className="d-flex flex-column px-4 pt-2">
          <Formik
            initialValues={{
              ...initialState,
              ...data,
              requested_by: data?.requested_by ? data?.requested_by : {},
              raised_by: data?.raised_by ? data?.raised_by : {},
              price: data?.product?.price ? data?.product?.price : "",
            }}
            validationSchema={incidentValidations}
            enableReinitialize={true}
            onSubmit={(values) => {
              mutate(
                {
                  requested_by: values?.requested_by?.USER_ID
                    ? values?.requested_by?.USER_ID
                    : values?.requested_by?.id,
                  product: values?.product?.id,
                  quantity: values?.quantity,
                  approval:
                    data?.approval === "Requested"
                      ? "1st level approval"
                      : data?.approval === "1st level approval"
                      ? "Approved"
                      : data?.approval,
                  state:
                    data?.approval === "1st level approval"
                      ? "In Progress"
                      : values?.state || values?.state?.name,
                  short_desc: values?.short_desc,
                  justification: values?.justification,
                  comments1: values?.comments1,
                  comments2: values?.comments2,
                  comment: values?.comments2
                    ? values?.comments2
                    : values?.comments1,
                },
                {
                  onSuccess: (data) => {
                    console.log(data, "data after submit");
                    if (data?.data?.approval === "1st level approval") {
                      toast("Approved By Department Head.");
                      history.push("/first-approval-pending-requests");
                    } else if (data?.data?.approval === "Approved") {
                      toast("Approved By Skip Level Manager.");
                      history.push("/second-approval-pending-requests");
                    }
                  },
                }
              );
            }}
          >
            {({ values, errors, setFieldValue }) => (
              <>
                <Form>
                  <Grid container columnSpacing={6}>
                    <Grid item xs={6} className="mt-4">
                      <Input
                        label="Raised By"
                        name="raised_by"
                        value={
                          values?.raised_by
                            ? values?.raised_by?.profile?.full_name
                            : ""
                        }
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <Grid container columnSpacing={0}>
                        <Grid item xs={12}>
                          <Input
                            label="Requested For"
                            name="requested_by"
                            value={
                              values?.requested_by.full_name ||
                              values?.requested_by?.profile?.full_name ||
                              ""
                            }
                            disabled
                            required
                          />
                        </Grid>
                        {/* <Grid item xs={1} className="mt-10">
                          <div
                            className="ms-2 d-inline border-2 rounded"
                            onMouseEnter={openUserInfoBox}
                            onMouseLeave={closeUserInfoBox}
                            style={{ position: "relative" }}
                          >
                            <UserAvatar
                              size={30}
                              style={{
                                color: "gray",
                                background: "white",
                              }}
                            />
                            <div
                              className="px-4 bg-white shadow userInfo?.user?-info-box rounded my-4 pb-3"
                              style={{ display: "none" }}
                            >
                              <Grid container>
                                <Grid xs={12} item>
                                  <Input
                                    label="Name"
                                    value={tooltipInfo?.username || ""}
                                  />
                                </Grid>
                                <Grid xs={12} item>
                                  <Input
                                    label="Email"
                                    value={tooltipInfo?.email || ""}
                                  />
                                </Grid>
                                <Grid xs={12} item>
                                  <Input
                                    label="Phone no"
                                    value={tooltipInfo?.phoneno || ""}
                                  />
                                </Grid>
                                <Grid xs={12} item>
                                  <Input
                                    label="Role"
                                    value={tooltipInfo?.userRole || ""}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <Input
                        label="Status"
                        name="state"
                        value={
                          values?.state
                            ? values?.state?.name || values?.state
                            : "-"
                        }
                        disabled
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4">
                      <Input
                        label={`${data?.product?.product_type}`}
                        name="product"
                        value={data?.product?.name ? data?.product?.name : "-"}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <Input
                        label="Quantity"
                        name="quantity"
                        type="number"
                        value={values?.quantity ? values?.quantity : -""}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <Input
                        label="Price"
                        name="price"
                        disabled
                        value={
                          data?.product?.price !== "-"
                            ? data?.product.price * data?.quantity
                            : "-"
                        }
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4">
                      <Input
                        label="Approvals"
                        name="approval"
                        disabled
                        onChange={(e) => {
                          if (e.target.value === "Approved") {
                            setFieldValue("approval", e?.target?.value);
                            setFieldValue("state", "In Progress");
                          } else if (
                            e.target.value === "Rejected" ||
                            e.target.value === "No Longer Required" ||
                            e.target.value === "Duplicate"
                          ) {
                            setFieldValue("approval", e?.target?.value);
                            setFieldValue("state", "Cancelled");
                          } else {
                            setFieldValue("approval", e?.target?.value);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} className="mt-4">
                      <Input
                        multiline
                        rows={2}
                        label="Short Description"
                        name="short_desc"
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} className="mt-4">
                      <Input
                        required
                        multiline
                        rows={2}
                        def
                        name="justification"
                        label="Justification"
                        disabled
                        value={removeTags(values.justification)}
                      />
                    </Grid>

                    <Grid item xs={12} className="mt-4">
                      <Input
                        required
                        multiline
                        rows={3}
                        label={
                          data?.approval === "Requested"
                            ? "Comments"
                            : data?.approval === "1st level approval"
                            ? "Comments For Skip Manager"
                            : "Comments From Department Head"
                        }
                        name="comments1"
                        placeholder="Add Approval or Rejection Comments - 
                          Approval reasons will be seen by Skip Level Manager/
                          Rejection reasons will be seen by Requestor"
                        disabled={data?.approval === "Requested" ? false : true}
                        helperText={
                          data?.approval === "Rejected"
                            ? "Rejected By Department Head."
                            : data?.approval === "1st level approval"
                            ? "Approved By Department Head."
                            : ""
                        }
                      />
                    </Grid>

                    {data?.approval === "1st level approval" && (
                      <Grid item xs={12} className="mt-4">
                        <Input
                          required
                          multiline
                          rows={3}
                          label={
                            data?.approval === "1st level approval"
                              ? "Comments"
                              : "Comments for Assets Team"
                          }
                          name="comments2"
                          placeholder="Add Approval or Rejection Comments - 
                    Approval reasons will be seen by Asset Managment Team/
                    Rejection reasons will be seen by Requestor"
                          helperText={
                            data?.approval === "Rejected"
                              ? "Rejected By Skip Level Manager."
                              : data?.approval === "Approved"
                              ? "Approved By Skip Level Manager."
                              : ""
                          }
                        />
                      </Grid>
                    )}

                    <Grid xs={12} item>
                      <Box className="mt-5 mb-4 d-flex justify-content-end">
                        <Button
                          isLoading={isLoading}
                          variant="contained"
                          color="secondary"
                          className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                          onClick={() => onReject(values)}
                        >
                          Reject
                        </Button>
                        <Button
                          isLoading={isLoading}
                          variant="contained"
                          className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                          type="submit"
                          onClick={() => {
                            console.log({ errors }, { values });
                          }}
                        >
                          Approve
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        </main>
      </section>
    </>
  );
};

export default ApprovalScreen;
