import React, { useContext, useReducer } from "react";

import ProblemContext from "./problemContext";
import problemReducer from "./problemReducer";
import {
  GET_AFFECTED_INCS,
  GET_CONFIG_ITEMS,
  GET_PROBLEMS,
  GET_OPEN_PROBLEMS,
  GET_OPEN_UNASSIGNED_PROBLEMS,
  GET_RESOLVED_PROBLEMS,
  GET_PROBLEMS_RAISED_BY_ME,
  GET_PROBLEMS_ASSIGNEDTO_ME,
  GET_PRB_OVERVIEW_DATA,
  SET_LOADING,
  GET_TASKS,
  SET_TASK,
  GET_LIST_OF_AFFECTED_INCS,
  SET_PREVIEW,
} from "../types";
import { url } from "../../urls";
import PortalContext from "../portalContext/portalContext";
import serverAPI from "../../utils/serverAPI";

const ProblemState = (props) => {
  const initialState = {
    prbTableData: [],
    openPrbData: [],
    openUnAssignedPrbData: [],
    resolvedPrbData: [],
    prbRaisedByMe: [],
    prbAssignedToMe: [],
    configItem: [],
    affected_incs: [],
    error: null,
    pichartData: {
      discard: 0,
      closed: 0,
      fix_in_progress: 0,
      new: 0,
      root_cause_analysis: 0,
      resolved: 0,
      assess: 0,
    },
    linechartData: {},
    tableData: {},
    barData: {},
    tasks: [],
    loading: false,
    task: {},
    listOfAffectedInc: [],
    previewPrb: {},
  };

  const [state, dispatch] = useReducer(problemReducer, initialState);

  const portalContext = useContext(PortalContext);
  const { setAlert, clearError } = portalContext;

  // get all data of Prb overview in one req
  const getPrbOverviewData = async () => {
    dispatch({ type: SET_LOADING });
    const pi = await serverAPI.get(`${url}/problems/piechart`);
    const line = await serverAPI.get(`${url}/problems/week`);
    const bar = await serverAPI.get(`${url}/problems/permonth`);
    const table = await serverAPI.get(`${url}/problems/table`);
    const piData = pi.data;
    const tableData = table.data;

    // set data of line
    let data1 = [];
    let data2 = [];
    let lable1 = "";
    let lable2 = "";
    data1.push(Object.values(line.data)[0]);
    data2.push(Object.values(line.data)[1]);
    lable1 = Object.keys(line.data)[0];
    lable2 = Object.keys(line.data)[1];
    let lineData = {
      data1: data1[0],
      data2: data2[0],
      lable1: lable1.toUpperCase(),
      lable2: lable2.toUpperCase(),
    };

    // set bar chart
    let lables = [];
    let data11 = [];
    let data21 = [];
    let last_month = "";
    let this_month = "";
    for (const [key, value] of Object.entries(bar.data)) {
      lables.push(key);
      data11.push(Object.values(value)[0]);
      data21.push(Object.values(value)[1]);
      last_month = Object.keys(value)[0].toUpperCase();
      this_month = Object.keys(value)[1].toUpperCase();
    }
    let barData = {
      lables: lables,
      data1: data11,
      data2: data21,
      last_month: last_month,
      this_month: this_month,
    };
    dispatch({
      type: GET_PRB_OVERVIEW_DATA,
      payload: { piData, tableData, lineData, barData },
    });
    dispatch({ type: SET_LOADING });
  };

  // get all problems
  const getProblems = async (pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/problems/${pag_no}`);
    dispatch({ type: GET_PROBLEMS, payload: res.data });
    dispatch({ type: SET_LOADING });
  };

  // get list of affected incs for single

  const getListOfAffwctedInc = async (id) => {
    const res = await serverAPI.get(`${url}/problems/affected/${id}`);
    dispatch({ type: GET_LIST_OF_AFFECTED_INCS, payload: res.data });
  };

  // get Open problems
  const getOpenProblems = async (pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/problems/open/${pag_no}`);
    dispatch({ type: GET_OPEN_PROBLEMS, payload: res.data });
    dispatch({ type: SET_LOADING });
  };

  // get Open un assigned problems
  const getOpenUnassignedProblems = async (pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/problems/unassigned/${pag_no}`);
    dispatch({ type: GET_OPEN_UNASSIGNED_PROBLEMS, payload: res.data });
    dispatch({ type: SET_LOADING });
  };

  // get reesolved problems
  const getResolvedProblems = async (pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/problems/resolved/${pag_no}`);
    dispatch({ type: GET_RESOLVED_PROBLEMS, payload: res.data });
    dispatch({ type: SET_LOADING });
  };

  // get problems raised by me
  const getProblemsRaisedByMe = async (id, pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(
      `${url}/problems/myproblems/${id}/${pag_no}`
    );
    dispatch({ type: GET_PROBLEMS_RAISED_BY_ME, payload: res.data });
    dispatch({ type: SET_LOADING });
  };

  // get problems assigned to me

  const getAssignedToMeProblems = async (id, pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(
      `${url}/problems/assignedtome/${id}/${pag_no}`
    );
    dispatch({ type: GET_PROBLEMS_ASSIGNEDTO_ME, payload: res.data });
    dispatch({ type: SET_LOADING });
  };

  //   get config items
  const getConfigItem = async () => {
    const res = await serverAPI.get(`${url}/portal/items/0`);
    dispatch({
      type: GET_CONFIG_ITEMS,
      paylod: res.data,
    });
  };

  //   get affected incs
  const getAffectedIncs = async () => {
    const res = await serverAPI.get(`${url}/problems/allincidents`);
    dispatch({
      type: GET_AFFECTED_INCS,
      paylod: res.data,
    });
  };

  // create new problem
  const creatProblem = async (data, history) => {
    dispatch({ type: SET_LOADING });
    const config = {
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
      },
    };
    const res = await serverAPI.post(`${url}/problems/0`, data, config);
    if (res.status === 201) {
      setAlert({
        msg: "Problem Created successfully.",
        type: "success",
        icon: "check-circle",
      });
      setTimeout(() => {
        clearError();
      }, 5000);
      history.push(`/update-problem/${res.data.prb_num}`);
    } else if (res.status === 500) {
      setAlert({
        msg: "Somthing Went Wrong Please Try Again!",
        type: "danger",
        icon: "exclamation-triangle",
      });
      setTimeout(() => clearError(), 5000);
    } else {
      setAlert({
        msg: Object.values(res.data)[0][0],
        type: "danger",
        icon: "exclamation-triangle",
      });
      setTimeout(() => clearError(), 5000);
    }
    dispatch({ type: SET_LOADING });
  };

  const updateprb = async (
    prbUpdateData,
    prb_num,
    updateLogData,
    analysis_info,
    resolved_info,
    cloData,
    getUpdateLogs
  ) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.put(
      `${url}/problems/update/${prb_num}`,
      prbUpdateData
    );
    if (res.status === 202) {
      setAlert({
        msg: "Problem Updated successfully.",
        type: "success",
        icon: "check-circle",
      });
      setTimeout(() => {
        clearError();
      }, 5000);
      await serverAPI.post(`${url}/portal/log/0`, updateLogData);
      await serverAPI.put(`${url}/problems/analysis/${prb_num}`, analysis_info);
      await serverAPI.put(
        `${url}/problems/resolution/${prb_num}`,
        resolved_info
      );
      if (cloData) {
        await serverAPI.put(`${url}/portal/closure/${prb_num}`, cloData);
      }
    } else {
      setAlert({
        msg: Object.values(res.data)[0][0],
        type: "danger",
        icon: "exclamation-triangle",
      });
      setTimeout(() => clearError(), 5000);
    }
    getUpdateLogs(prb_num);
    dispatch({ type: SET_LOADING });
  };

  // get all tasks
  const getTasks = async (id) => {
    const res = await serverAPI.get(`${url}/portal/tasks/${id}`);
    dispatch({ type: GET_TASKS, payload: res.data });
  };

  // set task for update
  const setTask = (item) => {
    dispatch({ type: SET_TASK, payload: item });
  };

  const setPrivew = (item) => {
    dispatch({ type: SET_PREVIEW, payload: item });
  };
  return (
    <ProblemContext.Provider
      value={{
        prbTableData: state.prbTableData,
        openPrbData: state.openPrbData,
        openUnAssignedPrbData: state.openUnAssignedPrbData,
        resolvedPrbData: state.resolvedPrbData,
        prbRaisedByMe: state.prbRaisedByMe,
        prbAssignedToMe: state.prbAssignedToMe,
        configItem: state.configItem,
        affected_incs: state.affected_incs,
        error: state.error,
        pichartData: state.pichartData,
        linechartData: state.linechartData,
        tableData: state.tableData,
        barData: state.barData,
        loading: state.loading,
        tasks: state.tasks,
        task: state.task,
        listOfAffectedInc: state.listOfAffectedInc,
        previewPrb: state.previewPrb,
        setPrivew,
        getProblems,
        getOpenProblems,
        getOpenUnassignedProblems,
        getResolvedProblems,
        getProblemsRaisedByMe,
        getAssignedToMeProblems,
        getConfigItem,
        getAffectedIncs,
        creatProblem,
        getPrbOverviewData,
        getTasks,
        setTask,
        getListOfAffwctedInc,
        updateprb,
      }}
    >
      {props.children}
    </ProblemContext.Provider>
  );
};

export default ProblemState;
