import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import {
  AutoComplete,
  Input,
  Label,
} from "../../components/layouts/forms/inputs";
import Navbar from "../../components/layouts/Navbar";
import { OtherDialog } from "../../components/requests/create-request/OtherDialoge";
import ServiceCard from "../../components/requests/create-request/ServiceCard";
import { useGetAll } from "../../hooks";
import { getPrdBycategory, onIpChange } from "../../utils/RequestUtils";
import { Formik } from "formik";
import BUlkCsvUpload from "../adminPanel/BulkCsvUpload";

const initialValues = {
  category: {},
  search: "",
};
const Service = ({ history }) => {
  const [data, setData] = useState([]);
  const { data: service_data } = useGetAll({
    key: "/requests/product/Service/0",
    select: (data) => {
      return data?.data?.rows;
    },
  });
  useEffect(() => {
    setData(service_data);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page_data: {
        country: 'India',
        language: 'en',
        page_url: window.location.href,
        page_category: 'Incident Management',
        page_location: 'ServiceRequest',
        page_referrer: document.referrer,
        page_title: document.title,
        region: 'Pune',
        site_section: 'Support',
      },
      user_data: {
        user_id: JSON.parse(localStorage.getItem("data")).user_id,
        user_login_state: 'User',
        user_type: JSON.parse(localStorage.getItem("data")).user_role,
      }
    });
    console.log(window.dataLayer)
  }, [service_data]);

  //   console.log(data, "gggggggggggggg");
  return (
    <>
      <Navbar heading="SERVICE" subheading="SELECT SERVICE TO CREATE REQUEST" />
      <section className="main-container">
        <Formik initialValues={initialValues}>
          {({ setFieldValue }) => (
            <Grid container columnSpacing={1} className="sm:flex-col">
              <Grid xs={5} item className="sm:max-w-full">
                <AutoComplete
                  label="Category"
                  name="category"
                  url="/requests/v2/category"
                  getOptionLabel={(opt) => opt?.name || "-------"}
                  onChange={(_, value) => {
                    if (value === null || value?.name === "") {
                      setData(service_data);
                      setFieldValue("category", value);
                    } else {
                      setData(getPrdBycategory(value?.name, service_data));
                      setFieldValue("category", value);
                    }
                  }}
                />
              </Grid>
              <Grid xs={5} item className="sm:max-w-full">
                <Input
                  label="Search"
                  name="search"
                  placeholder="Search...."
                  onChange={(e) => {
                    setFieldValue("search", e.target.value);
                    setData(onIpChange(e.target.value, service_data));
                  }}
                />
              </Grid>
              <Grid xs={1} item>
                <OtherDialog />
              </Grid>
            </Grid>
          )}
        </Formik>

        <div className="flex flex-row justify-between">
          <Label
            text="Select Service"
            colorClass={"blue"}
            // colorClass={colors?.primary?.dark}
            className="mt-4 mb-4 text-3xl font-extrabold underline"
          />
        </div>
        <Grid container spacing={4} className="sm:flex-col">
          {data?.map(
            (item) =>
              !item.remaining && (
                <Grid xs={3} item key={item?.id} className="sm:max-w-full">
                  <ServiceCard key={item?.id} data={item} history={history} />
                </Grid>
              )
          )}
        </Grid>
      </section>
    </>
  );
};

export default Service;
