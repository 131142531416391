import React, { useEffect } from 'react';
import Navbar from '../../components/layouts/Navbar';
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from '../../hooks';
import {columns} from './RequestsColumns'
import RequestCard2 from './RequestCard2';
import { useMediaQuery } from '@mui/material';
import { PageHeader } from '../../components/layouts/pageHeader';


const ReqRaisedByMe = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  
  useEffect(() => {
    document.title = 'All Requests Raised By Me';
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page_data: {
        country: 'India',
        language: 'en',
        page_url: window.location.href,
        page_category: 'Request Management',
        page_location: 'RaqRaisedByMe',
        page_referrer: document.referrer,
        page_title: document.title,
        region: 'Pune',
        site_section: 'Support',
      },
      user_data: {
        user_id: JSON.parse(localStorage.getItem("data")).user_id,
        user_login_state: 'User',
        user_type: JSON.parse(localStorage.getItem("data")).user_role,
      }
    });
    console.log(window.dataLayer)
    // eslint-disable-next-line
  }, []);

  const { data: MyRequests } = useGetAll({
    key: "/requests/myrequests/v2",
    params: {
      page: 0,
      limit: 25,
    },
  });

  return (
    <>
      <Navbar heading="My  Requests" subheading="" />
      <PageHeader header={"MY REQUESTS"} className="mb-4" />
      <section className="main-container">
      {isMobile ? (
        MyRequests?.rows?.map((row, index) => <RequestCard2 key={index} row={row}/>)
       ) :(
        <DataGrid
          columns={columns}
          url="/requests/myrequests/v2"
          rows={MyRequests}
          getRowId={(row) => row?.req_num + row?.id}
        />)}
      </section>
    </>
  );
};

export default React.memo(ReqRaisedByMe);
