import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Dialog } from '../../../components/layouts/commonLayouts/dialogBox/dialog';
import { Download, Edit } from '@carbon/icons-react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { StatusCard } from '../../../components/layouts/commonLayouts/cards/StatusCard';
// import { StatusCard } from '../../../../components/layouts/commonLayouts/cards/StatusCard';



export default function MyCard({ row }) {


    console.log('rowrowrowrowrowrowrowrowState',row.state)
  return (
    <>

      <Box
        className='mb-3'
        sx={{ minWidth: 275, }} >

        <Card
          variant="outlined"
          className="mt-[30px] shadow-lg transition duration-300 transform hover:-translate-y-1 hover:shadow-2xl"
          sx={{ borderRadius: '16px', overflow: 'hidden', backgroundColor: '#f9f9f9' }}>
          <CardContent>
            <Typography variant="h6" sx={{ fontWeight:'bold' }} className='mb-2' >
              {row.prb_num}
            </Typography>
            <Typography  variant='p' component="div">
              {row?.raised_by?.profile?.full_name}
            </Typography>
            <br />
              <Typography variant="p" dangerouslySetInnerHTML={{ __html: `short Description: ${row?.prb_statement}` }} />
            <br />
            <br />
            <p>
              Category {row?.category?.name ? row?.category?.name : "-"}
            </p>

            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Priority:{row?.priority}
            </Typography>
            <div className='flex'>
            <Typography sx={{mt:1}} color="text.secondary" className='mr-3' >
              STATUS:  
            </Typography>
              <StatusCard
                text={row?.state}
                variant='new'
              />
            </div>
          </CardContent>
          <CardActions>
            <Dialog
              title="Problem PREVIEW"
              button={
                <Link to={`update-problem/${row.prb_num}`}>
                  <Button
                    startIcon={<Edit />}
                    className="capitalize xl:text-sm 2xl:text-semi-base"
                    variant="text"

                  >
                    View/Edit
                  </Button>
                </Link>
              }
            />
          </CardActions></Card>
      </Box>
    </>
  );
}