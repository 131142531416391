import { Grid, Box } from "@mui/material";
import React from "react";
import { Input } from "../layouts/forms/inputs";
import { Dialog } from "../layouts/commonLayouts/dialogBox/dialog";
import { Button } from "../layouts/commonLayouts/buttons";
import { Form, Formik } from "formik";
import { Edit } from "@carbon/icons-react";
import { Link } from "react-router-dom";
import removeTags from "../../utils/removeTags";
import { datahandleClick } from "../../components/DataLayer/ButtonClick";

export const RequestPreview = ({ data, link }) => {
  //   console.log(data, "from dialoge");
  // console.log(data, "Inside preview");
  return (
    <Dialog
      title="REQUEST PREVIEW"
      button={
        <Button
        onClick={() => {datahandleClick('Request-Management', 'Request-Preview','View/Edit', '/first-approval-pending-requests', )}}
          startIcon={<Edit />}
          className="capitalize xl:text-sm 2xl:text-semi-base"
          variant="text"
        >
          View/Edit
        </Button>
      }
    >
      {({ onClose }) => (
        <Formik initialValues={{ ...data, justification: removeTags(data.justification) }}>
          {({ values }) => (
            <Form>
              <Grid container columnSpacing={4} className=" sm:flex-col mt-2">
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="req_num"
                    label="Request Number"
                    required
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="state"
                    label="State"
                    // defultValue={values?.state ? values?.state : "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="raised_by"
                    label="Raised By"
                    value={values?.raised_by?.profile?.full_name || "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="approval"
                    label="Approval"
                    value={values?.approval ? values?.approval : "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="product"
                    label="Product"
                    value={values?.product ? values?.product?.name : "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="quantity"
                    label="Quantity"
                    value={values?.product ? values?.product?.name : "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="price"
                    label="Price"
                    value={values?.product ? values?.product?.price : "-"}
                  />
                </Grid>
                <Grid xs={6} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="completed_on"
                    label="Resolved On"
                    value={values?.completed_on || "-"}
                  />
                </Grid>
                <Grid xs={12} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled 
                    className="mt-2"
                    name="short_desc"
                    label="Short Description"
                    multiline
                    maxRows={3}
                    defultValue={values?.short_desc ? values?.short_desc : "-"}
                  />
                </Grid>
                <Grid xs={12} item className="mt-4 justify-between sm:max-w-full">
                  <Input
                    disabled
                    className="mt-2"
                    name="justification"
                    label="Justification"
                    multiline
                    maxRows={3}
                  />
                </Grid>

                <Grid xs={12} item className="mt-4 justify-between sm:max-w-full">
                  <Box className="mt-5 d-flex justify-content-end">
                    <Link to={link ? link : `/update-request/${data?.req_num}`}>
                      <Button
                        className="px-4 ml-4 capitalize  xl:text-sm 2xl:text-semi-base"
                        variant="contained"
                        /*onClick={onClose}*/
                        onClick={() => {datahandleClick('Request-Management', 'Request-Preview','Update', '/first-approval-pending-requests', )}}
                      >
                        Update
                      </Button>
                    </Link>
                    <Button
                      color="secondary"
                      className="px-4 ml-4 capitalize  xl:text-sm 2xl:text-semi-base"
                      variant="contained"
                      onClick={onClose}
                    >
                      Close
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
