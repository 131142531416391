import { Grid, Box } from "@mui/material";
import React from "react";
import {
  AutoComplete,
  Input,
} from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { Edit } from "@carbon/icons-react";
import { Dialog } from "../../components/layouts/commonLayouts/dialogBox";
import removeTags from "../../utils/removeTags";

const UpdateChangeDialog = ({ data, onUpdateChange }) => {
  console.log("Row Data", data);

  return (
    <Dialog
      title="Change Preview"
      button={
        <Button
          startIcon={<Edit />}
          className="capitalize xl:text-sm 2xl:text-semi-base"
          variant="text"
        >
          View/Edit
        </Button>
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{
            ...data,
            // short_desc:removeTags(short_desc),
            //   description:removeTags(description)
          }}
          onSubmit={async (values, { resetForm }) => {}}
        >
          {({ values, errors, handleSubmit }) => (
            <>
              <Form>
                <Grid container columnSpacing={6}>
                  <Grid item xs={6} className="mt-4">
                    <Input
                      label="Requested By"
                      name="requested_by"
                      value={values?.requested_by?.profile?.full_name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Category"
                      name="category"
                      value={values?.category}
                      getOptionLabel={(opt) => opt?.name || ""}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <Input label="Service" name="service" disabled />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete label="State" name="state" disabled />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Impact"
                      name="impact"
                      value={values?.impact}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete label="Risk" name="risk" disabled />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete label="Priority" name="priority" disabled />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Configuration Item"
                      name="config_item"
                      value={values?.config_item}
                      // url={"/portal/items/0"}
                      // options={priorityOptions}
                      getOptionLabel={(opt) => opt?.name || ""}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Assigned Group"
                      name="assigned_grp"
                      value={values?.assigned_grp}
                      // url={"/portal/departments/0"}
                      getOptionLabel={(opt) => opt.name || ""}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Assigned To"
                      name="assigned_to"
                      value={values?.assigned_to}
                      // options={[values?.assigned_grp?.manager?.profile]}
                      getOptionLabel={(opt) => opt?.profile?.full_name || ""}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} className="mt-4 sm:w-[100%] tab:w-full">
                    <Input
                      disabled
                      name="short_desc"
                      label="Short Discription"
                      value={
                        values?.short_desc ? removeTags(values?.short_desc) : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} className="mt-4 sm:w-[100%] tab:w-full">
                    <Input
                      disabled
                      name="discription"
                      label="Discription"
                      value={
                        values?.description
                          ? removeTags(values?.description)
                          : ""
                      }
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    item
                    className="mt-4 justify-between sm:max-w-full"
                  >
                    <Box className="mt-2 d-flex justify-content-end">
                      <Link to={`/update-change/${data.chng_num}`}>
                      <Button
                        className="px-4 ml-4 capitalize  xl:text-sm 2xl:text-semi-base"
                        variant="contained"
                        type="submit"
                        startIcon={<Edit />}
                        onClick={() => {
                          console.log("DATA ===================> ", { data });
                          console.log({ errors }, { values });
                          handleSubmit();
                          onUpdateChange();
                        }}
                        >
                        Edit
                      </Button>
                        </Link>
                      <Button
                        color="secondary"
                        className="px-4 ml-4 capitalize  xl:text-sm 2xl:text-semi-base"
                        variant="contained"
                        onClick={onClose}
                      >
                        Close
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default UpdateChangeDialog;
