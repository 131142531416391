import React, { useEffect } from "react";
import Navbar from "../../components/layouts/Navbar";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { DeleteBox } from "../../components/layouts/commonLayouts/dialogBox/delete";
import { useGetAll } from "../../hooks";
import { ProductDialog } from "../../components/AdminPanelLayout/ProductDialog";
import { Button, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import ProductsCard from "./ProductsCard";
import BUlkCsvUpload from "./BulkCsvUpload";

const Products = ({ history, match }) => {
  const columns = [
    {
      headerName: "Product",
      field: "name",
      flex: 1,
      cellClassName: "text-dark",
      renderCell: ({ row }) => {
        // console.log(row);
        return <div>{row?.name}</div>;
      },
    },
    {
      headerName: "Category",
      field: "category",
      flex: 1,
      renderCell: ({ row }) => {
        // console.log(row);
        return <div>{row?.category?.name}</div>;
      },
    },
    {
      headerName: "Sub Category",
      field: "sub_category",
      flex: 1,
      renderCell: ({ row }) => {
        // console.log(row);
        return <div>{row?.sub_category?.sc_name}</div>;
      },
    },
    {
      headerName: "Price",
      field: "price",
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
    },

    {
      headerName: "Actions",
      field: "actions",
      flex: 1.2,
      // minWidth: 250,
      renderCell: ({ row }) => (
        <div className="flex items-center">
          <ProductDialog
            isUpdate
            data={row}
            product_type={match.path.replace("/", "")}
          />
          {/* <div className="border rounded-xl py-2 mx-3"></div> */}
          {/* <DeleteBox
            title="Product"
            url="/requests/product/Product"
            data={row.id}
            refetchUrl="/requests/product/Product/0"
          >
            Do you really want to delete this Product?
          </DeleteBox> */}
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("data")).user_role !== "Admin" &&
      !JSON.parse(localStorage.getItem("data")).is_asset_manager
    ) {
      history.push("/view-status");
    } else {
      document.title = "All Products";
    }
    //eslint-disable-next-line
  }, []);

  const { data: products,refetch } = useGetAll({
    key: "/requests/product/Product/0",
    params: {
      page: 0,
      limit: 25,
    },
  });
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Navbar heading="PRODUCTS" subheading="" />
      <section className="main-container  sm:w-[100%]  ">
        {isMobile ? (
          <div>
              <Link
                to={{
                  pathname: `/request-category/`,
                  state: { title: "PRODUCTS" },
                }}
              >
                <Button>Category</Button>
              </Link>
              <ProductDialog product_type={match.path.replace("/", "")} />
            </div>
        ) : (
          ""
        )}
        {isMobile ? (
          products?.rows?.map((row, index) => (
            <ProductsCard key={index} row={row || []} match={match} />
          ))
        ) : (
          <DataGrid
            columns={columns}
            rows={products}
            url="/requests/product/Product/0"
            addButton={
              <div className="flex sm:flex-col tab:flex-col  flex-wrap mt-4">
                <Link
                  to={{
                    pathname: `/request-category/`,
                    state: { title: "PRODUCTS" },
                  }}
                >
                  <Button>Category</Button>
                </Link>
              <BUlkCsvUpload className={""} downloadAPI={"/requests/export/products"} uploadAPI={"/requests/upload/products"} onUploadSuccess={refetch}/>
                <ProductDialog product_type={match.path.replace("/", "")} />
              </div>
            }
          />
        )}
      </section>
    </>
  );
};

export default Products;
