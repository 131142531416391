import React, { useEffect } from "react";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from "../../hooks";
import queryString from "query-string";
import { columns } from "./RequestsColumns";

const FilterRequest = ({ history, match, location }) => {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role === "User") {
      history.push("/view-status");
    } else {
      document.title = "All Request";
      window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page_data: {
        country: 'India',
        language: 'en',
        page_url: window.location.href,
        page_category: 'Request Management',
        page_location: 'FilterRequest',
        page_referrer: document.referrer,
        page_title: document.title,
        region: 'Pune',
        site_section: 'Support',
      },
      user_data: {
        user_id: JSON.parse(localStorage.getItem("data")).user_id,
        user_login_state: 'User',
        user_type: JSON.parse(localStorage.getItem("data")).user_role,
      }
    });
    console.log(window.dataLayer)
    }
    // eslint-disable-next-line
  }, []);

  const parsed = queryString.parse(location.search);
  const state = parsed.state ? parsed.state : history.push("/page-not-found");

  const { data } = useGetAll({
    key: `/${match.params.module}/piecharthyperlinks/${state}`,
    params: {
      page: 0,
      limit: 25,
    },
  });

  return (
    <>
      <DataGrid
        columns={columns}
        url={`/${match.params.module}/piecharthyperlinks/${state}`}
        rows={data}
        getRowId={(row) => row?.id}
      />
    </>
  );
};

export default FilterRequest;
