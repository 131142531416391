import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { DeleteBox } from "../../components/layouts/commonLayouts/dialogBox";
import { DepartmentDialog } from "../../components/AdminPanelLayout/DepartmentDialog";

export default function DepartmentCard({ row, key }) {
  console.log("Department card", row);

  return (
    <>
      <Box sx={{ minWidth: 275 }} key={key}>
        <Card variant="outlined" className="mt-[30px] shadow-lg transition duration-300 transform hover:-translate-y-1 hover:shadow-2xl">
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {row?.name}
            </Typography>
            <Typography variant="h5" component="div">
              {row?.manager?.profile?.full_name}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {row?.manager?.email}
            </Typography>
            <div className="w-full flex justify-between">
              {" "}
              <DepartmentDialog isUpdate data={row} />
              
              <DeleteBox
                title="Department"
                url="/portal/departments"
                data={row.id}
                refetchUrl="/portal/departments/0"
              >
                Do you really want to delete this Category?
              </DeleteBox>
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
