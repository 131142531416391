function removeTags(str) {
    if ((str === null) || (str === ''))
        return '';
    else
        str = str.toString();
        str = str.replace(/(<([^>]+)>)/ig, '');
        str = str.replace(/&nbsp;/g, ' ').replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&');
        return str.trim();
}

export default removeTags;
